//@ts-ignore
import { store } from 'react-notifications-component';

export function addNotification(
    title: string,
    message: string,
    type: string,
    duration: number,
    config?: any
) {
    return store.addNotification({
        insert: 'bottom',
        container: 'bottom-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
            duration,
            pauseOnHover: true,
        },
        dismissable: { click: true },
        ...config,
        title,
        message,
        type,
    });
}

export function removeNotification(id: string) {
    return store.removeNotification(id);
}
