import { FC, useEffect, useState } from 'react';
import Wrapper from '../Common/wrapper';
import Header from '../Common/Header';
import { useTranslation } from 'react-i18next';
import { ProviderActionsProps } from './props';
import { isDeclinedLot } from '../../helpers/declined';
import { isProvider } from '../../helpers/permissions';
import ModalDeclineProvider from '../ModalDeclineProvider';
import ModalIntentionToAppeal from '../ModalIntentionToAppeal';
import styled from 'styled-components';
import ProviderAdditionalDocumentsRedirect from '../ProviderAdditionalDocumentsRedirect';
import { canIntentionToAppeal } from '../../helpers/intention-to-appeal';
import ApiService from '../../services/api';
import { StageLot } from '../../Interfaces/stageLot';

const Item = styled.div`
    padding: 4px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const stagesToShowAdditionalDocuments = [
    StageLot.negotiation,
    StageLot.negotiation_finished
]

const ProviderActions: FC<ProviderActionsProps> = ({
    auctionNotice,
    auctionNoticeLotSelected,
    authUser,
}) => {
    const [isOpened, setOpened] = useState(true);
    const [visibleAdditionalDocuments, setVisibleAdditionalDocuments] = useState(false);
    const { t } = useTranslation();

    const getCanShow = async () => {
        if (!stagesToShowAdditionalDocuments.includes(auctionNoticeLotSelected.stage)) {
            return;
        }

        const response = await ApiService.getRequestedAdditionalDocuments(
            auctionNotice.id,
            auctionNoticeLotSelected.id,
            () => {}
        );

        setVisibleAdditionalDocuments(Boolean(response?.requestedAdditionalDocuments) || false);
    };

    useEffect(() => {
        setVisibleAdditionalDocuments(false);
        getCanShow();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auctionNoticeLotSelected.id, auctionNoticeLotSelected.stage]);

    const hasOptions =
        (!isDeclinedLot(auctionNoticeLotSelected) && isProvider(authUser)) ||
        canIntentionToAppeal(auctionNotice, auctionNoticeLotSelected) ||
        visibleAdditionalDocuments;

    return (
        <Wrapper
            border='1px solid rgba(204, 204, 204, 0.5);'
            margin='10px 0'
            width='100%'
            minWidth='290px'
            minHeight='auto'
            height='auto'
        >
            <Header
                icon='user'
                showMinimize
                title={t('Ações')}
                onExpand={() => setOpened(!isOpened)}
                expandDirection={isOpened ? 'rotate(-90deg)' : 'rotate(0deg)'}
            />
            {isOpened && (
                <Wrapper style={{ padding: '5px 0' }}>
                    {!hasOptions && (
                        <Item>
                            <span>{t('info.no-actions-avaliable')}</span>
                        </Item>
                    )}
                    {!isDeclinedLot(auctionNoticeLotSelected) && isProvider(authUser) ? (
                        <Item>
                            <span>{t('info.request.decline.lot')}</span>
                            <ModalDeclineProvider
                                auctionLot={auctionNoticeLotSelected}
                                auctionId={auctionNotice.id}
                            />
                        </Item>
                    ) : null}
                    {canIntentionToAppeal(auctionNotice, auctionNoticeLotSelected) ? (
                        <Item>
                            <span>{t('info.appeal-intent')}</span>
                            <ModalIntentionToAppeal
                                auctionLot={auctionNoticeLotSelected}
                                auction={auctionNotice}
                            />
                        </Item>
                    ) : null}
                    {visibleAdditionalDocuments ? (
                        <Item>
                            <span>{t('info.additional-documents')}</span>
                            <ProviderAdditionalDocumentsRedirect auction={auctionNotice} />
                        </Item>
                    ) : null}
                </Wrapper>
            )}
        </Wrapper>
    );
};

export default ProviderActions;
