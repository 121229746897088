import { FC } from 'react';
import { InformationListProps } from './props';
import { Checkbox } from 'antd';
import Wrapper from '../../../Common/wrapper';
import { formatValueWithAuctionRules } from '../../../../helpers/currencyTransform';
import { AuctionNoticeType, TypeValueBidTypes } from '../../../../Interfaces/auctionNotice';
import HelpText from '../../../HelpText';
import { useTranslation } from 'react-i18next';

const InformationList: FC<InformationListProps> = ({ auctionNoticeLotSelected, auctionNotice }) => {
    const { t } = useTranslation();

    const typeValueBidText = {
        [TypeValueBidTypes.total]: t('term.lot'),
        [TypeValueBidTypes.unit]: t('term.unitary'),
        [TypeValueBidTypes.kit]: t('term.lot'),
    };

    const isExclusiveText = t('term.exclusive.simple.company');
    const isExclusiveRegionalText = t('term.exclusive.regional');
    const bidFormatText = t('term.bid.format');

    return (
        <Wrapper padding='10px 20px'>
            {auctionNotice.auctionType !== AuctionNoticeType.L ? (
                <>
                    <Wrapper margin='7px 0' flexBox justifyContent='space-between'>
                        <Wrapper title={isExclusiveText}>{isExclusiveText}</Wrapper>
                        <Checkbox
                            checked={auctionNoticeLotSelected.isExclusiveToSimpleCompany === 1}
                        />
                    </Wrapper>
                    <Wrapper margin='7px 0' flexBox justifyContent='space-between'>
                        <Wrapper title={isExclusiveRegionalText}>{isExclusiveRegionalText}</Wrapper>
                        <Checkbox
                            checked={
                                auctionNotice.rangeRegionParticipation === 1 ||
                                auctionNotice.rangeRegionParticipation === 2
                            }
                        />
                    </Wrapper>
                </>
            ) : null}
            <Wrapper flexBox margin='7px 0' justifyContent='space-between'>
                <Wrapper title={t('info.minimum.interval')}>{t('term.minimum.interval')}</Wrapper>
                <Wrapper fontWeight='700'>
                    {
                        formatValueWithAuctionRules(
                            auctionNoticeLotSelected.bidAmountDifference,
                            auctionNotice.judgmentCriterion,
                            auctionNotice.rateTypeBid,
                            auctionNotice.decimalPlaces
                        ).formatted
                    }
                </Wrapper>
            </Wrapper>
            <Wrapper flexBox margin='7px 0' justifyContent='space-between'>
                <Wrapper position='relative' title={bidFormatText}>
                    {bidFormatText}
                    <Wrapper top='-5px' right='-13px' position='absolute'>
                        <HelpText
                            size={10}
                            helpText={
                                <Wrapper>
                                    <p>
                                        <strong>
                                            {`${typeValueBidText[TypeValueBidTypes.unit]}`}:{' '}
                                        </strong>
                                        <span>{t('info.bid.format.unit')}</span>
                                    </p>
                                    <p>
                                        <strong>
                                            {`${typeValueBidText[TypeValueBidTypes.total]}`}:{' '}
                                        </strong>
                                        <span>{t('info.bid.format.total')}</span>
                                    </p>
                                </Wrapper>
                            }
                        />
                    </Wrapper>
                </Wrapper>
                <Wrapper fontWeight='700'>
                    {typeValueBidText[auctionNotice.typeValueBid] || '--'}
                </Wrapper>
            </Wrapper>
        </Wrapper>
    );
};

export default InformationList;
