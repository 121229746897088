import { FC, useEffect, useState } from 'react';
import Popover from 'react-tiny-popover';
import ApiService from '../../../../services/api';
import { addNotification } from '../../../../helpers/notification';
import Image from '../../../Common/Image';
import Wrapper from '../../../Common/wrapper';
import { ChatEnableListProps } from './props';
import { Button, Switch } from 'antd';
import Scroll from '../../../Common/Scroll';
import { pad } from '../../../../helpers/pad';
import { useTranslation } from 'react-i18next';

const ChatEnableList: FC<ChatEnableListProps> = ({ auctionNoticeId }) => {
    const [modalOpened, setmodalOpened] = useState(false);
    const [chatEnabledList, setChatEnabledList] = useState<
        { enabled: number; providerAuctionCode: number; providerId: number }[]
    >([]);

    const { t } = useTranslation();

    const enableChatToProvider = async (data: any) => {
        await ApiService.enableProviderChat({
            auctionNoticeId,
            data,
        });

        setmodalOpened(false);

        addNotification(t('term.success'), t('info.updated.chat.configs'), 'success', 3000);
    };

    useEffect(() => {
        if (modalOpened) {
            getChatsEnabled();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalOpened]);

    const getChatsEnabled = async () => {
        const chatEnabledList = await ApiService.getEnableProvidersChat({ auctionNoticeId });
        if (!chatEnabledList) return;

        setChatEnabledList(chatEnabledList);
    };

    const onChange = (index: number, checked: boolean) => {
        chatEnabledList[index] = {
            ...chatEnabledList[index],
            enabled: checked ? 1 : 0,
        };

        setChatEnabledList([...chatEnabledList]);
    };

    const onSelectAll = (checked: boolean) => {
        setChatEnabledList((prevState) => [
            ...prevState.map((chatEnabled) => ({
                ...chatEnabled,
                enabled: checked ? 1 : 0,
            })),
        ]);
    };

    const update = async () => {
        let data: any = {
            providers: [...chatEnabledList],
        };

        await enableChatToProvider(data);
    };

    return (
        <Popover
            isOpen={modalOpened}
            position={['bottom', 'right', 'left']}
            onClickOutside={(e: any) => {
                setmodalOpened(false);
            }}
            content={() => (
                <Wrapper bgcolor='#FFF'>
                    <Scroll minWidth='180px' maxHeight='330px' overflowY='auto'>
                        <Wrapper padding='12px 20px 6px 20px'>
                            <Wrapper padding='8px 0 10px 0' flexBox>
                                <Wrapper padding='0 12px 0 0'>
                                    <Switch
                                        checked={
                                            chatEnabledList.length > 0 &&
                                            chatEnabledList.every(
                                                (chatEnabled) => !!chatEnabled.enabled
                                            )
                                        }
                                        onChange={(checked) => onSelectAll(checked)}
                                    />
                                </Wrapper>
                                <Wrapper fontSize='13px'>{t('info.select.all')}</Wrapper>
                            </Wrapper>
                            {chatEnabledList.map((chatEnabled, index) => (
                                <Wrapper padding='7px 0' flexBox>
                                    <Wrapper padding='0 12px 0 0'>
                                        <Switch
                                            checked={Boolean(chatEnabled.enabled)}
                                            onChange={(checked) => onChange(index, checked)}
                                        />
                                    </Wrapper>
                                    <Wrapper fontSize='13px'>
                                        {`${t('term.provider')} ${pad(
                                            chatEnabled.providerAuctionCode
                                        )}`}
                                    </Wrapper>
                                </Wrapper>
                            ))}
                        </Wrapper>
                    </Scroll>
                    <Wrapper padding='10px 15px' flexBox justifyContent='space-between'>
                        <Button
                            onClick={() => setmodalOpened(false)}
                            style={{
                                borderRadius: '4px',
                                color: '#333',
                                fontSize: '12px',
                            }}
                        >
                            {t('info.cancel')}
                        </Button>
                        <Button
                            onClick={() => update()}
                            style={{
                                borderRadius: '4px',
                                background: 'var(--platform-primary-color)',
                                color: '#FFF',
                                fontSize: '12px',
                            }}
                        >
                            {t('info.save')}
                        </Button>
                    </Wrapper>
                </Wrapper>
            )}
        >
            <Image
                src='../../assets/icons/chat.svg'
                style={{ cursor: 'pointer' }}
                width='17px'
                height='17px'
                title={t('info.release.providers.chat')}
                margin='0 15px 0 0'
                onClick={() => setmodalOpened(true)}
            />
        </Popover>
    );
};

export default ChatEnableList;
