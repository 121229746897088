import styled from 'styled-components';
import Wrapper from '../wrapper';

const Scroll = styled(Wrapper)`
    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-track {
        background: #e8e8e8;
    }

    ::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.colors.darkGray};
    }

    ::-webkit-scrollbar-thumb:hover {
        background: ${({ theme }) => theme.colors.darkGray};
    }

    scrollbar-width: thin;
    scrollbar-color: ${({ theme }) => theme.colors.darkGray};
`;

export default Scroll;
