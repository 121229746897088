import { FC } from 'react';
import 'antd/dist/antd.css';
import { useLocation } from 'react-router-dom';
import jwt from 'jwt-decode';
import { Redirect } from 'react-router-dom';
import { CONSTANTS } from '../../constants';

interface RedirectValidatorProps {}

const RedirectValidator: FC<RedirectValidatorProps> = () => {
    const useQuery = () => new URLSearchParams(useLocation().search);
    const queryString = useQuery();

    const resetLocalStorageValues = () => {
        localStorage.removeItem(CONSTANTS.storageMap.FILTERS)
    }

    const setLocal = (key: string, value: string) => {
        localStorage.setItem(key, value);
    };

    const { AUTH } = CONSTANTS.storageMap;

    const setAuthData = () => {
        const routeToken = queryString.get('token');
        const routeAuctionId = queryString.get('auction');
        const routeHash = queryString.get('hash');
        const service = queryString.get('service');

        resetLocalStorageValues()
        if (routeToken && routeAuctionId && routeHash) {
            setLocal(
                `${AUTH}:${routeHash}`,
                JSON.stringify({
                    auction: routeAuctionId,
                    token: routeToken,
                    hash: routeHash,
                    service
                })
            );

            try {
                jwt(routeToken);
                return <Redirect to={`/a/${routeHash}`} />;
            } catch (error) {
                return <Redirect to='404' />;
            }
        }

        return <Redirect to='404' />;
    };

    return setAuthData();
};

export default RedirectValidator;
