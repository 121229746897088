import { FC, useState, useEffect, createRef, RefObject, useRef } from 'react';
import { ChatProps } from './props';
import Wrapper from '../Common/wrapper';
import Message from './components/message';
import { getColor, ColorType, ColorVariation } from '../Common/theme';
import Image from '../Common/Image';
import Scroll from '../Common/Scroll';
import SendMessage from '../SendMessage';
import { MessageItem } from './interface';
import ApiService from '../../services/api';
import { SocketEvent, SocketEvents } from '../../Interfaces/socketEvents';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CONSTANTS } from '../../constants';
import TinyQueue from 'tinyqueue';
import ChatHeader from './components/header';
import SearchBox from './components/searchBox';
import styled from 'styled-components';
import { timeout } from 'helpers/timer';
import { Spin, SpinArea } from 'Components/Common/Spin';
import { isAuctioneer, isProvider } from '../../helpers/permissions';
import { setPageTitle } from '../../helpers/set-page-title';
import { useTranslation } from 'react-i18next';
import { usePlatformContext } from '../../context/platform.context';
import { UseWindowEvent } from '../../Hooks/window-event.hook';
import { isMobile, isSuspendedChat } from '../../helpers/mobile';
import { Button } from 'antd';
import ChatFavoriteMessage from './components/chatFavoriteMessage';

const OpenChatButton = styled(Wrapper)`
    z-index: 100000;
`;

const paginationLimit = 100;

const { NOTIFICATIONS } = CONSTANTS.storageMap;

const Chat: FC<ChatProps> = ({ auctionNotice, authUser, socketConnection }) => {
    let queueLock = false;

    const queueEvents: any = new TinyQueue([], (a: any, b: any) => {
        return a.message.id - b.message.id;
    });

    const auctionNoticeId = auctionNotice.id;
    const { t } = useTranslation();

    const [isOpened, setOpened] = useState(isMobile() || isSuspendedChat() ? false : true);
    const [messagesPersist, setMessagesPersist] = useState<MessageItem[]>([]);
    const [messages, setMessages] = useState<MessageItem[]>([]);
    const [favoriteMessage, setFavoriteMessage] = useState<string>('');
    const [loading, setLoading] = useState(true);
    const [unreadMessages, setUnreadMessages] = useState<number>(0);
    const [windowFocused, setWindowFocused] = useState<boolean>(true);
    const [searchBoxOpened, setSearchBoxOpened] = useState<boolean>(false);
    const [textFilterApplied, setTextFilterApplied] = useState<string | undefined>(undefined);
    const [providerCanSendMessages, setProviderCanSendMessages] = useState(false);

    const notificationKey: string = `${NOTIFICATIONS}:${auctionNoticeId}`;

    const [emitNotifications, setEmitNotifications] = useState<string | null>(null);

    const windowFocusedRef: any = useRef(null);
    windowFocusedRef.current = { windowFocused, setWindowFocused };

    const unreadMessagesRef: any = useRef(null);
    unreadMessagesRef.current = { unreadMessages, setUnreadMessages };

    const emitNotificationsRef: any = useRef(null);
    emitNotificationsRef.current = { emitNotifications, setEmitNotifications };

    const textFilterAppliedRef: any = useRef(null);
    textFilterAppliedRef.current = { textFilterApplied, setTextFilterApplied };

    const providerCanSendMessagesRef: any = useRef(null);
    providerCanSendMessagesRef.current = { providerCanSendMessages };

    const messagesEnd: RefObject<any> = createRef();

    const [lastMessagesFetch, setLastMessagesFetch] = useState(0);
    const [page, setPage] = useState(1);
    const [newMessages, setNewMessages] = useState(0);
    const [fetching, setFetching] = useState(false);

    const [disabledLoadMore, setDisabledLoadMore] = useState({ start: false, end: false });

    const pageRef: any = useRef(null);
    pageRef.current = { page, setPage };

    useEffect(() => {
        if (isOpened && socketConnection) {
            scrollToBottom();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, isOpened, messages.length, socketConnection]);

    const scrollToBottom = () => {
        timeout(() => {
            const element = document.getElementById('end');
            element?.scrollIntoView();
        }, 50);
    };

    useEffect(() => {
        if (!searchBoxOpened) {
            loadMore(true);
        }
    }, [searchBoxOpened]);

    useEffect(() => {
        if (isProvider(authUser)) {
            getChatEnabled();
        }

        getAuctionNoticeMessages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auctionNoticeId]);

    useEffect(() => {
        handleSocketMessage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socketConnection]);

    const getAuctionNoticeMessages = async () => {
        const messageList = auctionNoticeId ? await ApiService.getAuctionNoticeMessages(auctionNoticeId, {
            ts: lastMessagesFetch,
            limit: paginationLimit,
            offset: 0,
            q: '',
        }) : [];

        if (messageList?.length > 0) {
            setMessagesPersist(messageList);
            setMessages(messageList);
            setLastMessagesFetch(+new Date());
        }

        timeout(() => setLoading(false), 0);
    };

    const getChatEnabled = async () => {
        const chatEnabled = await ApiService.getEnableProviderChat({ auctionNoticeId });
        if (!chatEnabled) return;

        if (chatEnabled?.enabled) {
            setProviderCanSendMessages(chatEnabled.enabled === 1 ? true : false);
        }
    };

    useEffect(() => {
        const emit = localStorage.getItem(notificationKey);

        if (!emit) {
            localStorage.setItem(notificationKey, 'true');
            setEmitNotifications('true');
        } else if (emit === 'true') {
            setEmitNotifications(emit);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { platform } = usePlatformContext();

    useEffect(() => {
        if (windowFocusedRef.current.windowFocused) {
            if (platform) {
                setPageTitle(platform.description, undefined, true);
            }
            unreadMessagesRef.current.setUnreadMessages(0);
        }
    }, [windowFocusedRef.current.windowFocused]);

    useEffect(() => {
        window.onfocus = function () {
            windowFocusedRef.current.setWindowFocused(true);
        };

        window.onblur = function () {
            windowFocusedRef.current.setWindowFocused(false);
        };

        document.onblur = window.onblur;
    }, []);

    useEffect(() => {
        if (page === 1) {
            setNewMessages(0);
        }
    }, [page]);

    useEffect(() => {
        if (!isOpened) {
            setSearchBoxOpened(false);
            setTextFilterApplied(undefined);
            setMessages(messagesPersist);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpened]);

    const playNotification = () => {
        unreadMessagesRef.current.setUnreadMessages((prevState: number) => prevState + 1);

        if (
            !windowFocusedRef.current.windowFocused &&
            unreadMessagesRef.current.unreadMessages > 0
        ) {
            if (platform) {
                document.title = `(${unreadMessagesRef.current.unreadMessages}) - ${platform?.description}`;
            }
        }

        if (!emitNotificationsRef.current.emitNotifications) return;

        const audio = new Audio('../../assets/audio/notification.mp3');
        audio.volume = 0.25;
        audio.play().catch(() => console.log('error when running notification'));
    };

    const handleMessageEvent = (message: MessageItem) => {
        const { textFilterApplied } = textFilterAppliedRef.current;

        if (!!messagesPersist.find((chat) => chat.id === message.id)) {
            return;
        }

        if (pageRef.current.page > 1) {
            setNewMessages((prev) => prev + 1);
            return playNotification();
        }

        if (!textFilterApplied) {
            setMessages((prevState) => {
                const messages = [...prevState, message];

                if (messages.length > paginationLimit) {
                    messages.shift();
                }
                return messages;
            });
            setMessagesPersist((prevState) => {
                const messages = [...prevState, message];

                if (messages.length > paginationLimit) {
                    messages.shift();
                }
                return messages;
            });
            scrollToBottom();
            return playNotification();
        }
    };

    const handleEnableChatProvider = (message: { enabled: number }) => {
        setProviderCanSendMessages(message.enabled === 1 ? true : false);
    };

    const handleSocketMessage = () => {
        if (!socketConnection) return;

        socketConnection.on(SocketEvents.newMessage, (event: MessageItem) =>
            receiveEvent({
                type: SocketEvents.newMessage,
                message: event,
            })
        );

        socketConnection.on(SocketEvents.enableChatToProvider, (event: MessageItem) =>
            receiveEvent({
                type: SocketEvents.enableChatToProvider,
                message: event,
            })
        );
    };

    const handleEmitNotifications = (ev: CheckboxChangeEvent) => {
        if (ev.target.checked) {
            localStorage.setItem(notificationKey, ev.target.checked + '');
            setEmitNotifications(ev.target.checked + '');
        } else {
            localStorage.removeItem(notificationKey);
            setEmitNotifications(null);
        }
    };

    const receiveEvent = (message: SocketEvent) => {
        queueEvents.push(message);

        if (!queueLock) {
            queueLock = true;
            processEvent();
        }
    };

    const processEvent = async () => {
        try {
            while (queueEvents.length) {
                const event: SocketEvent = queueEvents.pop();

                const { type, message } = event;
                switch (type) {
                    case SocketEvents.newMessage:
                        return handleMessageEvent(message);

                    case SocketEvents.enableChatToProvider:
                        return handleEnableChatProvider(message);
                }

                await new Promise((r) => timeout(r, 15));
            }
        } finally {
            queueLock = false;
        }
    };

    const openSearchBox = () => {
        if (searchBoxOpened) {
            const { setTextFilterApplied } = textFilterAppliedRef.current;
            setTextFilterApplied(undefined);
            setMessages(messagesPersist);
        }

        setSearchBoxOpened(!searchBoxOpened);
    };

    const onSearchText = (text: string) => {
        const { setTextFilterApplied } = textFilterAppliedRef.current;
        setTextFilterApplied(text);

        if (text === '' || !text) {
            return loadMore(true);
        }

        loadMore(true);
    };

    UseWindowEvent(
        '@event.open-chat',
        () => {
            setOpened(true);
        },
        []
    );

    const enableSendMessage =
        isAuctioneer(authUser) ||
        (isProvider(authUser) && providerCanSendMessagesRef.current.providerCanSendMessages);

    
    const getHeight = () => {
        if (enableSendMessage) {
            return isAuctioneer(authUser) ? 'calc(100% - 126px)' : 'calc(100% - 110px)'
        }
        return '100%';
    }

    const getStyles = () => {
        if (isMobile() && isOpened) {
            return {
                width: '100%',
                top: '0',
                right: '0',
                left: '0',
                bottom: '0',
                position: 'absolute',
                height: getHeight(),
                zIndex: '1',
            };
        }

        if (isSuspendedChat() && isOpened) {
            return {
                width: '310px',
                top: '0',
                left: '0',
                bottom: '0',
                background: '#fff',
                borderRight: '1px #eaeaea solid',
                position: 'absolute',
                height: getHeight(),
                zIndex: '1',
            };
        }

        return {
            maxWidth: '310px',
            borderRight: '1px #eaeaea solid',
            position: 'relative',
            height: getHeight(),
            minWidth: isOpened ? '310px' : 'auto',
        };
    };

    const loadMore = async (reset?: boolean, inverse?: boolean) => {
        setFetching(true);

        let offset = 0;

        if (reset) {
            offset = 0;
        } else if (inverse) {
            const page = pageRef.current.page - 2;

            if (page > 1) {
                offset = page * paginationLimit;
            }
        } else {
            offset = pageRef.current.page * paginationLimit;
        }

        if (offset < 0) {
            offset = 0;
        }

        const messageList = auctionNoticeId ? await ApiService.getAuctionNoticeMessages(auctionNoticeId, {
            ts: reset || inverse ? 0 : lastMessagesFetch,
            limit: paginationLimit,
            offset,
            q: textFilterAppliedRef.current.textFilterApplied,
        }) : [];

        if (messageList?.length > 0) {
            if (reset) {
                setPage(1);
                setNewMessages(0);
                scrollToBottom();
            } else {
                if (inverse) {
                    setPage((prev) => prev - 1);
                } else {
                    setPage((prev) => prev + 1);
                }
            }
        }

        if (messageList.length < paginationLimit) {
            setDisabledLoadMore((prevState) => ({
                ...prevState,
                start: true,
            }));
        }

        if (messageList.length < paginationLimit / 2) {
            setMessagesPersist((prevState) => [...messageList, ...prevState]);
        } else {
            setMessagesPersist(messageList);
        }

        setFetching(false);
        setMessages(messageList);
        setLastMessagesFetch(+new Date());
    };

    const selectFavoriteMessage = (favoriteMessage: string) => {
        setFavoriteMessage(favoriteMessage);
    }

    const getRender = () => {
        if (isOpened) {
            return (
                <>
                    <ChatHeader
                        icon='chat'
                        title={t('info.messages')}
                        showMinimize={true}
                        onExpand={() => setOpened(!isOpened)}
                        expandDirection='0'
                        authUser={authUser}
                        emitNotifications={emitNotificationsRef.current.emitNotifications}
                        handleEmitNotifications={handleEmitNotifications}
                        auctionNotice={auctionNotice}
                        socketConnection={socketConnection}
                        openSearchBox={openSearchBox}
                        searchBoxOpened={searchBoxOpened}
                        providerCanSendMessages={
                            providerCanSendMessagesRef.current.providerCanSendMessages
                        }
                    />
                    <SearchBox
                        openSearchBox={openSearchBox}
                        searchBoxOpened={searchBoxOpened}
                        onSearch={(text: string) => onSearchText(text)}
                        fetching={fetching}
                    />
                    <Scroll
                        style={{
                            overflowY: 'auto',
                            background: '#fff',
                            borderBottom: `1px var(--light-gray-color) solid`,
                            height: `calc(100% -  ${searchBoxOpened ? '98px' : '46px'})`,
                            margin: searchBoxOpened ? '52px 0 0 0 ' : '0',
                        }}
                    >
                        {loading ? (
                            <SpinArea>
                                <Spin />
                            </SpinArea>
                        ) : messages?.length ? (
                            <>
                                {messages.length > 30 && !disabledLoadMore.start ? (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Button
                                            style={{
                                                width: '90%',
                                                background: '#efefef',
                                                marginBottom: '2px',
                                            }}
                                            loading={fetching}
                                            onClick={() => loadMore()}
                                        >
                                            Carregar mais
                                        </Button>
                                    </div>
                                ) : null}
                                {messages
                                    .filter((message) => message.message)
                                    .map((message: MessageItem) => (
                                        <Message
                                            message={message}
                                            authUser={authUser}
                                            auctionNotice={auctionNotice}
                                            key={`chat:${message.id}`}
                                        />
                                    ))}
                                {page > 1 && !disabledLoadMore.end && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            marginTop: '2px',
                                        }}
                                    >
                                        <Button
                                            style={{
                                                width: '90%',
                                                background: '#efefef',
                                            }}
                                            loading={fetching}
                                            onClick={() => loadMore(undefined, true)}
                                        >
                                            Carregar mais
                                        </Button>
                                    </div>
                                )}
                            </>
                        ) : (
                            <Wrapper
                                flexBox
                                padding='70px 0'
                                fontSize='13px'
                                textAlign='center'
                                justifyContent='center'
                            >
                                {searchBoxOpened
                                    ? t('info.chat.no-messages.with.search')
                                    : t('info.chat.no-messages')}
                            </Wrapper>
                        )}
                        {newMessages ? (
                            <div
                                onClick={() => {
                                    loadMore(true);
                                }}
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    right: 0,
                                    left: 0,
                                    background: '#d2fbd3',
                                    padding: '5px 20px',
                                    cursor: 'pointer',
                                    fontWeight: 600,
                                    color: '#444',
                                }}
                            >
                                {`${newMessages} novas mensagens`}
                            </div>
                        ) : null}
                        <div style={{ float: 'left', clear: 'both' }} ref={messagesEnd} id='end' />
                    </Scroll>
                    { enableSendMessage ? <SendMessage auctionNoticeId={auctionNoticeId} favoriteMessage={favoriteMessage} selectFavoriteMessage={selectFavoriteMessage} isAuctioneer={isAuctioneer(authUser)}/> : null }
                </>
            );
        }

        if (!isOpened && !isMobile()) {
            return (
                <OpenChatButton
                    position='absolute'
                    width='45x'
                    height='45px'
                    flexBox
                    bgcolor='#f5f5f5'
                    alignItems='center'
                    justifyContent='center'
                    padding='10px'
                    cursor='pointer'
                    title={t('info.open.chat')}
                    boxShadow='1px 2px 3px #9e9e9e'
                    margin='5px 0 0 0'
                    borderRadius='0 4px  4px 0'
                    onClick={() => setOpened(!isOpened)}
                >
                    <Image src='../../assets/icons/chat.svg' width='30px' height='30px' />
                </OpenChatButton>
            );
        }
    };

    return (
        <Wrapper style={getStyles()}>
            {/** só exibe loading se não for cidadão e tem conexão com socket */}
            {!socketConnection ? (
                !isMobile() && !isSuspendedChat() ? (
                    <SpinArea>
                        <Spin />
                    </SpinArea>
                ) : null
            ) : (
                getRender()
            )}
        </Wrapper>
    );
};

export default Chat;
