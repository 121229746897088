import { BackOfficeTheme } from 'Interfaces/theme';
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    :root{
        --platform-primary-color: ${({ theme }: { theme: BackOfficeTheme }) => theme.colors.primary};
        --platform-secondary-color: ${({ theme }) => theme.colors.secondary};
        --platform-tertiary-color: ${({ theme }) => theme.colors.tertiary};
        --platform-info-color: ${({ theme }) => theme.colors.info};
        --platform-warning-color: ${({ theme }) => theme.colors.warning};
        --platform-danger-color: ${({ theme }) => theme.colors.danger};
        --platform-success-color: ${({ theme }) => theme.colors.success};
      
        --gray-color: ${({ theme }) => theme.colors.gray};
        --light-gray-color: ${({ theme }) => theme.colors.lightGray};
        --dark-gray-color: ${({ theme }) => theme.colors.darkGray};
        
        --background-color: ${({ theme }) => theme.colors.background};
        --background-page-default-color: ${({ theme }) => theme.colors.backgroundPageDefault};
    }
`;
