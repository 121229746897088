import { FC, useEffect, useState, useRef } from 'react';
import Wrapper from '../Common/wrapper';
import { RequestRefreshPageProps } from './props';
import ApiService from '../../services/api';
import ConfirmationPopover from '../Common/ConfirmationPopover';
import { addNotification } from '../../helpers/notification';
import { SocketEvents } from '../../Interfaces/socketEvents';
import { RefreshIcon, RefreshDoneIcon } from './styled';
import { timeout } from 'helpers/timer';
import { useTranslation } from 'react-i18next';

const RequestRefreshPage: FC<RequestRefreshPageProps> = ({ auctionNoticeId, socketConnection }) => {
    const [requestedRefresh, setRequestedRefresh] = useState<boolean>(false);
    const [requestDone, setRequestDone] = useState<boolean>(false);

    const { t } = useTranslation();

    const requestDoneRef: any = useRef(null);
    requestDoneRef.current = {
        requestDone,
        setRequestDone,
    };

    useEffect(() => {
        socketConnection.on(SocketEvents.requestRefreshPage, markRequestDone);

        return () => {
            socketConnection.removeListener(SocketEvents.requestRefreshPage, markRequestDone);
        };
    }, [socketConnection]);

    const markRequestDone = () => {
        const { setRequestDone } = requestDoneRef.current;

        timeout(() => {
            setRequestDone(true);
            addNotification(t('term.success'), t('info.updated'), 'success', 3000);
        }, 1000);
    };

    const request = async () => {
        setRequestedRefresh(true);

        await ApiService.requestRefreshPage({
            auctionNoticeId,
        });
    };

    return (
        <ConfirmationPopover
            id='requestRefreshPage'
            key={`requestRefreshPage`}
            render={
                <Wrapper fontSize='13px'>
                    <p>{t('info.confirm.update.providers.page')}</p>
                    <p>{t('info.continue.update.page')}?</p>
                </Wrapper>
            }
            maxWidth='220px'
            onConfirm={request}
        >
            {requestedRefresh && requestDoneRef.current.requestDone ? (
                <RefreshDoneIcon onClick={(e: any) => e.prevendDefault()} />
            ) : (
                <RefreshIcon
                    className={
                        requestedRefresh && !requestDoneRef.current.requestDone
                            ? 'loading'
                            : undefined
                    }
                />
            )}
        </ConfirmationPopover>
    );
};

export default RequestRefreshPage;
