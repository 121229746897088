export interface AuctionNotice {
    id: number;
    auctionNumber: string;
    accreditationNumber: string;
    methodDispute: number;
    judgmentCriterion: 1 | 2 | 3;
    rangeSupplierParticipation: number;
    rangeRegionParticipation: number;
    organizationUnit: {
        organizationUnitName: string;
    };
    organization: {
        organizationName: string;
    };
    decimalPlaces: number;
    typeValueBid: TypeValueBidTypes;
    rateTypeBid: number;
    auctionType: AuctionNoticeType;
    disputeDuration: number;
    isRandomTime: number;
    deadlineIntentAppeal: string;
    appealStatus: AppealStatusType;
    isPhaseReversal: number;
    auctionTypeRules: {
        actived: number;
        auctionType: string;
        description: 'Pregão';
        generalSettings: any;
        cadastro: any;
        comissao: any;
        etapas: any;
        solicitacoes: any;
        id: string;
    };
}

export enum AuctionNoticeType {
    E = 'E',
    D = 'D',
    L = 'L',
}

export enum TypeValueBidTypes {
    total = 'total',
    unit = 'unit',
    kit = 'kit',
}

export enum AppealStatusType {
    closed = 'closed',
    intent = 'intent',
    request = 'request',
    counterReason = 'counterReason'
}
