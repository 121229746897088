import styled from 'styled-components';
import { Input } from 'antd';

const { TextArea } = Input;

const TextAreaScroll = styled(TextArea)`
    &::-webkit-scrollbar {
        width: 3px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 0px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0;
    }
    scrollbar-width: none;
`;

const TotalChars = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 3px 10px 0 10px;

    span {
        color: #666;
        font-size: 11px;
    }
`;

export { TextAreaScroll, TotalChars };
