export enum ThemeTypes {
    light = 'light',
    dark = 'dark',
}

export enum ThemeColors {
    primary = 'primary',
    secondary = 'secondary',
    tertiary = 'tertiary',
    info = 'info',
    warning = 'warning',
    danger = 'danger',
    success = 'success',
    // Background colors
    background = 'background',
    backgroundPageDefault = 'backgroundPageDefault',
    // text colors
    gray = 'gray',
    lightGray = 'lightGray',
    darkGray = 'darkGray',
}

export interface PlatformColors {
    primary: string;
    secondary: string;
    terciary: string;
    info: string;
    warning: string;
    danger: string;
    success: string;
}

export interface BackOfficeTheme {
    colors: { [key in ThemeColors]: string };
}
