import { FC, useState } from 'react';
import { HelpTextProps } from './props';
import Popover from 'react-tiny-popover';
import styled from 'styled-components';
import { MdHelp } from 'react-icons/md';

const HelpIcon = styled(MdHelp)<{ size?: number }>`
    min-height: 11px;
    min-width: 11px;
    cursor: auto;
    margin: 0 0 0 2px;
    color: #666;
    ${(props) =>
        !!props.size &&
        `
        min-height: ${props.size}px;
        min-width:  ${props.size}px;
    `}
`;

const Content = styled.div`
    background: #fff;
    padding: 8px;
    border-radius: 5px;
    max-width: 300px;

    span {
        white-space: break-spaces;
        font-size: 13px;
        color: #555;
    }
`;

const HelpText: FC<HelpTextProps> = ({ helpText, size }) => {
    const [opened, setOpened] = useState(false);

    const handleMouseEvent = (ev: any) => helpText && (ev.stopPropagation(), setOpened(!opened));

    return (
        <Popover
            isOpen={opened}
            position={['bottom', 'right', 'left']}
            onClickOutside={() => setOpened(false)}
            content={() => (
                <Content>
                    <span>{helpText}</span>
                </Content>
            )}
        >
            <HelpIcon size={size} onMouseOver={handleMouseEvent} onMouseOut={handleMouseEvent} />
        </Popover>
    );
};

export default HelpText;
