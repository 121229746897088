import { FC } from 'react';
import Wrapper from '../../../../Common/wrapper';
import { formatValueWithAuctionRules } from '../../../../../helpers/currencyTransform';
import { AuctioneerBestBidProps } from './props';
import { useTranslation } from 'react-i18next';
import HelpText from 'Components/HelpText';

const AuctioneerBestBid: FC<AuctioneerBestBidProps> = ({ auctionLot, auctionNotice }) => {
    const { judgmentCriterion, decimalPlaces, rateTypeBid } = auctionNotice;
    const { t } = useTranslation();

    const getBestBidColor = () => {
        if (judgmentCriterion === 2 || judgmentCriterion === 3) {
            return 'var(--platform-secondary-color)';
        } else if (!auctionLot.bestBid || !auctionLot.valueReference) {
            return '#555';
        }

        return parseFloat(auctionLot.valueReference) >= parseFloat(auctionLot.bestBid)
            ? 'var(--platform-secondary-color)'
            : 'var(--platform-danger-color)';
    };

    function formatCurrency(value: string | number | null) {
        return formatValueWithAuctionRules(value, judgmentCriterion, rateTypeBid, decimalPlaces)
            .formatted;
    }

    return (
        <Wrapper flexBox minWidth='145px' margin='0 10px' alignItems='center'>
            <Wrapper flex>
                <Wrapper flex textAlign='right' fontSize='12px'>
                    {t('info.reference-value')}
                </Wrapper>
                <Wrapper flex textAlign='right' fontSize='12px' truncate>
                    {
                        formatValueWithAuctionRules(
                            auctionLot.valueReference,
                            1,
                            rateTypeBid,
                            decimalPlaces,
                            true
                        ).formatted
                    }
                </Wrapper>
                <Wrapper
                    flex
                    truncate
                    textAlign='right'
                    color={getBestBidColor()}
                    fontWeight='700'
                    fontSize='12px'
                >
                    {formatCurrency(auctionLot.bestBid)}
                    {!!auctionLot?.additionalExpense && (
                        <HelpText
                            helpText={t('info.lot-proposal-values', {
                                totalValue: formatCurrency(auctionLot.bestBid ?? ''),
                                baseValue: formatCurrency(
                                    Number(auctionLot.bestBid ?? 0) -
                                        Number(auctionLot.additionalExpense ?? 0)
                                ),
                                additionalExpense: formatCurrency(
                                    auctionLot.additionalExpense ?? ''
                                ),
                            })}
                        />
                    )}
                </Wrapper>
            </Wrapper>
        </Wrapper>
    );
};

export default AuctioneerBestBid;
