import { FC } from 'react';
import Wrapper from '../../../Common/wrapper';
import { MultipleWinnerItemProps } from './props';
import { pad } from '../../../../helpers/pad';
import { MultipleWinnersStatus } from '../../../../Interfaces/multipleWinners';
import { HiOutlineBan } from 'react-icons/hi';
import ConfirmationPopover from '../../../Common/ConfirmationPopover';
import ApiService from '../../../../services/api';
import { addNotification } from '../../../../helpers/notification';
import { useTranslation } from 'react-i18next';

const MultipleWinnerItem: FC<MultipleWinnerItemProps> = ({
    multipleWinner,
    index,
    auction,
    viewMode,
    getWinners,
}) => {
    const { t } = useTranslation();

    const getLabel = () => {
        if (multipleWinner.status === MultipleWinnersStatus.pending) {
            return '--';
        }

        return multipleWinner.participate?.providerAuctionCode
            ? `Fornecedor ${pad(multipleWinner.participate.providerAuctionCode)}`
            : '--';
    };

    const handleClickDeclassify = async () => {
        if (viewMode) {
            return;
        }

        let error: any = undefined;

        const response = await ApiService.declassifyMultipleWinners(
            auction.id,
            multipleWinner.lotId,
            multipleWinner.position,
            (err) => {
                error = err;
            }
        );

        if (error || !response?.ok) {
            return addNotification(
                t('term.error'),
                t('Ocorreu um erro ao desclassificar'),
                'warning',
                3000
            );
        }

        getWinners();
        return addNotification(t('term.success'), t('term.success'), 'success', 3000);
    };

    return (
        <Wrapper
            borderBottom='1px solid #d7d7d7'
            flexBox
            width='100%'
            height='38px'
            padding='6px 5px'
            margin='4px 0'
            overflowY='hidden'
        >
            <Wrapper width='100%' alignItems='center' flexBox justifyContent='space-between'>
                <Wrapper display='flex'>
                    <Wrapper alignItems='center' flexBox>
                        <Wrapper alignItems='center' flexBox>
                            <Wrapper
                                width='20px'
                                margin='0 5px 0 0'
                                fontSize='12px'
                                fontWeight='700'
                            >
                                {`${index < 9 ? `0${index + 1}` : `${index + 1}`}º`}
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    <Wrapper
                        fontSize='13px'
                        alignItems='center'
                        flexBox
                        color='var(--platform-secondary-color)'
                        fontWeight='600'
                    >
                        {`${multipleWinner.percent}%`}
                    </Wrapper>
                    <Wrapper fontSize='13px' padding='0 0 0 20px' alignItems='center' flexBox>
                        {getLabel()}
                    </Wrapper>
                </Wrapper>
                <Wrapper fontSize='13px' padding='0 0 0 20px' alignItems='center' flexBox>
                    {multipleWinner.status === MultipleWinnersStatus.approved &&
                        multipleWinner.participate &&
                        !viewMode && (
                            <ConfirmationPopover
                                render={
                                    <>
                                        <Wrapper fontSize='13px'>
                                            <p>
                                                Confirma desclassificação? As posições serão
                                                reordenadas
                                            </p>
                                        </Wrapper>
                                    </>
                                }
                                maxWidth='220px'
                                onConfirm={handleClickDeclassify}
                            >
                                <HiOutlineBan
                                    style={{ fontSize: 18, color: 'var(--platform-danger-color)', cursor: 'pointer' }}
                                />
                            </ConfirmationPopover>
                        )}
                </Wrapper>
            </Wrapper>
        </Wrapper>
    );
};

export default MultipleWinnerItem;
