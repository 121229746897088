import { FC, useCallback } from 'react';
import { ConditionalTimerProps } from './props';
import ModeConvokedProvider from '../ModeConvokedProvider';
import ModeOpenClosed from '../ModeOpenClosed';
import ModeOpen from '../ModeOpen';
import { StageLot } from '../../Interfaces/stageLot';
import { UserType } from '../../Interfaces/user';
import ExemptionModeOpen from 'Components/ExemptionModeOpen';
import { AuctionNoticeType } from 'Interfaces/auctionNotice';
import { isAuctioneer, isProvider } from '../../helpers/permissions';
import { useTranslation } from 'react-i18next';
import { getCompetentAuthorityName } from '../../helpers/auction-rules';
import { MethodDisputeEnum } from 'enums/method-dispute.enum';

export interface HelpText {
    [UserType.supplier]: string;
    [UserType.auctioneer]: string;
    [UserType.citizen]: string;
}

const ConditionalTimer: FC<ConditionalTimerProps> = ({
    changeStage,
    onFinishTimerLot,
    auctionLot,
    authUser,
    auctionNotice,
    onFinishProviderPeriodConvoked,
    serverTimestamp,
    bidPeriodCloseMode,
}) => {
    const { t } = useTranslation();

    const getDataByStage = useCallback((): {
        title: string;
        helpText: HelpText;
    } => {
        switch (auctionLot.stage) {
            case StageLot.unStarted:
                return {
                    title: t('info.helper.unstarted.title'),
                    helpText: {
                        [UserType.supplier]: t('info.helper.unstarted.message'),
                        [UserType.auctioneer]: t('info.helper.unstarted.message'),
                        [UserType.citizen]: t('info.helper.unstarted.message'),
                    },
                };

            case StageLot.awaiting_call_provider:
            case StageLot.awaiting_rejudge:
                return {
                    title: t('info.helper.convoked.title'),
                    helpText: {
                        [UserType.supplier]: t('info.helper.convoked.provider.message'),
                        [UserType.auctioneer]: t('info.helper.convoked.auctioneer.message'),
                        [UserType.citizen]: t('info.helper.convoked.citizen.message'),
                    },
                };

            case StageLot.finished:
            case StageLot.stopped:
                return {
                    title: t('info.helper.finished.title'),
                    helpText: {
                        [UserType.supplier]: t('info.helper.finished.provider.message'),
                        [UserType.auctioneer]: t('info.helper.finished.auctioneer.message'),
                        [UserType.citizen]: t('info.helper.finished.citizen.message'),
                    },
                };

            case StageLot.awaiting_repeat_close_period:
                return {
                    title: t('info.helper.repeat-close.title'),
                    helpText: {
                        [UserType.supplier]: t('info.helper.repeat-close.provider.message'),
                        [UserType.auctioneer]: t('info.helper.repeat-close.auctioneer.message'),
                        [UserType.citizen]: t('info.helper.repeat-close.citizen.message'),
                    },
                };

            case StageLot.paused:
                return {
                    title: t('info.helper.paused.title'),
                    helpText: {
                        [UserType.supplier]: t('info.helper.paused.provider.message'),
                        [UserType.auctioneer]: t('info.helper.paused.auctioneer.message'),
                        [UserType.citizen]: t('info.helper.paused.citizen.message'),
                    },
                };

            case StageLot.canceled:
                return {
                    title: t('info.helper.canceled.title'),
                    helpText: {
                        [UserType.supplier]: t('info.helper.canceled.provider.message', {
                            name: getCompetentAuthorityName(auctionNotice),
                        }),
                        [UserType.auctioneer]: t('info.helper.canceled.auctioneer.message'),
                        [UserType.citizen]: t('info.helper.canceled.citizen.message', {
                            name: getCompetentAuthorityName(auctionNotice),
                        }),
                    },
                };

            case StageLot.negotiation:
                return {
                    title: t('info.helper.negotiation.title'),
                    helpText: {
                        [UserType.supplier]: t('info.helper.negotiation.provider.message', {
                            name: getCompetentAuthorityName(auctionNotice),
                        }),
                        [UserType.auctioneer]: t('info.helper.negotiation.auctioneer.message'),
                        [UserType.citizen]: t('info.helper.negotiation.citizen.message', {
                            name: getCompetentAuthorityName(auctionNotice),
                        }),
                    },
                };

            case StageLot.negotiation_finished:
                return {
                    title: t('info.helper.negotiation-finished.title'),
                    helpText: {
                        [UserType.supplier]: t('info.helper.negotiation-finished.message'),
                        [UserType.auctioneer]: t('info.helper.negotiation-finished.message'),
                        [UserType.citizen]: t('info.helper.negotiation-finished.message'),
                    },
                };

            case StageLot.no_winner_finished:
                return {
                    title: t('info.helper.no-winner.title'),
                    helpText: {
                        [UserType.supplier]: t('info.helper.no-winner.message'),
                        [UserType.auctioneer]: t('info.helper.no-winner.message'),
                        [UserType.citizen]: t('info.helper.no-winner.message'),
                    },
                };

            case StageLot.random_close_period:
                return {
                    title: t('info.helper.random-close.title'),
                    helpText: {
                        [UserType.supplier]: t('info.helper.random-close.provider.message'),
                        [UserType.auctioneer]: t('info.helper.random-close.auctioneer.message'),
                        [UserType.citizen]: t('info.helper.random-close.citizen.message'),
                    },
                };

            case StageLot.random_close_period_ended:
                return {
                    title: t('info.helper.random-close-ended.title'),
                    helpText: {
                        [UserType.supplier]: t('info.helper.random-close-ended.provider.message'),
                        [UserType.auctioneer]: t(
                            'info.helper.random-close-ended.auctioneer.message'
                        ),
                        [UserType.citizen]: t('info.helper.random-close-ended.citizen.message'),
                    },
                };

            case StageLot.close_period:
                return {
                    title: t('info.helper.close.title'),
                    helpText: {
                        [UserType.supplier]: t('info.helper.close.provider.message'),
                        [UserType.auctioneer]: t('info.helper.close.auctioneer.message'),
                        [UserType.citizen]: t('info.helper.close.citizen.message'),
                    },
                };

            case StageLot.close_period_ended:
            case StageLot.second_close_period_ended:
            case StageLot.repeat_second_close_period_ended:
                return {
                    title: t('info.helper.close-ended.title'),
                    helpText: {
                        [UserType.supplier]: t('info.helper.close-ended.provider.message'),
                        [UserType.auctioneer]: t('info.helper.close-ended.auctioneer.message'),
                        [UserType.citizen]: t('info.helper.close-ended.citizen.message'),
                    },
                };

            case StageLot.second_close_period:
            case StageLot.repeat_second_close_period:
                return {
                    title: t('info.helper.second-close.title'),
                    helpText: {
                        [UserType.supplier]: t('info.helper.second-close.message'),
                        [UserType.auctioneer]: t('info.helper.second-close.message'),
                        [UserType.citizen]: t('info.helper.second-close.message'),
                    },
                };

            case StageLot.timeEnded:
                return {
                    title: t('info.helper.time-ended.title'),
                    helpText: {
                        [UserType.supplier]: t('info.helper.time-ended.provider.message'),
                        [UserType.auctioneer]: t('info.helper.time-ended.auctioneer.message'),
                        [UserType.citizen]: t('info.helper.time-ended.citizen.message'),
                    },
                };

            case StageLot.convoked:
                return {
                    title: t('info.helper.convoked2.title'),
                    helpText: {
                        [UserType.supplier]: t('info.helper.convoked2.provider.message'),
                        [UserType.auctioneer]: t('info.helper.convoked2.auctioneer.message'),
                        [UserType.citizen]: t('info.helper.convoked2.citizen.message'),
                    },
                };

            case StageLot.random_period:
                return {
                    title: t('info.helper.random.title'),
                    helpText: {
                        [UserType.supplier]: t('info.helper.random.provider.message'),
                        [UserType.auctioneer]: t('info.helper.random.auctioneer.message'),
                        [UserType.citizen]: t('info.helper.random.citizen.message'),
                    },
                };

            case StageLot.random_period_ended:
                return {
                    title: t('info.helper.random-ended.title'),
                    helpText: {
                        [UserType.supplier]: t('info.helper.random-ended.provider.message'),
                        [UserType.auctioneer]: t('info.helper.random-ended.auctioneer.message'),
                        [UserType.citizen]: t('info.helper.random-ended.citizen.message'),
                    },
                };

            default:
                return {
                    title: t('info.helper.default.title'),
                    helpText: {
                        [UserType.supplier]: t('info.helper.default.message'),
                        [UserType.auctioneer]: t('info.helper.default.message'),
                        [UserType.citizen]: t('info.helper.default.message'),
                    },
                };
        }
    }, [auctionLot.stage]);

    const timerToRender = () => {
        const { title } = getDataByStage();

        const authUserHelperText = () => {
            const { helpText } = getDataByStage();
            if (isProvider(authUser)) {
                return helpText[UserType.supplier];
            }

            if (isAuctioneer(authUser)) {
                return helpText[UserType.auctioneer];
            }

            return helpText[UserType.citizen];
        };

        const helpText = authUserHelperText();

        if (auctionNotice.auctionType === AuctionNoticeType.D && auctionNotice.methodDispute !== MethodDisputeEnum.notApplicable) {
            return auctionLot.convokedProvider ? (
                    <ModeConvokedProvider
                        key={`convokedMode:${auctionLot.id}`}
                        onFinishProviderPeriodConvoked={onFinishProviderPeriodConvoked}
                        changeStage={changeStage}
                        authUser={authUser}
                        serverTimestamp={serverTimestamp}
                        auctionLot={auctionLot}
                        helpText={helpText}
                        title={title}
                    />
                ) :
                (<ExemptionModeOpen
                    key={`exemptionOpenMode:${auctionLot.id}`}
                    onFinishTimerLot={onFinishTimerLot}
                    auctionLot={auctionLot}
                    changeStage={changeStage}
                    serverTimestamp={serverTimestamp}
                    helpText={helpText}
                    title={title}
                    auctionNotice={auctionNotice}
                />
            );
        }

        switch (auctionNotice.methodDispute) {
            case MethodDisputeEnum.open:
            case MethodDisputeEnum.closedOpen:
                return auctionLot.convokedProvider ? (
                    <ModeConvokedProvider
                        key={`convokedMode:${auctionLot.id}`}
                        onFinishProviderPeriodConvoked={onFinishProviderPeriodConvoked}
                        changeStage={changeStage}
                        authUser={authUser}
                        serverTimestamp={serverTimestamp}
                        auctionLot={auctionLot}
                        helpText={helpText}
                        title={title}
                    />
                ) : (
                    <ModeOpen
                        key={`openMode:${auctionLot.id}`}
                        onFinishTimerLot={onFinishTimerLot}
                        auctionLot={auctionLot}
                        changeStage={changeStage}
                        serverTimestamp={serverTimestamp}
                        helpText={helpText}
                        title={title}
                    />
                );
            case MethodDisputeEnum.openClosed:
            case MethodDisputeEnum.closed:
            case MethodDisputeEnum.notApplicable:
                return auctionLot.convokedProvider ? (
                    <ModeConvokedProvider
                        key={`convokedMode:${auctionLot.id}`}
                        onFinishProviderPeriodConvoked={onFinishProviderPeriodConvoked}
                        changeStage={changeStage}
                        authUser={authUser}
                        serverTimestamp={serverTimestamp}
                        auctionLot={auctionLot}
                        helpText={helpText}
                        title={title}
                    />
                ) : (
                    <ModeOpenClosed
                        key={`openClosedMode:${auctionLot.id}`}
                        onFinishTimerLot={onFinishTimerLot}
                        auctionLot={auctionLot}
                        serverTimestamp={serverTimestamp}
                        changeStage={changeStage}
                        bidPeriodCloseMode={bidPeriodCloseMode || false}
                        helpText={helpText}
                        title={title}
                    />
                );
        }
        return null;
    };

    return timerToRender();
};

export default ConditionalTimer;
