import { FC, useState } from 'react';
import Wrapper from '../Common/wrapper';
import { Button, Form, Input, Modal } from 'antd';
import ApiService from '../../services/api';
import { addNotification } from '../../helpers/notification';
import { useTranslation } from 'react-i18next';
import { ModalIntentionToAppealProps } from './props';
import { MdMoodBad } from 'react-icons/md';

const ModalIntentionToAppeal: FC<ModalIntentionToAppealProps> = ({ auction, auctionLot }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [message, setMessage] = useState('');

    const showModal = () => {
        setIsModalVisible(true);
    };

    const { t } = useTranslation();

    const doIntentionToAppeal = async () => {
        let error: any;
        await ApiService.doIntentionToAppeal(auction.id, auctionLot.id, message, (err) => {
            error = err;
        });

        if (error) {
            return;
        }

        setIsModalVisible(false);
        return addNotification(t('term.success'), t('term.success'), 'success', 3000);
    };

    return (
        <>
            {isModalVisible && (
                <Modal
                    title={t(`info.appeal-intent`)}
                    visible={isModalVisible}
                    onCancel={() => {
                        setIsModalVisible(false);
                    }}
                    okText={t('term.confirm')}
                    cancelText={t('info.cancel')}
                    width='400px'
                    onOk={doIntentionToAppeal}
                    okButtonProps={{
                        disabled: message.length === 0,
                    }}
                >
                    <Wrapper>
                        <Form layout='vertical'>
                            <Form.Item label={`${t('term.message')}:`}>
                                <Input.TextArea
                                    style={{
                                        resize: 'none',
                                    }}
                                    autoFocus
                                    value={message}
                                    onChange={(ev) => setMessage(ev.target.value)}
                                    rows={3}
                                    maxLength={300}
                                    placeholder={t('info.required.message')}
                                />
                            </Form.Item>
                        </Form>
                    </Wrapper>
                </Modal>
            )}
            <Button
                title={t('info.appeal-intent.message')}
                type='primary'
                shape='round'
                style={{
                    background: 'var(--platform-danger-color)',
                    border: 'none',
                    display: 'flex',
                    alignItems: 'center',
                }}
                icon={
                    <MdMoodBad
                        style={{
                            fontSize: '18px',
                        }}
                    />
                }
                size='small'
                onClick={showModal}
            />
        </>
    );
};

export default ModalIntentionToAppeal;
