import { FC, useState, useEffect, useRef, useCallback } from 'react';
import { ProposalsProps } from './props';
import Wrapper from '../Common/wrapper';
import Header from '../Common/Header';
import Scroll from '../Common/Scroll';
import ApiService from '../../services/api';
import { Participate } from '../../Interfaces/participate';
import { debounce, orderBy } from 'lodash';
import { SocketEvent, SocketEvents } from '../../Interfaces/socketEvents';
import TinyQueue from 'tinyqueue';
import { timeout } from 'helpers/timer';
import { Spin, SpinArea } from '../Common/Spin';
import { AuctionNotice } from 'Interfaces/auctionNotice';
import AuctioneerProposalItem from './components/AuctioneerProposalItem';
import ProviderProposalItem from './components/ProviderProposalItem';
import { isAuctioneer, isCitizen, isProvider } from '../../helpers/permissions';
import CitzenProposalItem from './components/CitzenProposalItem';
import { useTranslation } from 'react-i18next';
import { useLotsContext } from '../../pages/Initial/select-lots.context';
import { Pagination } from 'antd';
import { PaginationState } from 'Interfaces/pagination';

const LIMIT_PAGINATION = 10;

const initialPaginationState: PaginationState = {
    skip: 0,
    limit: LIMIT_PAGINATION,
}

const Proposals: FC<ProposalsProps> = ({
    auctionNoticeLotSelected,
    auctionNotice,
    socketConnection,
    authUser,
}) => {
    const [isOpened, setOpened] = useState(true);
    const [loading, setLoading] = useState(true);
    const { participants, setParticipants } = useLotsContext();
    const [pagination, setPagination] = useState<PaginationState>(initialPaginationState);
    const [auctionNoticeParticipantsCount, setAuctionNoticeParticipantsCount] = useState<number>(0);

    const participantsRef: any = useRef(null);
    participantsRef.current = { participants, setParticipants };

    const { t } = useTranslation();

    let queueLock = false;
    const queueEvents: any = new TinyQueue([]);

    const getParticipants = async () => {
        setLoading(true);
        const {participates, count} = await ApiService.getAuctionNoticeParticipants(
            auctionNoticeLotSelected.auctionNoticeId,
            auctionNoticeLotSelected.id,
            pagination
        );
        
        const participantsList = participates

        const replacedProposals = participantsList.map((participant) => ({
            ...participant,
            proposal: {
                ...participant.proposal,
                valueBidProposal: participant.proposal.valueBidProposal,
            },
        }));

        const { setParticipants } = participantsRef.current;

        if (participantsList?.length) {
            setParticipants([...replacedProposals]);
            setAuctionNoticeParticipantsCount(count)
        } else {
            setParticipants([]);
        }

        timeout(() => setLoading(false), 100);
    };

    useEffect(() => {
        if (auctionNoticeLotSelected?.id) {
            setPagination({...initialPaginationState})
        }
        
    }, [auctionNoticeLotSelected.id]);

    useEffect(() => {
        if (auctionNoticeLotSelected?.id) {
            getParticipants();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination])

    useEffect(() => {
        if (isAuctioneer(authUser)) {
            return handleAuctioneerSocketEvents();
        }

        return handleProviderSocketEvents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socketConnection]);

    const orderProposals = () => {
        const { judgmentCriterion } = auctionNotice;
        const { participants } = participantsRef.current;

        if (judgmentCriterion === 2 || judgmentCriterion === 3) {
            return orderBy(participants, 'proposal.valueBidProposal', 'desc');
        }
        return orderBy(participants, 'proposal.valueBidProposal', 'asc');
    };

    const handleAuctioneerSocketEvents = () => {
        if (!socketConnection) {
            return;
        }

        socketConnection.on(SocketEvents.providerOnline, (event: any) =>
            receiveEvent({
                type: SocketEvents.providerOnline,
                message: event,
            })
        );
    };

    const handleProviderSocketEvents = () => {
        if (!socketConnection) {
            return;
        }

        socketConnection.on(SocketEvents.classifiedProposalLot, (event: any) =>
            receiveEvent({
                type: SocketEvents.classifiedProposalLot,
                message: event,
            })
        );
    };

    const receiveEvent = (message: SocketEvent) => {
        queueEvents.push(message);

        if (!queueLock) {
            queueLock = true;
            processEvent();
        }
    };

    const processEvent = async () => {
        try {
            while (queueEvents.length) {
                const event: SocketEvent = queueEvents.pop();
                const { type, message } = event;

                switch (type) {
                    case SocketEvents.providerOnline:
                        return handleProviderStatus(message);

                    case SocketEvents.classifiedProposalLot:
                        const { lot } = message;
                        return handleClassifiedProposalLot(lot);
                }

                await new Promise((r) => timeout(r, 20));
            }
        } finally {
            queueLock = false;
        }
    };

    const handleClassifiedProposalLot = async (lot: AuctionNotice) => {
        if (auctionNoticeLotSelected.id !== lot.id) {
            return;
        }

        await getParticipants();
        setLoading(false);
    };

    const handleProviderStatus = (event: { providerAuctionCode: number; isOnline: boolean }) => {
        const { participants, setParticipants } = participantsRef.current;

        (participants as Participate[]).forEach((participate: Participate) => {
            if (participate.providerAuctionCode === event.providerAuctionCode) {
                participate.isOnline = event?.isOnline || false;
            }
        });

        setParticipants([...participants]);
    };

    const proposals = orderProposals();

    const getRenderProposal = () => {
        if (loading) {
            return (
                <SpinArea>
                    <Spin />
                </SpinArea>
            );
        }

        if (!proposals?.length) {
            return (
                <Wrapper margin='30px' textAlign='center'>
                    {t('info.no-proposals')}
                </Wrapper>
            );
        }

        return proposals.map((participant: Participate) => {
            if (isAuctioneer(authUser)) {
                return (
                    <AuctioneerProposalItem
                        participant={participant}
                        key={`proposal:${participant.id}`}
                        auctionNotice={auctionNotice}
                    />
                );
            }

            if (isProvider(authUser)) {
                return (
                    <ProviderProposalItem
                        participant={participant}
                        key={`proposal:${participant.id}`}
                        auctionNotice={auctionNotice}
                    />
                );
            }

            if (isCitizen(authUser)) {
                return (
                    <CitzenProposalItem
                        participant={participant}
                        key={`proposal:${participant.id}`}
                        auctionNotice={auctionNotice}
                    />
                );
            }

            return <></>;
        });
    };

    const handlePaginate = useCallback(
        debounce((page: number) => {
            setPagination((prevState) => ({
                ...prevState,
                skip: prevState.limit * (page - 1),
            }));
        }, 400),
        []
    );

    return (
        <Wrapper
            border='1px solid rgba(204, 204, 204, 0.5);'
            margin='10px 0'
            width='100%'
            minWidth='290px'
            minHeight={isOpened ? '88px' : 'auto'}
            height={isOpened ? 'auto' : '46px'}
        >
            <Header
                icon='file'
                showMinimize={true}
                title={t('term.classified-proposals')}
                onExpand={() => setOpened(!isOpened)}
                expandDirection={isOpened ? 'rotate(-90deg)' : 'rotate(0deg)'}
            />
            {isOpened && (
                <>
                    <Scroll
                        style={{
                            height: 'calc(100% - 46px)',
                            overflowY: 'auto',
                        }}
                    >
                        {getRenderProposal()}
                        
                    </Scroll>
                    {auctionNoticeParticipantsCount > LIMIT_PAGINATION && (
                        <Wrapper margin='15px 0' flexBox justifyContent='center' alignItems='center'>
                            <Pagination
                                current={Math.floor(pagination.skip / pagination.limit) + 1 ?? 1}
                                defaultCurrent={Math.floor(pagination.skip / pagination.limit) + 1 ?? 1}
                                showSizeChanger={false}
                                disabled={loading}
                                total={auctionNoticeParticipantsCount}
                                onChange={(page) => {
                                    handlePaginate(page);
                                }}
                            />
                        </Wrapper>
                    )}
                </>
            )}
        </Wrapper>
    );
};

export default Proposals;
