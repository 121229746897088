import { FC } from 'react';
import { CardLotModeAuctionnerProps } from './props';
import styled from 'styled-components';
import Wrapper from '../../../Common/wrapper';
import Image from '../../../Common/Image';
import { methodDisputeDescription } from '../../../../helpers/methodDispute';
import { BsChatText } from 'react-icons/bs';
import { MdOutlineSpaceDashboard } from 'react-icons/md';
import { isMobile } from '../../../../helpers/mobile';
import { dispatchWindowEvent } from '../../../../Hooks/window-event.hook';
import ReactSVG from 'Components/ReactSVG';

const Wrapped = styled(Wrapper)`
    height: 44px;
    border: 1px solid rgba(204, 204, 204, 0.5);
`;

const IconChat = styled(BsChatText)`
    width: 22px;
    cursor: pointer;
    height: 22px;
    margin: 0 12px;
    color: #555;
`;

const IconResume = styled(MdOutlineSpaceDashboard)`
    width: 22px;
    cursor: pointer;
    height: 22px;
    color: #555;
`;

const CardLotModeAuctionner: FC<CardLotModeAuctionnerProps> = ({ auctionNotice }) => {
    const handleClickChat = () => {
        dispatchWindowEvent('@event.open-chat', {});
    };

    const handleClickResume = () => {
        dispatchWindowEvent('@event.open-resume', {});
    };

    return (
        <Wrapped
            minWidth='180px'
            maxWidth='400px'
            bgcolor='#FFF'
            textAlign='center'
            padding='12px 24px'
            justifyContent='space-between'
            alignItems='center'
            borderRadius='5px'
            flexBox
        >
            <Wrapper alignItems='center' flexBox justifyContent='space-between' width='100%'>
                <Wrapper flexBox>
                    <Wrapper margin='0 8px 0 0'>
                        <ReactSVG
                            color='var(--platform-secondary-color)'
                            src='../../assets/icons/clock2.svg'
                            width='22px'
                            height='22px'
                        />
                    </Wrapper>
                    <Wrapper fontSize='15px' color='#555' fontWeight='700'>
                        {methodDisputeDescription({ methodDispute: auctionNotice.methodDispute })}
                    </Wrapper>
                </Wrapper>
                <Wrapper flexBox>
                    {isMobile() && (
                        <>
                            <IconChat onClick={handleClickChat} />
                            <IconResume onClick={handleClickResume} />
                        </>
                    )}
                </Wrapper>
            </Wrapper>
        </Wrapped>
    );
};

export default CardLotModeAuctionner;
