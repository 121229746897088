import { AppealStatusType, AuctionNotice } from '../Interfaces/auctionNotice';
import moment from 'moment';
import { AuctionNoticeLot } from '../Interfaces/auctionNoticeLot';

export const canIntentionToAppeal = (auction: AuctionNotice, auctionLot: AuctionNoticeLot) => {
    if (!auction.auctionTypeRules?.generalSettings?.solicitacoes?.recurso?.habilitar) {
        return false;
    }

    const withinTheDeadline =
        moment.utc(auction.deadlineIntentAppeal).valueOf() > moment().valueOf();

    if (!withinTheDeadline) {
        return false;
    }

    if (auctionLot.lotStage !== 'intentionAppeal' && auction.isPhaseReversal !== 1) {
        return false;
    }

    if (auction.appealStatus === AppealStatusType.closed  && auction.isPhaseReversal === 1) {
        return false;
    }

    return true;
};
