import React, { FC, useState, ReactElement } from 'react';
import Popover from 'react-tiny-popover';
import Wrapper from '../../../Common/wrapper';
import { pad } from '../../../../helpers/pad';
import { ProviderValuesPopoverInfoProps } from './props';
import { useTranslation } from 'react-i18next';

const ProviderValuesPopoverInfo: FC<ProviderValuesPopoverInfoProps> = ({
    children,
    providerValue,
}) => {
    const [modalOpened, setModalOpened] = useState(false);
    const cloneChildren = React.cloneElement(children as ReactElement<any>, {
        onMouseOver: (ev: any) => handleMouseEvent(ev),
        onMouseOut: (ev: any) => handleMouseEvent(ev),
    });

    const { t } = useTranslation();

    const handleMouseEvent = (ev: any) => {
        ev.stopPropagation();
        setModalOpened(!modalOpened);
    };

    const itsRegional =
        providerValue.itsRegionalCompany === 1 || providerValue.itsRegionalCompany === 2;
    const itsSimpleCompany = providerValue.itsSimpleCompany === 1;

    const getParticipateClosePeriodMessage = () => {
        if (providerValue.repeatTimeCloseBid === 1) {
            return (
                <span>
                    <b>{t('info.participated')}</b> {t('info.participated.closed.stage')}
                </span>
            );
        } else if (providerValue.repeatTimeCloseBid === 2) {
            return (
                <span>
                    <b>{t('info.participated')}</b> {t('info.participated.second-closed.stage')}
                </span>
            );
        }

        return null;
    };

    return (
        <Popover
            isOpen={modalOpened}
            position={['bottom', 'right', 'left']}
            content={() => (
                <Wrapper borderRadius='4px' bgcolor='#FFF' justifyContent='center' padding='15px'>
                    <Wrapper display='grid' textAlign='center' fontSize='13px'>
                        <span>
                            {`${t('info.provider.bid')} `}
                            <b>{pad(providerValue.providerAuctionCode)}</b>
                        </span>
                        <span>{itsRegional && <b>({t('term.regional')})</b>}</span>
                        <span>
                            {itsSimpleCompany && (
                                <b style={{ fontSize: '12px', color: 'var(--platform-secondary-color)' }}>ME/EPP/COOP</b>
                            )}
                        </span>
                        <br />
                        {getParticipateClosePeriodMessage()}
                    </Wrapper>
                </Wrapper>
            )}
        >
            {cloneChildren}
        </Popover>
    );
};

export default ProviderValuesPopoverInfo;
