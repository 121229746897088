import { ProviderValue, ProviderValueAction, ProviderValueStatus } from 'Interfaces/providerValues';
import { StageLot } from 'Interfaces/stageLot';
import { orderBy } from 'lodash';
import { currMoment } from '../../helpers/moment';
import moment from 'moment';
import { AuctionNoticeLot } from 'Interfaces/auctionNoticeLot';
import { ServerTimestamp } from 'Interfaces/serverTimestamp';
import { CONSTANTS } from '../../constants';

export const getProviderValuesActions = (
    providerValues: ProviderValue[],
    judgmentCriterion: number,
    auctionNoticeLotSelected: AuctionNoticeLot,
    serverTimestamp: ServerTimestamp
) => {
    providerValues = orderBy(
        providerValues,
        ['value', 'itsSimpleCompany', 'itsRegionalCompany'],
        judgmentCriterion === 2 || judgmentCriterion === 3 ? ['desc', 'desc', 'desc'] : ['asc', 'desc', 'desc']
    );

    const providerCalled = providerValues.find(
        (provValue) => provValue.status === ProviderValueStatus.called
    );
    const providerWinnerIndex = providerValues.findIndex(
        (provValue) => provValue.status === ProviderValueStatus.winner
    );
    const callableProvidersList = providerValues.filter(
        (prov) => prov.status === ProviderValueStatus.callable
    );

    const getDuration = (providerValue: any) => {
        const now = currMoment(serverTimestamp.difference);
        const finish = moment(providerValue.statusUpdatedAt).add(
            CONSTANTS.timersDuration.CONVOKED_PERIOD,
            'seconds'
        );
        return finish && moment.duration(finish.diff(now)).asSeconds();
    };

    if (providerWinnerIndex !== -1) {
        if (auctionNoticeLotSelected.stage === StageLot.negotiation_finished) {
            providerValues[providerWinnerIndex].currentAction = ProviderValueAction.show_winner;
        } else if (auctionNoticeLotSelected.stage === StageLot.negotiation) {
            providerValues[providerWinnerIndex].currentAction =
                ProviderValueAction.show_negotiating;
        }
    } else if (!!providerCalled && getDuration(providerCalled) > 0) {
        const calledIndex = providerValues.findIndex(
            (provValue) => provValue.id === providerCalled.id
        );

        if (calledIndex !== -1) {
            providerValues[calledIndex].currentAction = ProviderValueAction.show_convoked;
        }
    } else if (callableProvidersList.length) {
        const index = providerValues.findIndex((prov) => prov.id === callableProvidersList[0].id);
        providerValues[index].currentAction = ProviderValueAction.show_convoke;
    }

    providerValues.forEach((provValue) => {
        if (providerWinnerIndex === -1) {
            if (
                provValue.status === ProviderValueStatus.not_covered ||
                (provValue.status === ProviderValueStatus.called &&
                    getDuration(provValue) < 0)
            ) {
                provValue.currentAction = ProviderValueAction.show_not_covered;
            }
        }

        if (provValue.status === ProviderValueStatus.winner_unclassified) {
            provValue.currentAction = ProviderValueAction.show_unclassified;
        } else if (provValue.status === ProviderValueStatus.winner_disabled) {
            provValue.currentAction = ProviderValueAction.show_disabled;
        }
    });

    return providerValues;
};
