import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { setDefaultFavicon, setPageTitle } from '../helpers/set-page-title';
import { Platform } from '../Interfaces/platform';
import ApiService from '../services/api';
import { useThemeContext } from 'theme';

export type PlatformContextType = {
    platform: Platform | undefined;
    setPlatform: React.Dispatch<React.SetStateAction<any | undefined>>;
};

export const PlatformContext = createContext<PlatformContextType>({
    platform: undefined,
    setPlatform: () => {},
});

export const usePlatformContext = () => useContext(PlatformContext);

export const PlatformContextProvider = ({ children }: { children: ReactNode }) => {
    const [platform, setPlatform] = useState<Platform | undefined>(undefined);

    const { setPlatformColor } = useThemeContext();

    useEffect(
        () => {
            getPlatform();
        } /* eslint-disable-line */,
        []
    );

    const getPlatform = async () => {
        const platform = await ApiService.getPlatform();
        setPlatform(platform);
        setDefaultFavicon(platform.urlOriginalIcon);
        setPageTitle(platform.description, undefined, true);
        if (platform?.themeColors) setPlatformColor(platform.themeColors);
    };

    return (
        <PlatformContext.Provider
            value={{
                platform,
                setPlatform,
            }}
        >
            {children}
        </PlatformContext.Provider>
    );
};
