import { BiHistory } from 'react-icons/bi';
import styled from 'styled-components';

const BidHistoryIcon = styled(BiHistory)`
    cursor: pointer;
    font-size: 19px;
    color: #666;
`;

export { BidHistoryIcon };
