import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AuctionNotice } from '../../../../../Interfaces/auctionNotice';
import { Platform } from '../../../../../Interfaces/platform';
import { ServerTimestamp } from '../../../../../Interfaces/serverTimestamp';
import Image from '../../../Image';
import Clock from '../../../Timer';
import Wrapper from '../../../wrapper';
import { Content } from '../../styles';
import { getAuctionTypeDescription } from 'helpers/get-auction-type-label';
interface DefaultHeaderProps {
    auctionNotice: AuctionNotice;
    serverTimestamp: ServerTimestamp;
    platform?: Platform;
}

const DefaultHeader: FC<DefaultHeaderProps> = ({ auctionNotice, serverTimestamp, platform }) => {
    const { t } = useTranslation();

    const auctionTypeName = getAuctionTypeDescription(
        auctionNotice?.auctionType,
        t('term.auction')
    );

    const processTitle = `${auctionTypeName} ${t('info.electronic')}`;

    return (
        <Content
            bgcolor='var(--platform-secondary-color)'
            padding='5px 30px 5px 20px'
            alignItems='center'
            flexBox
            width='100%'
            color='#FFF'
            height='70px'
            justifyContent='space-between'
        >
            <Wrapper>
                <Image width='auto' height='54px' src={platform?.urlNegativeLogo as string} />
            </Wrapper>
            <Wrapper>
                <Wrapper
                    flexBox
                    style={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                    }}
                >
                    <span
                        style={{
                            fontSize: '14px',
                        }}
                    >
                        {t('info.header.title')} Nº:{' '}
                        <span
                            style={{
                                fontWeight: 700,
                            }}
                        >
                            {' '}
                            {auctionNotice?.auctionNumber}
                        </span>
                    </span>
                </Wrapper>
                <Wrapper
                    flexBox
                    style={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                    }}
                >
                    <span
                        style={{
                            fontSize: '14px',
                        }}
                    >
                        {processTitle} Nº:{' '}
                        <span
                            style={{
                                fontWeight: 700,
                            }}
                        >
                            {' '}
                            {auctionNotice?.accreditationNumber}
                        </span>
                    </span>
                </Wrapper>
                <Wrapper
                    flexBox
                    justifyContent='center'
                    style={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                    }}
                >
                    <span
                        style={{
                            fontSize: '14px',
                        }}
                    >
                        {auctionNotice.organizationUnit?.organizationUnitName || '--'}:{' '}
                        <span
                            style={{
                                fontWeight: 700,
                            }}
                        >
                            {' '}
                            {`${auctionNotice.organization?.organizationName || '--'}`}
                        </span>
                    </span>
                </Wrapper>
            </Wrapper>
            <Clock serverTimestamp={serverTimestamp} />
        </Content>
    );
};

export default DefaultHeader;
