import { orderBy } from 'lodash';
import { currMoment } from './moment';
import { AuctionNoticeLot } from '../Interfaces/auctionNoticeLot';
import moment from 'moment';

const checkIfPaused = ({ auctionLot }: { auctionLot: AuctionNoticeLot }) => {
    if (!auctionLot.pauses || auctionLot.pauses.length === 0) {
        return false;
    }

    const pauses = orderBy(auctionLot.pauses, 'pauseStart', 'asc');

    const lastPause = pauses[auctionLot.pauses?.length - 1];
    if (!!lastPause.pauseEnd && lastPause.pauseEnd !== null) {
        return false;
    }

    if (!pauses[pauses?.length - 1].pauseEnd) {
        return true;
    }
};

const isRenewLot = ({ auctionLot }: { auctionLot: AuctionNoticeLot }) =>
    !!auctionLot.bidStartDate && !!auctionLot.bidRenewDate;

const differenceBetweenPauses = ({
    initPeriodPause,
    auctionLot,
    serverDifference,
}: {
    initPeriodPause: string | null;
    auctionLot: AuctionNoticeLot;
    serverDifference: moment.Duration;
}) => {
    let seconds: number = 0;
    const now = currMoment(serverDifference).valueOf();

    auctionLot.pauses
        ?.filter(
            (pause) =>
                initPeriodPause &&
                moment(pause.pauseStart).valueOf() >= moment(initPeriodPause).valueOf()
        )
        .forEach((pause) => {
            if (pause.pauseStart && pause.pauseEnd) {
                const diff = moment
                    .duration(moment(pause.pauseEnd).diff(moment(pause.pauseStart)))
                    .asSeconds();

                seconds += diff;
            } else if (pause.pauseStart && !pause.pauseEnd) {
                const diff = moment
                    .duration(moment(now).diff(moment(pause.pauseStart)))
                    .asSeconds();

                seconds += diff;
            }
        });
    return seconds;
};

const differenceBetweenStartAndFinish = ({
    pauseDiff,
    auctionLot,
    periodTimer,
    serverDifference,
}: {
    pauseDiff: number;
    auctionLot: AuctionNoticeLot;
    periodTimer: number;
    serverDifference: moment.Duration;
}) => {
    const now = currMoment(serverDifference).valueOf();
    const endDate =
        auctionLot.bidStartDate &&
        moment(auctionLot.bidStartDate).subtract(pauseDiff, 'seconds').add(periodTimer, 'seconds');

    let secondsToFinish = (endDate && moment.duration(endDate.diff(now)).asSeconds()) ?? 0;

    if (secondsToFinish > periodTimer) {
        secondsToFinish = periodTimer;
    }

    return !!secondsToFinish && secondsToFinish > 0 ? Math.round(secondsToFinish) : 0;
};

const alreadyStarted = ({
    pauseDiff,
    auctionLot,
    serverDifference,
    periodTimer,
}: {
    pauseDiff: number;
    auctionLot: AuctionNoticeLot;
    serverDifference: moment.Duration;
    periodTimer: number;
}) => {
    const now = currMoment(serverDifference).valueOf();

    const finish = moment(auctionLot.bidStartDate)
        .subtract(pauseDiff, 'seconds')
        .add(periodTimer, 'minutes');
    const diff = moment.duration(moment(finish).diff(now)).asSeconds();

    const bidStarted =
        auctionLot.bidStartDate &&
        moment(auctionLot.bidStartDate).subtract(pauseDiff, 'seconds').valueOf();

    return (!!bidStarted && bidStarted <= now) || diff > 0;
};

const diffBetweenNowAndExtensionTime = ({
    auctionLot,
    closedPeriodTimer,
    serverDifference,
}: {
    auctionLot: AuctionNoticeLot;
    closedPeriodTimer: number;
    serverDifference: moment.Duration;
}) => {
    const now = currMoment(serverDifference).valueOf();
    const InitialDate =
        auctionLot.extensionTime &&
        moment(auctionLot.extensionTime).add(closedPeriodTimer, 'seconds');

    const secondsToFinish = InitialDate && moment.duration(InitialDate.diff(now)).asSeconds();
    return !!secondsToFinish && secondsToFinish > 0 ? secondsToFinish : 0;
};

const checkIfExistExtensionBid = ({ auctionLot }: { auctionLot: AuctionNoticeLot }) =>
    !!auctionLot.extensionTime;

export {
    checkIfPaused,
    isRenewLot,
    differenceBetweenPauses,
    differenceBetweenStartAndFinish,
    alreadyStarted,
    diffBetweenNowAndExtensionTime,
    checkIfExistExtensionBid,
};
