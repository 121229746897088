import { BackOfficeTheme, PlatformColors,  ThemeTypes } from 'Interfaces/theme';
import { adjustBrightness } from 'helpers/colors.utils';
import { Dispatch, FC, SetStateAction, createContext, useCallback, useContext, useEffect, useState } from 'react';
import baseStyled, { ThemeProvider, ThemedStyledInterface } from 'styled-components';

export type ThemeContextType = {
    platformColor?: PlatformColors;
    setPlatformColor: Dispatch<SetStateAction<PlatformColors>>;
    selectedTheme: ThemeTypes;
    setSelectedTheme: Dispatch<SetStateAction<ThemeTypes>>;
};

export const ThemeContext = createContext<ThemeContextType>({
    platformColor: undefined,
    setPlatformColor: () => {},
    selectedTheme: ThemeTypes.light,
    setSelectedTheme: () => {},
});

export const useThemeContext = () => useContext(ThemeContext);

const Theme: FC = ({ children }) => {
    const [selectedTheme, setSelectedTheme] = useState<ThemeTypes>(ThemeTypes.light);
    const [platformColor, setPlatformColor] = useState<PlatformColors>({
        primary: '#1D1CE5',
        secondary: adjustBrightness('#1D1CE5', -80),
        terciary: '#72FFFF',
        danger: '#e74c3c',
        success: '#02ae51',
        warning: '#efbe0e',
        info: '#317ec5',
    });

    const lightTheme: BackOfficeTheme = {
        colors: {
            primary: platformColor?.primary,
            secondary: platformColor?.secondary,
            tertiary: platformColor?.secondary,
            info: platformColor?.info,
            warning: platformColor?.warning,
            danger: platformColor?.danger,
            success: platformColor?.success,
            background: '#FFF',
            backgroundPageDefault: '#F7F7F7',
            gray: '#cccccc',
            lightGray: '#efefef',
            darkGray: '#a5a5a5',
        },
    };

    const darkTheme: BackOfficeTheme = {
        ...lightTheme,
        colors: {
            ...lightTheme.colors,
            backgroundPageDefault: '#444',
            background: '#444',
            gray: '#FFF',
        },
    };


    return (
        <ThemeContext.Provider value={{platformColor, setPlatformColor , selectedTheme, setSelectedTheme}}>
            <ThemeProvider theme={selectedTheme === ThemeTypes.dark ? darkTheme : lightTheme}>
                {children}
            </ThemeProvider>
        </ThemeContext.Provider>
    );
};

export const styled = baseStyled as ThemedStyledInterface<BackOfficeTheme>;
export default Theme;
