import styled from 'styled-components';
import { Badge } from 'antd';
import Wrapper from '../Common/wrapper';
import { HiStatusOnline } from 'react-icons/hi';
import { useLotsContext } from '../../pages/Initial/select-lots.context';

const Icon = styled(HiStatusOnline)`
    width: 26px;
    height: 26px;
    color: var(--platform-secondary-color);
`;

const Wrapped = styled(Wrapper)`
    height: 44px;
    border: 1px solid rgba(204, 204, 204, 0.5);
`;

const CardOnlineProviders = () => {
    const { participants } = useLotsContext();

    const participatesOnline = participants.filter((participate) => participate.isOnline).length;

    return (
        <Wrapped
            minWidth='170px'
            maxWidth='160px'
            bgcolor='#FFF'
            textAlign='center'
            padding='12px 24px'
            justifyContent='space-between'
            alignItems='center'
            borderRadius='5px'
            flexBox
        >
            <Wrapper flexBox alignItems='center'>
                <Icon />
                <span style={{ margin: '0 0 0 8px', fontSize: 15 }}>
                    <b>Online</b>
                </span>
            </Wrapper>
            <Badge showZero count={participatesOnline} />
        </Wrapped>
    );
};

export default CardOnlineProviders;
