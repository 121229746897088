const auctioneerPercentDiff = (reference: string, value: string, judgmentCriterion: number, rateTypeBid?: boolean) => {
    const ref = parseFloat(reference);
    const val = parseFloat(value);

    switch (judgmentCriterion) {
        case 1:
        case 3: {
            if (rateTypeBid) {
                return val;
            }
            return (val / ref - 1) * 100;
        }
        case 2: {
            return val;
        }
        default:
            return (val / ref - 1) * 100;
    }
};

const percentDiff = (a: string, b: string, judgmentCriterion: number, inverse?: boolean) => {
    switch (judgmentCriterion) {

        case 2: {
            let value = ((parseFloat(b) - parseFloat(a)) / parseFloat(b)) * 100;
            if (parseFloat(a) < 0 && inverse) {
                value = value * -1;
            }

            return value;
        }
        default: {
            let value = ((parseFloat(b) - parseFloat(a)) / parseFloat(a)) * 100;

            if (parseFloat(a) < 0 && inverse) {
                value = value * -1;
            }

            return value;
        }
    }
};

const simplePercentDiff = (a: string, b: string, judgmentCriterion: number) => percentDiff(a, b, judgmentCriterion);

interface GetPercentOptionsResponse {
    color: 'var(--platform-secondary-color)' | 'var(--platform-danger-color)' | '#555555';
    icon?: 'arrowUp' | 'arrowDown' | 'swap';
}

const getPercentOptions = (
    percent?: number,
    judgmentCriterion?: number,
    rateTypeBid?: boolean
): GetPercentOptionsResponse => {
    const defaultReturn: GetPercentOptionsResponse = { color: '#555555', icon: 'swap' };
    const bigger: GetPercentOptionsResponse = {
        color: 'var(--platform-secondary-color)',
        icon: 'arrowUp',
    };
    const lower: GetPercentOptionsResponse = {
        color: 'var(--platform-danger-color)',
        icon: 'arrowDown',
    };

    if (percent === undefined || percent === 0) return defaultReturn;

    switch (judgmentCriterion) {
        case 1: {
            if (rateTypeBid) return defaultReturn;
            return percent < 0 ? bigger : lower;
        }
        case 3:
        case 2: {
            return percent > 0 ? bigger : lower;
        }
        default: {
            return defaultReturn;
        }
    }
};

export { auctioneerPercentDiff, simplePercentDiff, percentDiff, getPercentOptions };
