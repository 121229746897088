import styled from 'styled-components';
import Wrapper from '../../Common/wrapper';
import { BiBlock } from 'react-icons/bi';

const Online = styled.div<{ isOnline: boolean }>`
    margin: 0 4px 0 0;
    border: 1px #fff solid;
    background: ${(props) => (props.isOnline ? '#48d41a' : '#e62525')};
    border-radius: 50%;
    width: 9px;
    height: 9px;

    ${(props) =>
        props.isOnline &&
        `
        @keyframes pulse {
            0% {
                transform: scale(0.95);
                box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
            }

            70% {
                transform: scale(1);
                box-shadow: 0 0 0 3px rgba(0, 0, 0, 0);
            }

            100% {
                transform: scale(0.95);
                box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            }
        }

        box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
        transform: scale(1);
        animation: pulse 2s infinite;
   `}
`;

const Item = styled(Wrapper)`
    :not(:last-child) {
        border-bottom: 1px solid #d7d7d7;
    }
`;

const DisableIcon = styled(BiBlock)`
    cursor: pointer;
    color: var(--platform-danger-color);
    font-size: 16px;
`;

export { Item, Online, DisableIcon };
