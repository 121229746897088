import styled from 'styled-components';
import { Button } from 'antd';

const SendButton = styled(Button)`
    background: ${({ theme }) => theme.colors.primary} !important;
    :hover {
        background: ${({ theme }) => theme.colors.primary};
    }
    margin: 0 0 0 5px;

    span {
        color: #fff;
    }
`;

const PulseButton = styled.div`
    border-radius: 50%;
    position: absolute;
    right: -7px;
    bottom: -12px;
    width: 22px;
    height: 22px;

    @keyframes pulse {
        0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
        }

        70% {
            transform: scale(1);
            box-shadow: 0 0 0 3px rgba(0, 0, 0, 0);
        }

        100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        }
    }

    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
`;

export { SendButton, PulseButton };
