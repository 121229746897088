import { FC, useState } from 'react';
import { Button, Checkbox, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';
import { addNotification } from '../../../../helpers/notification';
import { IAuctionNoticeLot } from '../../../../Interfaces/auctionNoticeLot';
import { SelectLotMode, useLotsContext } from '../../../../pages/Initial/select-lots.context';
import Wrapper from '../../../Common/wrapper';
import ApiService from '../../../../services/api';
import { AuctionNotice } from '../../../../Interfaces/auctionNotice';
import MessagePopover from '../../../Common/MessagePopover';
import ConfirmationPopover from '../../../Common/ConfirmationPopover';

interface BulkActionsProps {
    auctionNoticeLots: IAuctionNoticeLot;
    auctionNotice: AuctionNotice;
}

const BulkActions: FC<BulkActionsProps> = ({ auctionNotice, auctionNoticeLots }) => {
    const { t } = useTranslation();
    const {
        selectedLots,
        setSelectedLots,
        setBulkChangesMode,
        bulkChangesMode,
        selectedMode,
        setSelectedMode,
    } = useLotsContext();

    const [allChecked, setAllChecked] = useState(false);

    const doActionLot = async (message?: string, reasonFrustratedId?: number) => {
        ReactDOM.unstable_batchedUpdates(() => {
            setSelectedLots([]);
            setBulkChangesMode(false);
        });

        addNotification('Sucesso', 'A ação será realizada, aguarde um momento', 'info', 3000);

        switch (selectedMode) {
            case SelectLotMode.start: {
                await Promise.all(
                    selectedLots.map((lotId) =>
                        ApiService.startAuctionNoticeLot({
                            auctionLotId: lotId,
                            auctionNoticeId: auctionNotice.id,
                        })
                    )
                );
                break;
            }

            case SelectLotMode.cancel: {
                if (!message) {
                    return;
                }

                await Promise.all(
                    selectedLots.map((lotId) =>
                        ApiService.cancelLot({
                            auctionLotId: lotId,
                            auctionNoticeId: auctionNotice.id,
                            message,
                            reasonFrustratedId,
                        })
                    )
                );
                break;
            }

            case SelectLotMode.advance: {
                await Promise.all(
                    selectedLots.map((lotId) =>
                        ApiService.finishAuctionNoticeLot({
                            auctionLotId: lotId,
                            auctionNoticeId: auctionNotice.id,
                        })
                    )
                );
                break;
            }

            case SelectLotMode.finishNegotiation: {
                await Promise.all(
                    selectedLots.map((lotId) =>
                        ApiService.finishNegotiationAuctionNoticeLot({
                            auctionLotId: lotId,
                            auctionNoticeId: auctionNotice.id,
                        })
                    )
                );
                break;
            }
        }
    };

    if (!bulkChangesMode) {
        return null;
    }

    const onSelectAllLots = (event) => {
        const checked = event.target.checked;
        setAllChecked(checked);

        const selectedLotsSet = new Set(selectedLots);

        const lotsIds = Array.from(document.querySelectorAll('[id^="checkbox-lot:"]'))?.map((el) =>
            Number(el.id.split(':')[1])
        );

        if (checked) {
            lotsIds
                .slice(
                    0,
                    10 -
                        selectedLots.filter(
                            (selectedLotId) =>
                                !Object.keys(auctionNoticeLots).includes(String(selectedLotId))
                        ).length
                )
                .forEach((lotId) => selectedLotsSet.add(Number(lotId)));
        } else {
            lotsIds.forEach((lotId) => selectedLotsSet.delete(Number(lotId)));
        }

        const newSelectedLots = Array.from(selectedLotsSet);

        if (newSelectedLots.length > 10) {
            addNotification(
                'Limite atingido',
                'Você só pode selecionar até 10 lotes.',
                'info',
                3000
            );
        }

        setSelectedLots(newSelectedLots);
    };

    const defaultExecuteButton = () => {
        const disabled = !selectedLots.length || !selectedMode;

        return (
            <Button
                disabled={disabled}
                onClick={() => doActionLot('')}
                style={
                    disabled
                        ? {}
                        : {
                              background: 'var(--platform-primary-color)',
                              color: '#fff',
                          }
                }
            >
                Executar
            </Button>
        );
    };

    const getExecuteButtonByType = () => {
        switch (selectedMode) {
            case SelectLotMode.cancel:
                return (
                    <>
                        <MessagePopover
                            maxWidth='330px'
                            render={
                                <Wrapper fontSize='13px'>
                                    <p>{t('info.action.cancel.message')}</p>
                                    <p>{t('info.action.cancel.confirmation')}</p>
                                </Wrapper>
                            }
                            onConfirm={(message: string) => doActionLot(message)}
                        >
                            {defaultExecuteButton()}
                        </MessagePopover>
                    </>
                );

            default:
                return (
                    <>
                        <ConfirmationPopover
                            render={
                                <Wrapper fontSize='13px'>
                                    <p>{'Deseja continuar com a ação em massa?'}</p>
                                </Wrapper>
                            }
                            maxWidth='240px'
                            onConfirm={() => doActionLot()}
                        >
                            {defaultExecuteButton()}
                        </ConfirmationPopover>
                    </>
                );
        }
    };

    return (
        <Wrapper
            height='50px'
            display='flex'
            alignItems='center'
            padding='0 30px'
            justifyContent='space-between'
            bgcolor='#fff6bc'
            position='relative'
            borderBottom='1px #e1e1e1 solid'
        >
            <Wrapper>
                <Checkbox
                    checked={allChecked}
                    onChange={onSelectAllLots}
                    style={{
                        position: 'absolute',
                        top: '15px',
                        left: '12px',
                        zIndex: '1',
                    }}
                />
            </Wrapper>
            <span style={{ fontSize: 15, color: '#444', fontWeight: 600 }}>
                {selectedLots.length ? (
                    <>
                        <b style={{ fontSize: 18 }}>{String(selectedLots.length)}</b> Lote(s)
                        selecionados
                    </>
                ) : (
                    `Nenhum lote selecionado`
                )}
            </span>
            <>
                <Select
                    placeholder='Selecione uma ação'
                    style={{ minWidth: 140 }}
                    value={selectedMode}
                    onChange={(value) => {
                        setSelectedMode(value);

                        if (!selectedMode || selectedMode !== value) {
                            setSelectedLots([]);
                            setAllChecked(false);
                        }
                    }}
                >
                    <Select.Option value={SelectLotMode.start}>Iniciar lances</Select.Option>
                    <Select.Option value={SelectLotMode.cancel}>Cancelar</Select.Option>
                </Select>

                {getExecuteButtonByType()}
            </>
        </Wrapper>
    );
};

export default BulkActions;
