import React, { FC, useState, useEffect } from 'react';
import { v4 } from 'uuid';
import sanitize from 'sanitize-html';
import { useTranslation } from 'react-i18next';

export interface ReadMoreProps extends React.HTMLAttributes<HTMLDivElement> {
    size: number;
    style: React.CSSProperties;
    text: string;
}

const ReadMore: FC<ReadMoreProps> = ({ text, size, style, ...rest }) => {
    const [readMoreVisible, setReadMoreVisible] = useState(text?.length > size);
    const id = v4();
    const { t } = useTranslation();

    const sanitizeHtml = (html: string) => {
        return sanitize(html, {
            allowedTags: ['b', 'i', 'em', 'strong', 'span'],
            allowedAttributes: {},
            allowedIframeHostnames: [],
        });
    };

    useEffect(() => {
        const div = document.getElementById(id);
        if (!div) {
            return;
        }

        div.innerHTML =
            text.length > size && readMoreVisible
                ? sanitizeHtml(`${text.slice(0, size)}..`)
                : sanitizeHtml(text);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [readMoreVisible]);

    return (
        <div {...rest}>
            <span
                style={{
                    ...style,
                    wordBreak: 'break-word',
                }}
                id={id}
            />
            {readMoreVisible && (
                <span
                    style={{
                        cursor: 'pointer',
                        color: '#2451c2',
                        fontSize: '12px',
                    }}
                    onClick={() => setReadMoreVisible(false)}
                >{` ${t('info.chat.read-more')}`}</span>
            )}
        </div>
    );
};

export default ReadMore;
