export enum DeclineType {
    definitive = 'definitive',
    temporary = 'temporary',
}

export interface Decline {
    id: number;
    lotId?: number;
    providerId: number;
    auctionNoticeId: number;
    providerMessage?: string;
    auctioneerMessage?: string;
    isDeferred?: number;
    createdAt: Date;
    type: DeclineType;
    lot: {
        item: string;
    };
    participate: {
        providerAuctionCode: number;
    };
}
