import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import styled from 'styled-components';

const Spin = styled(AiOutlineLoading3Quarters)`
    @keyframes rotation {
        from {
            -webkit-transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(359deg);
        }
    }

    width: 34px;
    height: 34px;
    animation: rotation 800ms infinite linear;
    color: #777;
`;

const SpinArea = styled.div`
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 1;
    overflow: hidden;
    padding: 5px;
`;

export { Spin, SpinArea };
