import React from 'react';
import * as SocketIo from 'socket.io-client';

export function SocketHandler(OriginalComponent: any) {
    const handleSocketEvents = (streamUrl: string) => {
        return SocketIo.connect(streamUrl);
    };

    return class extends React.Component<any> {
        render() {
            return <OriginalComponent {...this.props} initSocket={handleSocketEvents} />;
        }
    };
}
