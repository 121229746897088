import { SpinArea, Spin } from 'Components/Common/Spin';
import { Content } from './styled';

const Authenticating = () => {
    return (
        <Content>
            <SpinArea>
                <Spin />
            </SpinArea>
        </Content>
    );
};

export default Authenticating;
