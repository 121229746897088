import { FC } from 'react';
import { ProviderValueItemSupplierProps } from './props';
import Wrapper from '../../../Common/wrapper';
import styled from 'styled-components';
import { moment } from '../../../../helpers/moment';
import { formatValueWithAuctionRules } from '../../../../helpers/currencyTransform';
import { ProviderValueAction } from '../../../../Interfaces/providerValues';
import { useTranslation } from 'react-i18next';
import { isDeclinedProviderValue } from '../../../../helpers/declined';
import Badge from '../../../Common/Badge';
import HelpText from 'Components/HelpText';
import ReactSVG from 'Components/ReactSVG';

const ProviderValueItemSupplier: FC<ProviderValueItemSupplierProps> = ({
    providerValue,
    index,
    auctionNotice,
    authUser,
}) => {
    const { t } = useTranslation();

    const Wrapped = styled(Wrapper)`
        :not(:last-child) {
            border-bottom: 1px solid #d7d7d7;
        }
    `;

    const getStyleButton = () => {
        if (providerValue.isAuthProviderBid) {
            return {
                bgColor: 'var(--platform-secondary-color)',
                title: t('info.your.best.bid'),
                color: '#FFF',
                showIndicator: true,
            };
        } else {
            return {
                bgColor: "var(--gray-color)",
                title: '',
                color: '#444',
            };
        }
    };

    const getActionByStatus = () => {
        switch (providerValue.currentAction) {
            case ProviderValueAction.show_winner:
                return {
                    title: t('info.provider-value.status.winner.title'),
                    actionName: t('info.provider-value.status.winner.action'),
                    short: t('info.provider-value.status.winner.short'),
                    canShow: true,
                };
            case ProviderValueAction.show_not_covered:
                return {
                    title: t('info.provider-value.status.not-covered.title'),
                    actionName: t('info.provider-value.status.not-covered.action'),
                    bgColor: 'var(--platform-warning-color)',
                    short: t('info.provider-value.status.not-covered.short'),
                    canShow: authUser.providerAuctionCode === providerValue.providerAuctionCode,
                };
            case ProviderValueAction.show_convoked:
                return {
                    title: t('info.provider-value.status.convoked.title'),
                    actionName: t('info.provider-value.status.convoked.action'),
                    short: t('info.provider-value.status.convoked.short'),
                    canShow: authUser.providerAuctionCode === providerValue.providerAuctionCode,
                };
            case ProviderValueAction.show_negotiating:
                return {
                    title: t('info.provider-value.status.negotiating.title'),
                    actionName: t('info.provider-value.status.negotiating.action'),
                    bgColor: 'var(--platform-tertiary-color)',
                    short: t('info.provider-value.status.negotiating.short'),
                    canShow: true,
                };
            case ProviderValueAction.show_unclassified:
                return {
                    title: t('info.provider-value.status.unclassified.title'),
                    actionName: t('info.provider-value.status.unclassified.action'),
                    bgColor: '#881c2a',
                    short: t('info.provider-value.status.unclassified.short'),
                    canShow: true,
                };
            case ProviderValueAction.show_disabled:
                return {
                    title: t('info.provider-value.status.disabled.title'),
                    actionName: t('info.provider-value.status.disabled.action'),
                    bgColor: '#d22840',
                    short: t('info.provider-value.status.disabled.short'),
                    canShow: true,
                };
        }
    };

    const getFormattedValue = (value: number | string) => {
        return formatValueWithAuctionRules(
            value,
            auctionNotice.judgmentCriterion,
            auctionNotice.rateTypeBid,
            auctionNotice.decimalPlaces
        ).formatted;
    }

    const providerValueStatus = getActionByStatus();
    const button = getStyleButton();

    return (
        <Wrapped
            flexBox
            width='100%'
            height='45px'
            padding='6px 5px'
            margin='4px 0'
            overflowY='hidden'
        >
            <Wrapper width='100%' alignItems='center' flexBox>
                <Wrapper margin='0 5px 0 0 ' width='10px'>
                    {button.showIndicator && (
                        <ReactSVG
                            color='var(--platform-secondary-color)'
                            src='../../assets/icons/arrowRight.svg'
                            width='7px'
                            height='10px'
                        />
                    )}
                </Wrapper>
                <Wrapper justifyContent='space-between' width='100%' alignItems='center' flexBox>
                    <Wrapper alignItems='center' flexBox>
                        <Wrapper alignItems='center' flexBox>
                            <Wrapper
                                borderRadius='50%'
                                title={button.title}
                                bgcolor={button.bgColor}
                                width='28px'
                                flexBox
                                alignItems='center'
                                justifyContent='center'
                                color={button.color}
                                fontSize='13px'
                                fontWeight='700'
                                height='28px'
                            >
                                {`${index < 9 ? `0${index + 1}` : `${index + 1}`}º`}
                            </Wrapper>
                            <Wrapper width='70px' margin='0 7px'>
                                <Wrapper fontSize='12px'>
                                    {moment(
                                        providerValue.statusUpdatedAt || providerValue.createdAt
                                    )}
                                </Wrapper>
                                <Wrapper fontSize='9px' color='var(--platform-secondary-color)'>
                                    {providerValue.itsSimpleCompany === 1 ? 'ME/EPP/COOP' : ''}
                                </Wrapper>
                            </Wrapper>
                        </Wrapper>
                        <Wrapper truncate fontSize='14px' margin=' 0 0 0 5px'>
                            {getFormattedValue(providerValue.value)}
                            {!!providerValue?.proposal?.additionalExpense && (
                                <HelpText
                                    size={12}
                                    helpText={t('info.lot-proposal-values', {
                                        totalValue: getFormattedValue(
                                            providerValue.value ?? ''
                                        ),
                                        baseValue: getFormattedValue(
                                            Number(providerValue.value ?? 0) - Number(providerValue.proposal.additionalExpense ?? 0)
                                        ),
                                        additionalExpense: getFormattedValue(
                                            providerValue.proposal.additionalExpense ?? ''
                                        ),
                                    })}
                                />
                            )}
                        </Wrapper>
                    </Wrapper>
                    <Wrapper alignItems='center' flexBox>
                        {!!providerValueStatus && providerValueStatus.canShow && (
                            <Wrapper
                                height='20px'
                                bgcolor={providerValueStatus.bgColor || 'var(--platform-secondary-color)'}
                                color='#fff'
                                fontSize='9px'
                                width='24px'
                                flexBox
                                alignItems='center'
                                borderRadius='4px'
                                justifyContent='center'
                                padding='0 2px'
                                fontWeight='600'
                                title={providerValueStatus?.title}
                            >
                                {providerValueStatus?.short}
                            </Wrapper>
                        )}
                        {isDeclinedProviderValue(providerValue) && (
                            <Badge
                                title={t('info.provider-declined')}
                                fontSize='9px'
                                color='#a47110'
                                width='24px'
                                margin='0 0 0 3px'
                            >
                                {`DEC`}
                            </Badge>
                        )}
                    </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapped>
    );
};

export default ProviderValueItemSupplier;
