import { FC } from 'react';
import { CardLotModeProps } from './props';
import CardLotModeSupplier from './components/supplier';
import CardLotModeAuctionner from './components/auctionner';
import { isProvider } from '../../helpers/permissions';

const CardLotMode: FC<CardLotModeProps> = ({ authUser, auctionNotice }) => {
    return isProvider(authUser) ? (
        <CardLotModeSupplier authUser={authUser} auctionNotice={auctionNotice} />
    ) : (
        <CardLotModeAuctionner auctionNotice={auctionNotice} />
    );
};

export default CardLotMode;
