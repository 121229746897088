import { FC } from 'react';
import { AuctionNotice } from '../../../../../Interfaces/auctionNotice';
import { Platform } from '../../../../../Interfaces/platform';
import { ServerTimestamp } from '../../../../../Interfaces/serverTimestamp';
import Image from '../../../Image';
import Clock from '../../../Timer';
import { Content } from '../../styles';

interface MobileHeaderProps {
    auctionNotice: AuctionNotice;
    serverTimestamp: ServerTimestamp;
    platform?: Platform;
}

const MobileHeader: FC<MobileHeaderProps> = ({ serverTimestamp, platform }) => {
    return (
        <Content
            bgcolor="var(--platform-primary-color)"
            padding='5px 10px 5px 10px'
            alignItems='center'
            flexBox
            width='100%'
            color='#FFF'
            height='70px'
            justifyContent='space-between'
            overflowX='hidden'
        >
            <Image height='40px' src={platform?.urlNegativeLogo as string} />
            <Clock serverTimestamp={serverTimestamp} />
        </Content>
    );
};

export default MobileHeader;
