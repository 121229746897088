import { AuctionNoticeLot } from '../Interfaces/auctionNoticeLot';
import { Bid } from '../Interfaces/bid';
import { ProviderValue, ProviderValueStatus } from '../Interfaces/providerValues';
import { StageLot } from '../Interfaces/stageLot';

export const isDeclinedBid = (bid: Bid) => {
    return bid.temporaryDecline?.id || bid.definiveDecline?.id;
};

export const isDeclinedProviderValue = (providerValue: ProviderValue) => {
    return providerValue.status === ProviderValueStatus.provider_declined;
};

export const isDeclinedLot = (lot: AuctionNoticeLot) => {
    return lot.temporaryDecline?.id || lot.definiveDecline?.id;
};

export const showDeclineActionProvider = (lot: AuctionNoticeLot) => {
    return [
        StageLot.negotiation_finished,
        StageLot.canceled,
        StageLot.negotiation,
        StageLot.no_winner_finished,
    ].includes(lot.stage);
};
