import { FC } from 'react';
import Wrapper from '../../../Common/wrapper';
import { formatValueWithAuctionRules } from '../../../../helpers/currencyTransform';
import { pad } from '../../../../helpers/pad';
import { Item } from '../styles';
import { CitzenProposalItemProps } from './props';
import { useTranslation } from 'react-i18next';
import ProposalItemValueLabel from '../ProposalItemValueLabel';

const CitzenProposalItem: FC<CitzenProposalItemProps> = ({ participant, auctionNotice }) => {
    const code = pad(participant.providerAuctionCode);
    const { formatted } = formatValueWithAuctionRules(
        participant.proposal?.valueBidProposal,
        auctionNotice.judgmentCriterion,
        auctionNotice.rateTypeBid,
        auctionNotice.decimalPlaces
    );

    const { t } = useTranslation();

    return (
        <Item flexBox padding='8px 5px' alignItems='center' margin='3px 0'>
            <Wrapper justifyContent='space-between' alignItems='center' flex flexBox>
                <Wrapper fontSize='12px'>{`${t('term.provider.token')} ${code}`}</Wrapper>
                <Wrapper
                    truncate
                    title={t('info.proposal.initial.value', { value: formatted })}
                    fontSize='13px'
                    color='#444'
                    fontWeight='700'
                    textAlign='end'
                >
                    {formatted}
                    <ProposalItemValueLabel {...{ participant, auctionNotice }} />
                </Wrapper>
            </Wrapper>
        </Item>
    );
};

export default CitzenProposalItem;
