const defaults = {
    storageMap: {
        NOTIFICATIONS: '@notifications',
        FILTERS: '@filters',
        AUTH: '@auth',
    },
    timersDuration: {
        CONVOKED_PERIOD: 300,
        OPEN_PERIOD_EXTENSION_TIME: 120,
        CLOSE_PERIOD: 300,
        OPEN_PERIOD: 600,
        OPEN_CLOSED_PERIOD: 900,
    }, // seconds
    responsize: {
        width: 600,
    },
};

const variables = {
    ...defaults,
    API_URL: process.env.REACT_APP_API_URL,
    SERVICES_URL: process.env.API_GATEWAY_SERVICES_URL,
};

export const CONSTANTS = variables;
