import React, { FC, useState, ReactElement, useEffect } from 'react';
import { BidInformationProps } from './props';
import Popover from 'react-tiny-popover';
import Wrapper from '../../../Common/wrapper';
import { pad } from '../../../../helpers/pad';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';

const BidInformation: FC<BidInformationProps> = ({ children, bid, bids, omitPercentInfo }) => {
    const [modalOpened, setModalOpened] = useState(false);
    const { t } = useTranslation();

    const cloneChildren = React.cloneElement(children as ReactElement<any>, {
        onMouseOver: (ev: any) => handleMouseEvent(ev),
        onMouseOut: (ev: any) => handleMouseEvent(ev),
    });

    const handleMouseEvent = (ev: any) => {
        ev.stopPropagation();
        setModalOpened(!modalOpened);
    };

    useEffect(() => {
        if (!omitPercentInfo) {
            calculateVariation();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalOpened, bids, omitPercentInfo]);

    let variation: any;

    const calculateVariation = () => {
        const currentBid = bids.findIndex((currBid) => currBid.id === bid.id);

        const nextBid = bids[currentBid + 1];
        if (currentBid !== -1 && nextBid !== undefined) {
            variation = (parseFloat(nextBid.value) / parseFloat(bid.value) - 1) * 100;
            variation = variation.toFixed(2);
        }
    };

    const itsRegional = bid.itsRegionalCompany === 1 || bid.itsRegionalCompany === 2;
    const itsSimpleCompany = bid.itsSimpleCompany === 1;

    return (
        <Popover
            isOpen={modalOpened}
            position={['bottom', 'right', 'left']}
            content={() => (
                <Wrapper borderRadius='4px' bgcolor='#FFF' justifyContent='center' padding='15px'>
                    <Wrapper display='grid' textAlign='center' fontSize='13px'>
                        <span>
                            {`${t('info.provider.bid')} `}
                            <b>{pad(bid.providerAuctionCode)}</b>
                        </span>
                        <span>{itsRegional && <b>({t('term.regional')})</b>}</span>
                        <span>
                            {itsSimpleCompany && (
                                <b
                                    style={{
                                        fontSize: '12px',
                                        color: 'var(--platform-secondary-color)',
                                    }}
                                >
                                    ME/EPP/COOP
                                </b>
                            )}
                        </span>
                    </Wrapper>
                    {variation !== undefined && !omitPercentInfo && (
                        <Wrapper alignItems='center' margin='8px 0'>
                            <Wrapper
                                flexBox
                                margin='12px 0 4px 0'
                                justifyContent='center'
                                alignItems='center'
                            >
                                <Wrapper>
                                    <ReactSVG
                                        color={
                                            variation < 0
                                                ? 'var(--platform-danger-color)'
                                                : 'var(--platform-secondary-color)'
                                        }
                                        src='../../assets/icons/arrowUp.svg'
                                    />
                                    <span
                                        style={{
                                            fontWeight: 600,
                                            margin: '0 0 0 5px',
                                            color:
                                                variation < 0
                                                    ? 'var(--platform-danger-color)'
                                                    : 'var(--platform-secondary-color)',
                                        }}
                                    >
                                        {`${variation}% `}
                                    </span>
                                </Wrapper>
                            </Wrapper>
                            <Wrapper fontSize='13px'>
                                <span>{t('info.better.next.bid')}</span>
                            </Wrapper>
                        </Wrapper>
                    )}
                </Wrapper>
            )}
        >
            {cloneChildren}
        </Popover>
    );
};

export default BidInformation;
