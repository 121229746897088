import { FC, useCallback } from 'react';
import Wrapper from '../../../../Common/wrapper';
import Image from '../../../../Common/Image';
import { BidPercentDiffProps } from './props';
import { useTranslation } from 'react-i18next';
import { auctioneerPercentDiff, getPercentOptions } from 'helpers/difference';

const BidPercentDiff: FC<BidPercentDiffProps> = ({ auctionLot, auctionNotice }) => {
    const { t } = useTranslation();

    const getReferenceValueDiff = useCallback(() => {
        if (!auctionLot.bestBid || !auctionLot.providerBestBid) return undefined;
        return auctioneerPercentDiff(
            String(auctionLot.bestBid),
            String(auctionLot.providerBestBid),
            auctionNotice.judgmentCriterion,
            auctionNotice.rateTypeBid === 1
        );
    }, [
        auctionLot.bestBid,
        auctionLot.providerBestBid,
        auctionNotice.judgmentCriterion,
        auctionNotice.rateTypeBid,
    ]);

    const getReferencePercentOptions = useCallback(
        (percent?: number) => {
            return getPercentOptions(
                percent,
                auctionNotice.judgmentCriterion,
                auctionNotice.rateTypeBid === 1
            );
        },
        [auctionNotice.judgmentCriterion, auctionNotice.rateTypeBid]
    );

    const diff = getReferenceValueDiff();
    const options = getReferencePercentOptions(
        !auctionLot.bestBid || !auctionLot.providerBestBid ? undefined : diff
    );

    const percent = Math.abs(diff ?? 0)?.toFixed(2);

    return (
        <Wrapper margin='0 10px' width='50px' justifyContent='center' flexBox alignItems='center'>
            <Wrapper>
                <Wrapper flexBox justifyContent='center'>
                    <Image
                        src={`../../assets/icons/${options.icon}.svg`}
                        width='13px'
                        height='13px'
                        title={t('info.provider.values.difference')}
                    />
                </Wrapper>
                <Wrapper flexBox justifyContent='center' color={options.color}>
                    {`${
                        diff === undefined
                            ? '--'
                            : `${percent}%`
                    }`}
                </Wrapper>
            </Wrapper>
        </Wrapper>
    );
};

export default BidPercentDiff;
