import momentJs, { LongDateFormatKey } from 'moment';
import 'moment/locale/pt-br';

export const moment = (timestamp?: string | any, format?: LongDateFormatKey) =>
    momentJs(timestamp || undefined)
        .locale('pt-br')
        .format(format || 'LTS');

export const currMoment = (difference: momentJs.Duration) => {
    return momentJs().add(difference.asMilliseconds(), 'milliseconds');
};
