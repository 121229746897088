import React, { createContext, ReactNode, useContext, useState } from 'react';
import { Participate } from '../../Interfaces/participate';

export type LotsContextType = {
    selectedLots: number[];
    setSelectedLots: React.Dispatch<React.SetStateAction<number[]>>;
    bulkChangesMode: boolean;
    setBulkChangesMode: React.Dispatch<React.SetStateAction<boolean>>;
    selectedMode: SelectLotMode | undefined;
    setSelectedMode: React.Dispatch<React.SetStateAction<SelectLotMode | undefined>>;
    participants: Participate[];
    setParticipants: React.Dispatch<React.SetStateAction<Participate[]>>;
};

export const LotsContext = createContext<LotsContextType>({
    selectedLots: [],
    setSelectedLots: () => {},
    bulkChangesMode: false,
    setBulkChangesMode: () => {},
    selectedMode: undefined,
    setSelectedMode: () => {},
    participants: [],
    setParticipants: () => {},
});

export enum SelectLotMode {
    start = 'start',
    cancel = 'cancel',
    advance = 'advance',
    finishNegotiation = 'finishNegotiation',
}

export const useLotsContext = () => useContext(LotsContext);

export const LotsContextProvider = ({ children }: { children: ReactNode }) => {
    const [bulkChangesMode, setBulkChangesMode] = useState<boolean>(false);
    const [selectedLots, setSelectedLots] = useState<number[]>([]);
    const [selectedMode, setSelectedMode] = useState<SelectLotMode | undefined>(undefined);
    const [participants, setParticipants] = useState<Participate[]>([]);

    return (
        <LotsContext.Provider
            value={{
                selectedLots,
                setSelectedLots,
                bulkChangesMode,
                setBulkChangesMode,
                selectedMode,
                setSelectedMode,
                participants,
                setParticipants,
            }}
        >
            {children}
        </LotsContext.Provider>
    );
};
