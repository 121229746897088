import styled from 'styled-components';

const Button = styled.div<{ bgColor?: string; margin?: string; color: string }>`
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 3px ${props => props.color} solid;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.bgColor ?? '#FFF'};
    margin: ${props => props.margin};

    & > div:hover {
        div {
            transform: scale(1.2);
            transition: all 200ms;
        }
    }
`;

const BlockIcon = styled.div<{ color?: string }>`
    width: 2.5px;
    background: ${(props) => props.color ?? 'red'};
    height: 110%;
    transform: rotateZ(25deg);
`;

export { Button, BlockIcon };
