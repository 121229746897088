import { FC, useState } from 'react';
import { Button, Radio, Space } from 'antd';
import Wrapper from '../../../Common/wrapper';
import { BiFilter } from 'react-icons/bi';
import { LotsTab } from '../../../LotListArea';
import { useTranslation } from 'react-i18next';

export interface FiltersModalProps {
    onChange: (value: LotsTab) => void;
}

const FiltersModal: FC<FiltersModalProps> = ({ onChange }) => {
    const [visible, setVisible] = useState(false);
    const [value, setValue] = useState(LotsTab.started);

    const { t } = useTranslation();

    return (
        <>
            {visible && (
                <Wrapper
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100vh',
                        background: '#fff',
                        zIndex: 99999,
                        inset: 0,
                        padding: '20px',
                    }}
                >
                    <Radio.Group
                        onChange={(ev) => {
                            setValue(ev.target.value);
                        }}
                        value={value}
                    >
                        <Space direction='vertical'>
                            <Radio value={LotsTab.started}>{t('info.lots.filter.started')}</Radio>
                            <Radio value={LotsTab.negotiation}>
                                {t('info.lots.filter.negotiation')}
                            </Radio>
                            <Radio value={LotsTab.all}>{t('info.lots.filter.all')}</Radio>
                        </Space>
                    </Radio.Group>
                    <Wrapper flexBox justifyContent='flex-end'>
                        <Button
                            type='dashed'
                            onClick={() => {
                                setVisible(false);
                            }}
                        >
                            Cancelar
                        </Button>
                        <Button
                            style={{ margin: '0 0 0 10px' }}
                            type='primary'
                            onClick={() => {
                                onChange(value);
                                setVisible(false);
                            }}
                        >
                            Filtrar
                        </Button>
                    </Wrapper>
                </Wrapper>
            )}
            <Wrapper flexBox alignItems='center'>
                <BiFilter
                    onClick={() => {
                        setVisible(true);
                    }}
                    style={{ width: '30px', height: '30px', color: '#777' }}
                />
            </Wrapper>
        </>
    );
};

export default FiltersModal;
