import { CONSTANTS } from '../constants';

export const isMobile = () => {
    return document.documentElement.clientWidth < CONSTANTS.responsize.width;
};

export const isSuspendedChat = () => {
    return document.documentElement.clientWidth < 1200;
};

export const isSuspendedResume = () => {
    return document.documentElement.clientWidth < 900;
};
