import { FC, useState } from 'react';
import { AdditionalInformationProps } from './props';
import Wrapper from '../Common/wrapper';
import Header from '../Common/Header';
import InformationList from './components/InformationList';
import { pad } from '../../helpers/pad';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import { isMobile, isSuspendedResume } from '../../helpers/mobile';

const AdditionalInformation: FC<AdditionalInformationProps> = ({
    auctionNoticeLotSelected,
    auctionNotice,
    authUser,
    onClose,
    openedResume,
}) => {
    const [isOpened, setOpened] = useState(true);
    const { t } = useTranslation();

    const replacedLotName = `${pad(auctionNoticeLotSelected.item) || '--'} ${
        !!auctionNoticeLotSelected?.quotaId ? `(${t('term.quota')})` : ''
    }`;

    return (
        <Wrapper
            margin='0 0 10px 0'
            width='100%'
            minWidth='290px'
            minHeight={isOpened ? '120px' : 'auto'}
            height={isOpened ? 'auto' : '46px'}
        >
            <Header
                icon='file'
                showMinimize={false}
                title={`${t('info.lot.info')} ${replacedLotName}`}
                onExpand={() => setOpened(!isOpened)}
                expandDirection={isOpened ? 'rotate(-90deg)' : 'rotate(0deg)'}
                customActions={
                    <>
                        {openedResume && (isMobile() || isSuspendedResume()) && (
                            <Wrapper>
                                <MdClose
                                    onClick={onClose}
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        color: '#555',
                                        cursor: 'pointer',
                                    }}
                                />
                            </Wrapper>
                        )}
                    </>
                }
            />

            {isOpened && (
                <Wrapper
                    style={{
                        overflowY: 'auto',
                        height: 'calc(100% - 46px)',
                    }}
                >
                    <InformationList
                        auctionNotice={auctionNotice}
                        auctionNoticeLotSelected={auctionNoticeLotSelected}
                    />
                    <Wrapper width='80%' height='1px' bgcolor='#e4e4e4' margin='0 auto' />
                </Wrapper>
            )}
        </Wrapper>
    );
};

export default AdditionalInformation;
