import { FC, useState } from 'react';
import { BidItemProps } from './props';
import Wrapper from '../../../Common/wrapper';
import { moment } from '../../../../helpers/moment';
import { formatValueWithAuctionRules } from '../../../../helpers/currencyTransform';
import ApiService from '../../../../services/api';
import { CancelBid, Wrapped } from './styled';
import ConfirmationPopover from '../../../Common/ConfirmationPopover';
import BidInformation from 'Components/Bids/components/BidInformation';
import { addNotification } from '../../../../helpers/notification';
import { useTranslation } from 'react-i18next';
import { isDeclinedBid } from '../../../../helpers/declined';
import Badge from '../../../Common/Badge';
import { getCompetentAuthorityName } from '../../../../helpers/auction-rules';
import ReactSVG from 'Components/ReactSVG';

const BidItemSupplier: FC<BidItemProps> = ({
    index,
    bid,
    lastBidAuthProvider,
    bidAuthProvider,
    auctionNoticeId,
    lotId,
    auctionNotice,
    bids,
}) => {
    const { judgmentCriterion, decimalPlaces, rateTypeBid } = auctionNotice;
    const [requestedCancel, setRequestedCancel] = useState(false);
    const { t } = useTranslation();

    const getStyleButton = () => {
        if (lastBidAuthProvider) {
            return {
                bgColor: 'var(platform-secondary-color)',
                title: t('info.your.best.bid'),
                color: '#FFF',
                showIndicator: true,
            };
        } else if (bidAuthProvider) {
            return {
                bgColor: 'var(platform-secondary-color)',
                title: t('info.your.bid'),
                color: '#FFF',
            };
        } else {
            if (bid.itsRegionalCompany === 1 || bid.itsRegionalCompany === 2) {
                return {
                    bgColor: 'var(--platform-info-color)',
                    title: t('info.regional.bid'),
                    color: '#FFF',
                };
            }
            return {
                bgColor: 'var(platform-secondary-color)',
                title: '',
                color: '#444',
            };
        }
    };

    const cancelBid = async () => {
        let error: any;
        setRequestedCancel(true);
        await ApiService.requestCancelBid({
            lotId,
            auctionNoticeId,
            bidId: bid.id,
            errorCb: (err: any) => {
                error = err;
            },
        });

        if (error) {
            return addNotification(
                t('term.error'),
                t('info.error.request.delete.bid'),
                'danger',
                3000
            );
        }

        return addNotification(
            t('term.success'),
            t('info.bid.delete.requested', { name: getCompetentAuthorityName(auctionNotice) }),
            'success',
            3000
        );
    };

    const itsSimpleCompany = () => {
        if (bid.itsSimpleCompany === 1) {
            return 'ME/EPP/COOP';
        }
        return '';
    };

    const styledButton = getStyleButton();
    const { formatted: formattedBidValue } = formatValueWithAuctionRules(
        bid.value,
        judgmentCriterion,
        rateTypeBid,
        decimalPlaces
    );

    return (
        <Wrapped
            flexBox
            width='100%'
            padding='6px 5px'
            margin='3px 0'
            overflowX='hidden'
            overflowY='hidden'
        >
            <Wrapper width='100%' alignItems='center' flexBox>
                <Wrapper margin='0 1px 0 0 ' width='8px'>
                    {styledButton.showIndicator && (
                        <ReactSVG
                            color='var(--platform-secondary-color)'
                            src='../../assets/icons/arrowRight.svg'
                            width='6px'
                            height='9px'
                        />
                    )}
                </Wrapper>
                <Wrapper width='100%' flexBox justifyContent='space-between' alignItems='center'>
                    <Wrapper alignItems='center' flexBox>
                        <Wrapper
                            borderRadius='50%'
                            title={styledButton.title}
                            bgcolor={styledButton.bgColor}
                            width='26px'
                            flexBox
                            alignItems='center'
                            justifyContent='center'
                            color={styledButton.color}
                            fontSize='12px'
                            fontWeight='700'
                            height='26px'
                        >
                            {`${index < 9 ? `0${index + 1}` : `${index + 1}`}º`}
                        </Wrapper>
                        <Wrapper margin='0 10px' minWidth='62px'>
                            <Wrapper fontSize='11px'>{moment(bid.createdAt)}</Wrapper>
                            <Wrapper
                                fontWeight='700'
                                fontSize='9px'
                                color='var(--platform-secondary-color)'
                            >
                                {itsSimpleCompany()}
                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                    <BidInformation bids={bids} bid={bid} omitPercentInfo>
                        <Wrapper flex margin='0 0 0 5px' fontSize='13px' truncate cursor='help'>
                            <span
                                style={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                }}
                            >
                                {formattedBidValue}
                            </span>
                        </Wrapper>
                    </BidInformation>
                    <Wrapper
                        title={t('info.best.bid.variation')}
                        flexBox
                        fontWeight='600'
                        color='#838383'
                        width='50px'
                        fontSize='10px'
                        justifyContent='center'
                    >
                        {`${bid.variation !== 0 ? `${bid.variation.toFixed(2)}%` : '--'}`}
                    </Wrapper>
                </Wrapper>
                {index === 0 && !requestedCancel && lastBidAuthProvider && !isDeclinedBid(bid) && (
                    <ConfirmationPopover
                        render={
                            <Wrapper fontSize='13px'>
                                <p>
                                    {t('info.confirm.delete.bid.value', {
                                        value: formattedBidValue,
                                        name: getCompetentAuthorityName(auctionNotice),
                                    })}
                                </p>
                            </Wrapper>
                        }
                        maxWidth='230px'
                        onConfirm={cancelBid}
                    >
                        <CancelBid
                            src='../../assets/icons/trash.svg'
                            title={t('info.request.delete.bid', {
                                name: getCompetentAuthorityName(auctionNotice),
                            })}
                        />
                    </ConfirmationPopover>
                )}
                {isDeclinedBid(bid) && (
                    <Badge
                        title={t('info.provider-declined')}
                        fontSize='9px'
                        color='#a47110'
                        width='24px'
                    >
                        {`DEC`}
                    </Badge>
                )}
            </Wrapper>
        </Wrapped>
    );
};

export default BidItemSupplier;
