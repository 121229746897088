import { FC, useEffect, useRef, useState } from 'react';
import Wrapper from '../Common/wrapper';
import { ModalDeclinesProps } from './props';
import styled from 'styled-components';
import { Button, Modal } from 'antd';
import { Decline } from '../../Interfaces/decline';
import ApiService from '../../services/api';
import Badge from '../Common/Badge';
import ConfirmationPopover from '../Common/ConfirmationPopover';
import { useTranslation } from 'react-i18next';
import MessagePopover from '../Common/MessagePopover';
import { pad } from '../../helpers/pad';
import { Badge as DefaultBadge } from 'antd';
import { SocketEvent, SocketEvents } from '../../Interfaces/socketEvents';
import TinyQueue from 'tinyqueue';
import { timeout } from '../../helpers/timer';
import { addNotification } from '../../helpers/notification';
import { BiExit } from 'react-icons/bi';

const Icon = styled(BiExit)`
    width: 26px;
    height: 26px;
    color: ${({theme}) => theme.colors.warning};
`;

const Wrapped = styled(Wrapper)`
    height: 44px;
    border: 1px solid rgba(204, 204, 204, 0.5);
`;

const ModalDeclines: FC<ModalDeclinesProps> = ({ auctionId, socketConnection }) => {
    let queueLock = false;
    const queueEvents: any = new TinyQueue([]);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [declines, setDeclines] = useState<Decline[]>([]);
    const [newDeclinesCount, setNewDeclinesCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const newDeclinesCountRef: any = useRef(null);
    newDeclinesCountRef.current = { newDeclinesCount, setNewDeclinesCount };

    const getDeclines = async () => {
        const declines = await ApiService.listAuctionNoticeDeclines({
            auctionNoticeId: auctionId,
            filter: {
                isDeferred: null,
            },
        });

        setDeclines(declines);
        setIsLoading(false);
    };

    useEffect(() => {
        setIsLoading(true);
        getDeclines();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModalVisible]);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const { t } = useTranslation();

    const recuseDecline = async (declineId: number, message: string) => {
        await ApiService.deferDecline({
            isDeferred: 0,
            auctioneerMessage: message,
            declineId,
        });

        setDeclines((prevState) => [...prevState.filter((decline) => decline.id !== declineId)]);
        return addNotification(
            t('term.success'),
            t('info.success.recused-decline'),
            'success',
            3000
        );
    };

    const confirmDecline = async (declineId: number) => {
        await ApiService.deferDecline({
            isDeferred: 1,
            declineId,
        });

        setDeclines((prevState) => [...prevState.filter((decline) => decline.id !== declineId)]);

        return addNotification(
            t('term.success'),
            t('info.success.confirm-decline'),
            'success',
            3000
        );
    };

    useEffect(() => {
        handleSocketEvents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socketConnection]);

    const handleSocketEvents = () => {
        if (!socketConnection) {
            return;
        }

        socketConnection.on(SocketEvents.declineCreated, (event: Decline) =>
            receiveEvent({
                type: SocketEvents.declineCreated,
                message: event,
            })
        );
    };

    const receiveEvent = (message: SocketEvent) => {
        queueEvents.push(message);

        if (!queueLock) {
            queueLock = true;
            processEvent();
        }
    };

    const processEvent = async () => {
        try {
            while (queueEvents.length) {
                const event: SocketEvent = queueEvents.pop();
                const { type } = event;

                switch (type) {
                    case SocketEvents.declineCreated:
                        return handleDeclineCreated();
                }

                await new Promise((r) => timeout(r, 20));
            }
        } finally {
            queueLock = false;
        }
    };

    const handleDeclineCreated = () => {
        setNewDeclinesCount((prevState) => prevState + 1);
    };

    const getDefaultRender = () =>
        declines.map((decline) => (
            <Wrapper
                padding='7px 0'
                flexBox
                justifyContent='space-between'
                borderBottom='1px #ebebeb solid'
            >
                <Wrapper flexBox>
                    <Badge
                        cursor='pointer'
                        padding='0 2px'
                        minWidth='27px'
                        width='27px'
                        color={'var(--platform-info-color)'}
                        title={`F${pad(String(decline.participate?.providerAuctionCode))}`}
                        fontSize='9px'
                        style={{
                            marginRight: '8px',
                        }}
                    >
                        {`F${pad(String(decline.participate?.providerAuctionCode))}`}
                    </Badge>
                    <span style={{ fontSize: '14px' }}>
                        {decline.lot?.item
                            ? `${`${t('term.lot.title')} ${pad(decline.lot.item)}`} `
                            : t('info.all-lots')}
                    </span>
                </Wrapper>
                <Wrapper>
                    <MessagePopover
                        maxWidth='330px'
                        render={
                            <>
                                <Wrapper fontSize='13px'>
                                    <p>{t('info.recused.decline-message')}</p>
                                    <p>{t('term.confirm')}?</p>
                                </Wrapper>
                            </>
                        }
                        onConfirm={(message: string) => recuseDecline(decline.id, message)}
                    >
                        <Button
                            size='small'
                            style={{
                                borderRadius: '4px',
                                fontSize: '12px',
                            }}
                        >
                            {t('info.recuse')}
                        </Button>
                    </MessagePopover>
                    <ConfirmationPopover
                        render={
                            <>
                                <Wrapper fontSize='13px'>
                                    <p>{t('info.recused.confirm-message')}</p>
                                    <p>{t('term.confirm')}?</p>
                                </Wrapper>
                            </>
                        }
                        maxWidth='220px'
                        onConfirm={() => confirmDecline(decline.id)}
                    >
                        <Button
                            size='small'
                            style={{
                                borderRadius: '4px',
                                background: 'var(--platform-primary-color)',
                                color: '#FFF',
                                marginLeft: '8px',
                                fontSize: '12px',
                            }}
                        >
                            {t('term.accept')}
                        </Button>
                    </ConfirmationPopover>
                </Wrapper>
            </Wrapper>
        ));

    return (
        <>
            <Modal
                title={t('term.decline-requests')}
                visible={isModalVisible}
                footer={null}
                onCancel={() => {
                    newDeclinesCountRef.current.setNewDeclinesCount(0);
                    setIsModalVisible(false);
                }}
            >
                {isLoading ? (
                    // getFetchingRender()
                    <></>
                ) : declines.length ? (
                    getDefaultRender()
                ) : (
                    <Wrapper flexBox justifyContent='center'>
                        {t('info.no-declines')}
                    </Wrapper>
                )}
            </Modal>
            <Wrapped
                minWidth='185px'
                maxWidth='150px'
                bgcolor='#FFF'
                textAlign='center'
                padding='12px 24px'
                justifyContent='space-between'
                alignItems='center'
                borderRadius='5px'
                flexBox
            >
                <Wrapper flexBox alignItems='center' onClick={showModal} cursor='pointer'>
                    <Icon />
                    <span style={{ margin: '0 0 0 8px', fontSize: 15 }}>
                        <b>Declínios</b>
                    </span>
                </Wrapper>
                <DefaultBadge
                    showZero
                    count={newDeclinesCountRef.current.newDeclinesCount || declines.length}
                />
            </Wrapped>
        </>
    );
};

export default ModalDeclines;
