import { MdRefresh, MdDone } from 'react-icons/md';
import styled from 'styled-components';

const RefreshIcon = styled(MdRefresh)`
    width: 20px;
    height: 20px;
    cursor: pointer;
    color: #555;
    @keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    &.loading {
        animation: spin 0.7s linear infinite;
    }
`;

const RefreshDoneIcon = styled(MdDone)`
    width: 20px;
    height: 20px;
    color: #555;
`;

export { RefreshDoneIcon, RefreshIcon };
