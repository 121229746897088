import React, { FC, useState, ReactElement } from 'react';
import Wrapper from '../wrapper';
import { Button } from 'antd';
import { MessagePopoverProps } from './props';
import Popover from 'react-tiny-popover';
import ClickOutside from '../ClickOutside';
import { Input } from 'antd';
import { SendButton } from './styles';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

const Content: FC<any> = ({
    render,
    setOpenModal,
    onConfirm,
    maxWidth,
    onMessageChanged,
    message,
    error,
    onClose,
}) => {
    const { t } = useTranslation();

    return (
        <ClickOutside onClickOutside={onClose}>
            <Wrapper
                bgcolor='#FFF'
                maxWidth={maxWidth}
                width='auto'
                minWidth='330px'
                height='auto'
                padding='13px'
            >
                <Wrapper fontSize='14px' padding='3px 0px 10px 0px'>
                    {render}
                </Wrapper>
                <Wrapper>
                    <TextArea
                        style={{ fontSize: '13px', resize: 'none' }}
                        value={message}
                        rows={3}
                        autoFocus
                        onClick={(ev) => ev.stopPropagation()}
                        onChange={(ev) => {
                            ev.stopPropagation();
                            onMessageChanged(ev.target.value);
                        }}
                    >
                        {message}
                    </TextArea>
                </Wrapper>
                {error && (
                    <Wrapper
                        margin='4px 0 0 0'
                        flexBox
                        justifyContent='center'
                        color='#bb2828'
                        fontSize='12px'
                    >
                        {t('info.modal.message.validation.info')}
                    </Wrapper>
                )}
                <Wrapper
                    margin='14px 0 0 0'
                    flexBox
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <Button
                        style={{
                            borderRadius: '3px',
                            fontSize: '13px',
                            textTransform: 'capitalize',
                        }}
                        onClick={() => setOpenModal(false)}
                    >
                        {t('term.close')}
                    </Button>
                    <SendButton
                        style={{
                            borderRadius: '3px',
                            fontSize: '13px',
                            textTransform: 'capitalize',
                        }}
                        onClick={() => onConfirm()}
                    >
                        {t('term.send')}
                    </SendButton>
                </Wrapper>
            </Wrapper>
        </ClickOutside>
    );
};

// se necessário não selecionar o lote após efetuar uma ação, colocar stopPropagation em todos os onclicks
const MessagePopover: FC<MessagePopoverProps> = ({
    children,
    onConfirm,
    render,
    opened,
    maxWidth,
    disabled,
}) => {
    const [modalOpened, setOpenModal] = useState<boolean>(opened || false);
    const [message, setMessage] = useState<string | undefined>(undefined);
    const [error, setError] = useState<boolean>(false);

    const cloneChildren = React.cloneElement(children as ReactElement<any>, {
        onClick: (ev: any) => !disabled && handleClick(ev),
    });

    const handleClick = (ev: any) => {
        ev.stopPropagation();
        setOpenModal(!modalOpened);
    };

    return (
        <Popover
            isOpen={modalOpened}
            position={['bottom', 'right', 'left']}
            content={() => (
                <Content
                    message={message}
                    onMessageChanged={(message: string) => {
                        setMessage(message);
                        setError(false);
                    }}
                    maxWidth={maxWidth}
                    onConfirm={() => {
                        if (message && message.length >= 10) {
                            onConfirm(message);
                            return setOpenModal(false);
                        }
                        return setError(true);
                    }}
                    error={error}
                    setOpenModal={(ev: any) => setOpenModal(ev)}
                    render={render}
                    onClose={handleClick}
                />
            )}
        >
            {cloneChildren}
        </Popover>
    );
};

export default MessagePopover;
