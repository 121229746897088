import { FC, useEffect, useState } from 'react';
import Wrapper from '../../../Common/wrapper';
import { MessageProps } from './props';
import Image from '../../../Common/Image';
import styled from 'styled-components';
import { SenderType } from '../../../../Interfaces/senderType';
import { pad } from '../../../../helpers/pad';
import ReadMore from 'Components/Common/readMore';
import moment from 'moment';
import { timeout } from '../../../../helpers/timer';
import { isAuctioneer, isProvider } from '../../../../helpers/permissions';
import { useTranslation } from 'react-i18next';
import { getCompetentAuthorityName } from '../../../../helpers/auction-rules';

export const MESSAGE_LENGTH_SHOW = 300;
const MESSAGE_FOCUS_TIMEOUT = 15;

const Wrapped = styled(Wrapper)`
    :not(:last-child) {
        border-bottom: 1px solid #d7d7d7;
    }

    transition: all 0.4s ease-out;
`;

const Message: FC<MessageProps> = ({ message, authUser, auctionNotice }) => {
    const [focusedMessage, setFocusedMessage] = useState(false);
    const { t } = useTranslation();

    const getMessageIcon = () => {
        switch (message.senderType) {
            case SenderType.auction:
                return {
                    label: t('term.auctioneer', {
                        name: getCompetentAuthorityName(auctionNotice),
                    }),
                    icon: 'law',
                };

            case SenderType.system:
                return { label: t('term.system'), icon: 'robot' };

            case SenderType.provider:
                return {
                    label: message.participate?.providerAuctionCode
                        ? `${t('term.provider')} ${pad(message.participate?.providerAuctionCode)}`
                        : t('term.provider'),
                    icon: 'supplier',
                };
        }
    };

    const canFocusMessage =
        (message.senderType === SenderType.auction &&
            isProvider(authUser) &&
            authUser.providerId) ||
        (message.senderType === SenderType.provider && isAuctioneer(authUser));

    useEffect(() => {
        const diffBetweenNowAndMessage = moment().diff(moment(message.createdAt), 'seconds');
        if (canFocusMessage && diffBetweenNowAndMessage < MESSAGE_FOCUS_TIMEOUT) {
            setFocusedMessage(true);

            timeout(() => {
                setFocusedMessage(false);
            }, (MESSAGE_FOCUS_TIMEOUT - diffBetweenNowAndMessage) * 1_000);
        }
    }, [message]);

    const from: any = getMessageIcon();
    const parsedTime = `${moment(message.createdAt).format('L')} ${moment(message.createdAt).format(
        'HH:mm:ss'
    )}`;

    return (
        <Wrapped
            flexBox
            width='100%'
            fontSize='14px'
            padding='10px 6px'
            borderRadius={focusedMessage ? '4px' : '0'}
            borderLeft={canFocusMessage ? '5px var(--platform-secondary-color) solid' : '5px #fff solid'}
            bgcolor={focusedMessage ? '#f9f8d2' : '#fff'}
        >
            <Wrapper width='100%' flexBox>
                <Wrapper
                    title={`${t('info.message.sent.by')} ${from.label.toLocaleLowerCase()}`}
                    flexBox
                    justifyContent='center'
                    minWidth='35px'
                    height='35px'
                    alignItems='center'
                    margin='0 10px 0 0'
                    borderRadius='50%'
                    border={
                        focusedMessage ? '1px var(--platform-secondary-color) solid !important' : '1px transparent solid'
                    }
                    bgcolor='#D9D9D9'
                >
                    <Image src={`../../assets/icons/${from.icon}.svg`} width='18px' height='20px' />
                </Wrapper>
                <Wrapper width='100%'>
                    <Wrapper
                        flexBox
                        margin='0 0 7px 0'
                        alignItems='center'
                        justifyContent='space-between'
                    >
                        <Wrapper truncate fontSize='12px' fontWeight='700'>
                            {from.label}
                        </Wrapper>
                        <Wrapper
                            truncate
                            title={`${t('info.message.sent')} ${parsedTime}`}
                            fontSize='11px'
                        >
                            {parsedTime}
                        </Wrapper>
                    </Wrapper>
                    <ReadMore
                        id={String(message.id)}
                        style={{
                            fontSize: '12px',
                        }}
                        size={300}
                        text={message.message}
                    />
                    <Wrapper flexBox fontSize='12px' />
                </Wrapper>
            </Wrapper>
        </Wrapped>
    );
};

export default Message;
