import Wrapper from 'Components/Common/wrapper';
import styled, { css } from 'styled-components';

const FavoriteMessagesModalTitle = styled.h3`
    font-size: 14px;
    font-weight: bold;
`;

const FavoriteMessagesItem = styled(Wrapper)<{ disableDivider?: boolean }>`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: 10px;
    ${({ disableDivider }) =>
        disableDivider
            ? css`
                  padding-bottom: 5px;
              `
            : css`
                  border-bottom: 1px solid rgba(204, 204, 204, 0.5);
              `};
    :hover {
        opacity: 0.7;
    }
`;
const FavoriteMessagesTitle = styled.strong`
    font-size: 12px;
`;
const FavoriteMessagesBody = styled.span`
    font-size: 12px;
`;
const FavoriteButton = styled(Wrapper)`
    display: flex;
    align-items: center;
    padding-left: 8px;
    gap: 8px;
    color: var(--platform-secondary-color);
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
    :active {
        opacity: 0.7;
    }
`;

export {
    FavoriteButton,
    FavoriteMessagesItem,
    FavoriteMessagesModalTitle,
    FavoriteMessagesTitle,
    FavoriteMessagesBody,
};
