import { FC, useEffect } from 'react';
import { SocketEvents } from '../../Interfaces/socketEvents';
import { addNotification } from '../../helpers/notification';
import { timeout } from 'helpers/timer';
import { User } from '../../Interfaces/user';
import { isProvider } from '../../helpers/permissions';
import { useTranslation } from 'react-i18next';

export interface RefreshPageHandlerProps {
    socketConnection: any;
    authUser: User;
}

const RefreshPageHandler: FC<RefreshPageHandlerProps> = ({ socketConnection, authUser }) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (!isProvider(authUser)) {
            return;
        }
        socketConnection.on(SocketEvents.requestRefreshPage, refreshPageRequested);

        return () => {
            socketConnection.removeListener(SocketEvents.requestRefreshPage, refreshPageRequested);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socketConnection]);

    const refreshPageRequested = () => {
        addNotification(
            t('term.warning'),
            t('info.provider.page.will.refresh', { value: 5 }),
            'info',
            5000
        );
        timeout(() => window.location.reload(), 5000);
    };

    return null;
};

export default RefreshPageHandler;
