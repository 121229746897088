import { FC } from 'react';
import { BidItemCitzenProps } from './props';
import Wrapper from '../../../Common/wrapper';
import styled from 'styled-components';
import { moment } from '../../../../helpers/moment';
import { formatValueWithAuctionRules } from '../../../../helpers/currencyTransform';
import BidInformation from '../BidInformation';
import { useTranslation } from 'react-i18next';
import { isDeclinedBid } from '../../../../helpers/declined';
import Badge from '../../../Common/Badge';

const Wrapped = styled(Wrapper)`
    :not(:last-child) {
        border-bottom: 1px solid #d7d7d7;
    }
`;

const BidItemCitzen: FC<BidItemCitzenProps> = ({ bid, index, bids, auctionNotice }) => {
    const { judgmentCriterion, decimalPlaces, rateTypeBid } = auctionNotice;
    const { t } = useTranslation();

    const itsSimpleCompany = () => {
        if (bid.itsSimpleCompany === 1) {
            return 'ME/EPP/COOP';
        }
        return '';
    };

    return (
        <Wrapped
            flexBox
            width='100%'
            padding='6px 5px'
            margin='3px 0'
            overflowX='hidden'
            overflowY='auto'
        >
            <Wrapper justifyContent='space-between' width='100%' alignItems='center' flexBox>
                <Wrapper alignItems='center' flexBox>
                    <Wrapper
                        width='100%'
                        flexBox
                        justifyContent='space-between'
                        alignItems='center'
                    >
                        <Wrapper alignItems='center' flexBox>
                            <Wrapper
                                borderRadius='50%'
                                title={t('term.position')}
                                bgcolor='#dddddd'
                                width='26px'
                                flexBox
                                alignItems='center'
                                justifyContent='center'
                                color='#444'
                                fontSize='12px'
                                fontWeight='700'
                                height='26px'
                            >
                                {`${index < 9 ? `0${index + 1}` : `${index + 1}`}º`}
                            </Wrapper>
                            <Wrapper width='78px' margin='0 10px'>
                                <Wrapper fontSize='11px'>{moment(bid.createdAt)}</Wrapper>
                                <Wrapper fontWeight='700' fontSize='9px' color='var(--platform-secondary-color)'>
                                    {itsSimpleCompany()}
                                </Wrapper>
                            </Wrapper>
                        </Wrapper>
                        <BidInformation bids={bids} bid={bid}>
                            <Wrapper cursor='help' truncate fontSize='13px' margin=' 0 0 0 5px'>
                                {
                                    formatValueWithAuctionRules(
                                        bid.value,
                                        judgmentCriterion,
                                        rateTypeBid,
                                        decimalPlaces
                                    ).formatted
                                }
                            </Wrapper>
                        </BidInformation>
                    </Wrapper>
                    {isDeclinedBid(bid) && (
                        <Badge
                            title={t('info.provider-declined')}
                            fontSize='9px'
                            color='#a47110'
                            width='24px'
                        >
                            {`DEC`}
                        </Badge>
                    )}
                </Wrapper>
            </Wrapper>
        </Wrapped>
    );
};

export default BidItemCitzen;
