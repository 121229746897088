import { FC, useEffect, useState, useRef } from 'react';
import { CounterProps } from './props';
import Wrapper from '../../../Common/wrapper';
import moment from 'moment';
import HelpText from '../../../HelpText';
import * as workerTimers from 'worker-timers';
import { useTranslation } from 'react-i18next';

const Counter: FC<CounterProps> = ({
    title,
    helpText,
    secondsRemaining,
    onFinishTimer,
    visible,
    isRandomClosePeriod,
}) => {
    const [seconds, setSeconds] = useState(secondsRemaining);
    const secondsRef: any = useRef(null);
    secondsRef.current = { seconds, setSeconds };

    const { t } = useTranslation();

    const intervalRef: any = useRef(null);

    const handleClearInterval = () => {
        try {
            !!intervalRef.current && workerTimers.clearInterval(intervalRef.current);
            intervalRef.current = null;
        } catch (error) {
            // desnecessário tratamento
        }
    };

    const startCounter = () => {
        try {
            intervalRef.current = workerTimers.setInterval(() => {
                if (secondsRef.current.seconds >= 1) {
                    return secondsRef.current.setSeconds(secondsRef.current.seconds - 1);
                }
                handleClearInterval();
                return onFinishTimer();
            }, 1000);
        } catch (error) {
            // desnecessário tratamento
        }
    };

    useEffect(() => {
        if (isRandomClosePeriod) return;
        secondsRef.current.setSeconds(secondsRemaining);
        handleClearInterval();

        if (secondsRemaining > 0) {
            return startCounter();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [secondsRemaining]);

    useEffect(() => {
        if (isRandomClosePeriod) return;
        return () => handleClearInterval();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getLastTenSecondsStyles = () => {
        if (secondsRef.current.seconds <= 10 && secondsRef.current.seconds > -1 && visible) {
            return {
                color: '#de0909',
            };
        }

        return {};
    };

    return (
        <Wrapper width='102px' maxWidth='102px' minWidth='85px'>
            <Wrapper
                flexBox
                position='relative'
                textAlign='center'
                fontSize='13px'
                justifyContent='center'
            >
                {title}
                <Wrapper top='-4px' right='-11px' position='absolute'>
                    {helpText && <HelpText helpText={helpText} />}
                </Wrapper>
            </Wrapper>
            <Wrapper
                title={t('info.lot.counter.title')}
                flexBox
                fontSize='23px'
                justifyContent='center'
            >
                <span
                    style={{
                        fontFamily: 'Roboto Mono',
                        color: '#666',
                        fontSize: '20px',
                        ...getLastTenSecondsStyles(),
                    }}
                >
                    {secondsRef.current.seconds <= 0 || !visible ? (
                        <>--:--</>
                    ) : (
                        moment
                            .utc(Math.round(secondsRef.current.seconds) * 1000)
                            .format(secondsRef.current.seconds > 3600 ? 'hh:mm:ss' : 'mm:ss')
                    )}
                </span>
            </Wrapper>
        </Wrapper>
    );
};

export default Counter;
