import styled from 'styled-components';
import { Button } from 'antd';

const SendButton = styled(Button)`
    background: ${({theme}) => theme.colors.primary} !important;

    span {
        color: #fff;
    }
    :hover {
        background: ${({theme}) => theme.colors.primary};
    }
`;

export {
    SendButton,
}
