import React, { Suspense } from 'react';
import Routes from './routes';
import 'antd/dist/antd.css';
import 'react-notifications-component/dist/theme.css';
import 'moment/locale/pt-br';
import Theme from 'theme';
import GlobalStyles from 'theme/globalStyles';

const Loader = () => <></>;

const App: React.FC = () => {
    return (
        <Suspense fallback={<Loader />}>
            <Theme>
                <Routes />
                <GlobalStyles />
            </Theme>
        </Suspense>
    );
};

export default App;
