import { FC } from 'react';
import Wrapper from '../../../../Common/wrapper';
import { PositionProps } from './props';
import Image from '../../../../Common/Image';
import SendMessageSupplier from '../../SendMessageSupplier';
import { StageLot } from '../../../../../Interfaces/stageLot';
import { pad } from '../../../../../helpers/pad';
import { useTranslation } from 'react-i18next';
import { isDeclinedLot } from '../../../../../helpers/declined';

const Position: FC<PositionProps> = ({ auctionLot, authUser, auctionNotice }) => {
    const { t } = useTranslation();

    const getStage = () => {
        if (isDeclinedLot(auctionLot)) {
            return {
                color: '#e5cc9d',
                component: (
                    <Image
                        src='../../assets/icons/logout.png'
                        width='18px'
                        height='18px'
                        title={t('info.position.lot.declined.title')}
                    />
                ),
            };
        }

        switch (auctionLot.stage) {
            case StageLot.started:
                return {
                    color: auctionLot.position?.position === 1 ? 'var(--platform-secondary-color)' : '#777777',
                    component: (
                        <Wrapper
                            title={t('info.position.lot.started.title')}
                            fontSize='17px'
                            fontWeight='700'
                        >
                            {auctionLot.position?.position
                                ? `${pad(auctionLot.position?.position)}º`
                                : '--'}
                        </Wrapper>
                    ),
                };

            case StageLot.paused:
                return {
                    color: '#BABABA',
                    component: (
                        <Image
                            src='../../assets/icons/pause.svg'
                            width='18px'
                            height='18px'
                            title={t('info.position.lot.paused.title')}
                        />
                    ),
                };

            case StageLot.convoked:
                return {
                    color: '#BABABA',
                    component: (
                        <Image
                            src='../../assets/icons/play2.svg'
                            width='19px'
                            height='19px'
                            title={t('info.position.lot.convoked.title')}
                        />
                    ),
                };

            case StageLot.negotiation_finished:
                if (
                    authUser.providerAuctionCode === auctionLot.winnerProvider?.providerAuctionCode
                ) {
                    return {
                        color: '#BABABA',
                        component: (
                            <Image
                                src='../../assets/icons/cup2.svg'
                                width='20px'
                                height='20px'
                                title={t('info.position.lot.winner.title')}
                            />
                        ),
                    };
                }
                return {
                    color: '#BABABA',
                    component: (
                        <Image
                            src='../../assets/icons/check.svg'
                            width='19px'
                            height='19px'
                            title={t('info.position.lot.no-winner.title')}
                        />
                    ),
                };

            case StageLot.no_winner_finished:
                return {
                    color: '#BABABA',
                    component: (
                        <Image
                            src='../../assets/icons/delete.svg'
                            width='19px'
                            height='19px'
                            title={t('info.position.lot.no-winner-finished.title')}
                        />
                    ),
                };

            case StageLot.negotiation:
                return {
                    color: '#BABABA',
                    component: (
                        <Image
                            src='../../assets/icons/chat.svg'
                            width='21px'
                            height='21px'
                            title={t('info.position.lot.negotiation.title')}
                        />
                    ),
                };

            case StageLot.canceled:
                return {
                    color: '#BABABA',
                    component: (
                        <Image
                            src='../../assets/icons/delete.svg'
                            width='19px'
                            height='19px'
                            title={t('info.position.lot.canceled.title')}
                        />
                    ),
                };

            case StageLot.finished:
            case StageLot.unStarted:
            case StageLot.stopped:
            case StageLot.timeEnded:
            case StageLot.awaiting_call_provider:
            case StageLot.awaiting_repeat_close_period:
                return {
                    color: '#BABABA',
                    component: (
                        <Image
                            src='../../assets/icons/stop.svg'
                            width='18px'
                            height='18px'
                            title={t('info.position.lot.finished.title')}
                        />
                    ),
                };

            case StageLot.random_close_period:
            case StageLot.random_period:
                return {
                    color: '#BABABA',
                    component: (
                        <Image
                            src='../../assets/icons/random.svg'
                            width='22px'
                            height='22px'
                            title={t('info.position.lot.random.title')}
                        />
                    ),
                };

            case StageLot.random_close_period_ended:
            case StageLot.random_period_ended:
                return {
                    color: '#BABABA',
                    component: (
                        <Image
                            src='../../assets/icons/random.svg'
                            width='22px'
                            height='22px'
                            title={t('info.position.lot.random-ended.title')}
                        />
                    ),
                };

            case StageLot.close_period:
            case StageLot.second_close_period:
            case StageLot.repeat_second_close_period:
                return {
                    color: '#BABABA',
                    component: (
                        <Image
                            src='../../assets/icons/law2.svg'
                            width='22px'
                            height='22px'
                            title={t('info.position.lot.closed.title')}
                        />
                    ),
                };

            case StageLot.close_period_ended:
            case StageLot.second_close_period_ended:
            case StageLot.repeat_second_close_period_ended:
                return {
                    color: '#BABABA',
                    component: (
                        <Image
                            src='../../assets/icons/law2.svg'
                            width='22px'
                            height='22px'
                            title={t('info.position.lot.closed-ended.title')}
                        />
                    ),
                };
        }
    };

    const Component = getStage()?.component;
    const color = getStage()?.color;

    return (
        <Wrapper
            position='relative'
            margin='0 9px 0 0'
            borderRadius='50%'
            bgcolor={color}
            width='43px'
            flexBox
            alignItems='center'
            justifyContent='center'
            color='#FFF'
            height='43px'
        >
            {Component}
            {auctionLot.stage === StageLot.negotiation &&
                auctionLot.winnerProvider?.providerAuctionCode ===
                    authUser?.providerAuctionCode && (
                    <SendMessageSupplier auctionLot={auctionLot} auctionNotice={auctionNotice} />
                )}
        </Wrapper>
    );
};

export default Position;
