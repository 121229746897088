import { FC } from 'react';
import Wrapper from '../wrapper';
import { WrapperProps } from '../wrapper/props';
import { BadgeProps } from './props';
import styled from 'styled-components';
import { Spin, SpinArea } from '../Spin';

const Content = styled(Wrapper)<WrapperProps & BadgeProps>`
    @keyframes pulse {
        0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
        }

        70% {
            transform: scale(1.03);
            box-shadow: 0 0 0 3px rgba(0, 0, 0, 0);
        }

        100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        }
    }

    ${(props) =>
        props.pulse &&
        `
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
        transform: scale(1);
        animation: pulse 2s infinite;
    `}
`;

const Badge: FC<BadgeProps & WrapperProps> = ({ color, children, onClick, fontSize, ...rest }) => {
    return (
        <Content
            {...rest}
            onClick={onClick}
            color='#fff'
            bgcolor={color}
            height='22px'
            fontSize={fontSize || '10px'}
            flexBox
            alignItems='center'
            justifyContent='center'
            fontWeight='600'
            borderRadius='4px'
            position='relative'
            opacity={rest.isLoading ? '0.8' : '1'}
        >
            {children}
            {rest.isLoading && (
                <SpinArea
                    style={{
                        position: 'absolute',
                    }}
                >
                    <Spin style={{ color: '#fff', width: '13px' }} />
                </SpinArea>
            )}
        </Content>
    );
};

export default Badge;
