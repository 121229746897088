import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import Header from 'Components/Common/Header';
import Wrapper from 'Components/Common/wrapper';
import { ActionsProps } from './props';
import { Item, Title } from './styled';
import ApiService from 'services/api';
import ConfirmationPopover from '../Common/ConfirmationPopover';
import { StageLot } from 'Interfaces/stageLot';
import { featureFlagEnabled } from 'helpers/can-access';
import ToggleRequestAdditionalDocuments from './components/ToggleRequestAdditionalDocuments';
import { SpinArea, Spin} from 'Components/Common/Spin';

const stagesToShowToggleAdditionalDocuments = [StageLot.negotiation, StageLot.negotiation_finished];

const Actions: FC<ActionsProps> = ({ authUser, auctionNoticeLotSelected, winners, getWinners, loadingWinners }) => {
    const { t } = useTranslation();

    const showToggleAdditionalDocuments = 
        !!winners.length &&
        stagesToShowToggleAdditionalDocuments.includes(auctionNoticeLotSelected.stage) &&
        featureFlagEnabled('solicitacaoDocumentoComplementarDisputa', authUser);
    const showRemoveReferenceValue = auctionNoticeLotSelected.showReferenceValue === 1

    const [isOpened, setOpened] = useState(true);
    const [loadingButtonReference, setLoadingButtonReference] = useState(false);


    useEffect(() => {
        if (stagesToShowToggleAdditionalDocuments.includes(auctionNoticeLotSelected.stage)) {
            getWinners();
        }
    }, [auctionNoticeLotSelected.id]);


    const handleToogleSwitchReferenceValue = async () => {
        try {
            setLoadingButtonReference(true);
            const data = await ApiService.showReferenceValue(
                auctionNoticeLotSelected.auctionNoticeId,
                auctionNoticeLotSelected.id,
                () => {}
            );
    
            if (data?.showReferenceValue) {
                setLoadingButtonReference(false);
            }
        } finally {
            setLoadingButtonReference(false);
        }
    };

    const listRequestAdditionalDocuments = () => {
        if (loadingWinners) {
            return (
                <SpinArea>
                    <Spin />
                </SpinArea>
            )
        }
        return winners.map((winner) => (<ToggleRequestAdditionalDocuments providerAuctionCode={winner.providerAuctionCode} proposalId={winner.proposalId} requestedAdditionalDocumentsProp={winner.requestedAdditionalDocuments} />))
      };      

    return (
        <Wrapper
            border='1px solid rgba(204, 204, 204, 0.5);'
            margin='10px 0px'
            width='100%'
            minWidth='290px'
            minHeight='auto'
            height='auto'
        >
            <Header
                icon='settings'
                showMinimize
                title={t('term.actions')}
                onExpand={() => setOpened(!isOpened)}
                expandDirection={isOpened ? 'rotate(-90deg)' : 'rotate(0deg)'}
            />

            {isOpened && (
                <Wrapper padding='10px 5px'>
                    {!showToggleAdditionalDocuments && !showRemoveReferenceValue && (
                        <Wrapper flexBox justifyContent='center' textAlign='center'>
                            {t('info.empty.actions.list')}
                        </Wrapper>
                    )}
                    {showToggleAdditionalDocuments && listRequestAdditionalDocuments()}
                    {showRemoveReferenceValue && (
                        <Item>
                            <Title>
                                <span>{t('info.label-reference-value')}</span>
                            </Title>
                            <ConfirmationPopover
                                render={
                                    <Wrapper fontSize='13px'>
                                        <p><strong>{t('info.modal-reference-value-title')}</strong></p>
                                        <p>{t('info.modal-reference-value-description')}</p>
                                    </Wrapper>
                                }
                                maxWidth='240px'
                                onConfirm={handleToogleSwitchReferenceValue}
                            >
                                <Button loading={loadingButtonReference} size='small'>
                                    {t('info.remove-reference-value')}
                                </Button>
                            </ConfirmationPopover>
                        </Item>
                    )}
                </Wrapper>
            )}
        </Wrapper>
    );
};

export default Actions;
