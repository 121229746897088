import { FC, useState } from 'react';
import Wrapper from '../wrapper';
import { HeaderProps } from './props';
import Image from '../Image';
import Popover from 'react-tiny-popover';
import { useTranslation } from 'react-i18next';

const Header: FC<HeaderProps> = ({
    title,
    icon,
    expandDirection,
    onExpand,
    showMinimize,
    renderConfigs,
    component,
    customActions,
}) => {
    const [modalOpened, setmodalOpened] = useState(false);
    const { t } = useTranslation();

    return (
        <Wrapper
            color='#FFF'
            height='46px'
            padding='10px'
            flexBox
            alignItems='center'
            justifyContent='space-between'
            bgcolor='#EAEAEA'
        >
            <Wrapper alignItems='center' flexBox>
                <Wrapper margin='0 9px 0  0'>
                    <Image src={`../../assets/icons/${icon}.svg`} width='26px' height='26px' />
                </Wrapper>
                <Wrapper
                    style={{ textTransform: 'capitalize' }}
                    title={title}
                    color='#777'
                    fontSize='14px'
                    fontWeight='700'
                >
                    {title}
                </Wrapper>
            </Wrapper>
            <Wrapper flexBox justifyContent='flex-end'>
                {renderConfigs && (
                    <Wrapper>
                        <Popover
                            isOpen={modalOpened}
                            position={['bottom', 'right', 'left']}
                            onClickOutside={(e: any) => {
                                if (e?.path?.find((e: any) => e?.id === 'requestRefreshPage')) {
                                    return;
                                }

                                setmodalOpened(false);
                            }}
                            content={() => component}
                        >
                            <Image
                                src='../../assets/icons/settings.svg'
                                style={{ cursor: 'pointer' }}
                                width='17px'
                                height='17px'
                                onClick={() => setmodalOpened(!modalOpened)}
                            />
                        </Popover>
                    </Wrapper>
                )}
                {customActions}
                {showMinimize && (
                    <Wrapper margin='0 0 0 12px' onClick={onExpand} cursor='pointer'>
                        <Image
                            transform={expandDirection}
                            title={t('info.minimize')}
                            src='../../assets/icons/arrow.svg'
                            width='18px'
                            height='18px'
                        />
                    </Wrapper>
                )}
            </Wrapper>
        </Wrapper>
    );
};

export default Header;
