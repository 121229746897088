import styled from 'styled-components';
import Wrapper from '../wrapper';

const Content = styled(Wrapper)`
    div,
    span {
        color: #fff !important;
    }
`;

export {
    Content,
}
