import { FC } from 'react';
import Wrapper from '../../../../Common/wrapper';
import { formatValueWithAuctionRules } from '../../../../../helpers/currencyTransform';
import { CitzenBestBidProps } from './props';
import { useTranslation } from 'react-i18next';
import HelpText from 'Components/HelpText';

const CitzenBestBid: FC<CitzenBestBidProps> = ({ auctionLot, auctionNotice }) => {
    const { judgmentCriterion, decimalPlaces, rateTypeBid } = auctionNotice;
    const { t } = useTranslation();

    const getBestBidColor = () => {
        if (judgmentCriterion === 2 || judgmentCriterion === 3) {
            return 'var(--platform-secondary-color)';
        } else if (!auctionLot.bestBid || !auctionLot.valueReference) {
            return '#555';
        }

        return parseFloat(auctionLot.valueReference) >= parseFloat(auctionLot.bestBid)
            ? 'var(--platform-secondary-color)'
            : 'var(--platform-danger-color)';
    };

    function formatCurrency(value: string | number | null) {
        return formatValueWithAuctionRules(value, judgmentCriterion, rateTypeBid, decimalPlaces)
            .formatted;
    }

    return (
        <Wrapper flexBox minWidth='185px' margin='0 15px' alignItems='center'>
            <Wrapper flex>
                <Wrapper title={t('info.best.value.lot')} flexBox justifyContent='space-between'>
                    <Wrapper margin='0 10px 0 0' truncate minWidth='94px' fontSize='12px'>
                        {t('info.best.value')}
                    </Wrapper>
                    <Wrapper
                        flex
                        truncate
                        textAlign='right'
                        color={getBestBidColor()}
                        fontWeight='700'
                        fontSize='13px'
                    >
                        {formatCurrency(auctionLot.bestBid)}
                        {!!auctionLot?.additionalExpense && (
                            <HelpText
                                helpText={t('info.lot-proposal-values', {
                                    totalValue: formatCurrency(auctionLot.bestBid ?? ''),
                                    baseValue: formatCurrency(
                                        Number(auctionLot.bestBid ?? 0) -
                                            Number(auctionLot.additionalExpense ?? 0)
                                    ),
                                    additionalExpense: formatCurrency(
                                        auctionLot.additionalExpense ?? ''
                                    ),
                                })}
                            />
                        )}
                    </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper>
    );
};

export default CitzenBestBid;
