import RoundedButton from '../RoundedButton';
import { StageLot } from '../../../../../Interfaces/stageLot';
import { ConfirmationModalType } from '../../../../../Interfaces/confirmationModal';
import { AuctioneerActions } from '../interface/AuctioneerActions';
import { diffBetweenNowAndExtensionTime } from '../../../../../helpers/lotPeriodShared';
import { CloseBid, ClosedBidStatus } from '../../../../../Interfaces/closeBids';
import { ClosePeriodActionsProps } from './props';
import Wrapper from '../../../../Common/wrapper';
import { CONSTANTS } from '../../../../../constants';
import { ProviderValueStatus } from 'Interfaces/providerValues';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const ClosePeriodActions = ({
    doAction,
    auctionLot,
    closeBids,
    serverTimestamp,
    providerValues,
    changeStage,
    auctionNotice,
}: ClosePeriodActionsProps) => {
    const closedPeriodTimer = CONSTANTS.timersDuration.CLOSE_PERIOD;
    const { t } = useTranslation();

    const cancelButton = (
        <RoundedButton
            title={t('info.action.cancel.title')}
            color='var(--platform-danger-color)'
            render={
                <Wrapper fontSize='13px'>
                    <p>{t('info.action.cancel.message')}</p>
                    <p>{t('info.action.cancel.confirmation')}</p>
                </Wrapper>
            }
            confirmationType={ConfirmationModalType.frustate}
            margin='0 13px 0 13px'
            icon='stop1'
            onConfirm={(values) => doAction(AuctioneerActions.cancel, values)}
        />
    );

    useEffect(() => {
        if ((auctionLot.stage === StageLot.random_period || auctionLot.stage === StageLot.random_close_period) && auctionNotice.methodDispute === 2) {
            changeStage(StageLot.random_close_period)
        }
    }, [auctionLot.stage])

    const actions = () => {
        const inClosePeriod = () =>
            diffBetweenNowAndExtensionTime({
                auctionLot,
                closedPeriodTimer,
                serverDifference: serverTimestamp.difference,
            });

        let existCovered: CloseBid | undefined = undefined;
        let existCallable: CloseBid | undefined = undefined;

        closeBids.forEach((closeBid) => {
            if (!existCovered && closeBid.status === ClosedBidStatus.covered) {
                existCovered = closeBid;
            } else if (!existCallable && closeBid.status === ClosedBidStatus.callable) {
                existCallable = closeBid;
            }
        });

        const callableProviders = providerValues.filter(
            (providerValue) => providerValue.status === ProviderValueStatus.callable
        );

        const secondsToFinish = inClosePeriod();

        switch (auctionLot.stage) {
            case StageLot.unStarted:
                return (
                    <RoundedButton
                        title={t('info.stage.unstarted.title')}
                        color='#777777'
                        margin='0 13px 0 13px'
                        bgColor='#777777'
                        render={
                            <Wrapper fontSize='13px'>
                                <p>{t('info.stage.unstarted.message')}</p>
                                <p>{t('info.stage.unstarted.confirmation')}</p>
                            </Wrapper>
                        }
                        confirmationType={ConfirmationModalType.button}
                        icon='play'
                        onConfirm={() => doAction(AuctioneerActions.start)}
                    />
                );

            case StageLot.convoked:
                return <>{cancelButton}</>;

            case StageLot.timeEnded:
                return (
                    <>
                        {cancelButton}
                        <RoundedButton
                            title={t('info.action.avanced.stage.title')}
                            render={
                                <Wrapper fontSize='13px'>
                                    <p>{t('info.action.time-ended.restarted.message')}</p>
                                    <p>{t('info.action.avanced.stage.confirmation')}</p>
                                </Wrapper>
                            }
                            color='var(--platform-secondary-color)'
                            confirmationType={ConfirmationModalType.button}
                            icon='finish'
                            onConfirm={() => doAction(AuctioneerActions.startRandomPeriod)}
                        />
                    </>
                );

            case StageLot.awaiting_call_provider:
                return (
                    <>
                        {cancelButton}
                        {callableProviders.length > 0 ? (
                            <RoundedButton
                                title={t('info.action.avanced.stage.title')}
                                render={
                                    <Wrapper fontSize='13px'>
                                        <p>{t('info.stage.awaiting.call.provider.message')}</p>
                                    </Wrapper>
                                }
                                color='var(--platform-secondary-color)'
                                confirmationType={ConfirmationModalType.button}
                                icon='finish'
                                omitConfirmButton
                                onConfirm={() => {}}
                            />
                        ) : (
                            <RoundedButton
                                title={t('info.action.avanced.stage.title')}
                                render={
                                    <Wrapper fontSize='13px'>
                                        <p>{t('info.action.avanced.stage.message')}</p>
                                        <p>{t('info.action.avanced.stage.confirmation')}</p>
                                    </Wrapper>
                                }
                                color='var(--platform-secondary-color)'
                                confirmationType={ConfirmationModalType.button}
                                icon='finish'
                                onConfirm={() => doAction(AuctioneerActions.finish)}
                            />
                        )}
                    </>
                );

            case StageLot.finished:
                return (
                    <>
                        {cancelButton}
                        <RoundedButton
                            title={t('info.action.avanced.stage.title')}
                            render={
                                <Wrapper fontSize='13px'>
                                    <p>{t('info.action.avanced.stage.message')}</p>
                                    <p>{t('info.action.avanced.stage.confirmation')}</p>
                                </Wrapper>
                            }
                            color='var(--platform-secondary-color)'
                            confirmationType={ConfirmationModalType.button}
                            icon='finish'
                            onConfirm={() => doAction(AuctioneerActions.finish)}
                        />
                    </>
                );

            case StageLot.awaiting_rejudge:
                return (
                    <>
                        {cancelButton}
                        {callableProviders.length > 0 ? (
                            <RoundedButton
                                title={t('info.action.avanced.stage.title')}
                                render={
                                    <Wrapper fontSize='13px'>
                                        <p>{t('info.stage.awaiting-rejudge.message')}</p>
                                    </Wrapper>
                                }
                                color='var(--platform-secondary-color)'
                                confirmationType={ConfirmationModalType.button}
                                icon='finish'
                                omitConfirmButton
                                onConfirm={() => {}}
                            />
                        ) : (
                            <RoundedButton
                                title={t('info.action.avanced.stage.title')}
                                render={
                                    <Wrapper fontSize='13px'>
                                        <p>{t('info.action.avanced.stage.message')}</p>
                                        <p>{t('info.action.avanced.stage.confirmation')}</p>
                                    </Wrapper>
                                }
                                color='var(--platform-secondary-color)'
                                confirmationType={ConfirmationModalType.button}
                                icon='finish'
                                onConfirm={() => doAction(AuctioneerActions.finish)}
                            />
                        )}
                    </>
                );

            case StageLot.started:
                return (
                    <>
                        {cancelButton}
                        <RoundedButton
                            title={t('info.action.started.title')}
                            confirmationType={ConfirmationModalType.message}
                            render={
                                <Wrapper fontSize='13px'>
                                    <p>{t('info.action.started.message')}</p>
                                    <p>{t('info.action.started.confirmation')}</p>
                                </Wrapper>
                            }
                            onConfirm={(message: string) =>
                                doAction(AuctioneerActions.pause, { message })
                            }
                            color='var(--platform-secondary-color)'
                            icon='pause1'
                        />
                    </>
                );

            case StageLot.negotiation:
                return (
                    <>
                        {cancelButton}
                        <RoundedButton
                            title={t('info.stage.negotiation.title')}
                            render={
                                <Wrapper fontSize='13px'>
                                    <p>{t('info.stage.negotiation.message')}</p>
                                    <p>{t('info.action.avanced.stage.confirmation')}</p>
                                </Wrapper>
                            }
                            color='var(--platform-secondary-color)'
                            confirmationType={ConfirmationModalType.button}
                            icon='finish'
                            onConfirm={() => doAction(AuctioneerActions.finishNegotiation)}
                        />
                    </>
                );

            case StageLot.random_close_period_ended:
                return (
                    <>
                        {cancelButton}
                        <RoundedButton
                            title={t('info.stage.random-close-period-ended.title')}
                            render={
                                <Wrapper fontSize='13px'>
                                    <p>{t('info.action.avanced.stage.message')}</p>
                                    <p>{t('info.action.avanced.stage.confirmation')}</p>
                                </Wrapper>
                            }
                            color='var(--platform-secondary-color)'
                            confirmationType={ConfirmationModalType.button}
                            icon='finish'
                            onConfirm={() => doAction(AuctioneerActions.startClosePeriod)}
                        />
                    </>
                );

            case StageLot.close_period:
                return <>{cancelButton}</>;

            case StageLot.close_period_ended:
                return (
                    <>
                        {cancelButton}
                        {secondsToFinish <= 0 && !existCovered && !!existCallable ? (
                            <RoundedButton
                                title={t('info.stage.close-period-ended.title')}
                                render={
                                    <Wrapper fontSize='13px'>
                                        <p>{t('info.stage.close-period-ended.message')}</p>
                                        <p>{t('info.action.avanced.stage.confirmation')}</p>
                                    </Wrapper>
                                }
                                color='var(--platform-secondary-color)'
                                confirmationType={ConfirmationModalType.button}
                                icon='finish'
                                onConfirm={() => doAction(AuctioneerActions.startSecondClosePeriod)}
                            />
                        ) : (
                            <RoundedButton
                                title={t('info.action.avanced.stage.title')}
                                render={
                                    <Wrapper fontSize='13px'>
                                        <p>{t('info.action.avanced.stage.message')}</p>
                                        <p>{t('info.action.avanced.stage.confirmation')}</p>
                                    </Wrapper>
                                }
                                color='var(--platform-secondary-color)'
                                confirmationType={ConfirmationModalType.button}
                                icon='finish'
                                onConfirm={() => doAction(AuctioneerActions.finish)}
                            />
                        )}
                    </>
                );

            case StageLot.second_close_period:
                return <>{cancelButton}</>;

            case StageLot.second_close_period_ended:
                return (
                    <>
                        {cancelButton}
                        {secondsToFinish <= 0 && (
                            <RoundedButton
                                title={t('info.action.avanced.stage.title')}
                                render={
                                    <Wrapper fontSize='13px'>
                                        <p>{t('info.action.avanced.stage.message')}</p>
                                        <p>{t('info.action.avanced.stage.confirmation')}</p>
                                    </Wrapper>
                                }
                                color='var(--platform-secondary-color)'
                                confirmationType={ConfirmationModalType.button}
                                icon='finish'
                                onConfirm={() => doAction(AuctioneerActions.finish)}
                            />
                        )}
                    </>
                );

            case StageLot.awaiting_repeat_close_period:
                return (
                    <>
                        <RoundedButton
                            title={t('info.action.avanced.stage.title')}
                            render={
                                <Wrapper fontSize='13px'>
                                    <p>
                                        <b>
                                            {t('info.stage.awaiting-repeat-close-period.message')}
                                        </b>
                                    </p>
                                    <p>{t('info.stage.awaiting-repeat-close-period.message2')}</p>
                                    <p>{t('info.action.avanced.stage.confirmation')}</p>
                                </Wrapper>
                            }
                            color='var(--platform-secondary-color)'
                            confirmationType={ConfirmationModalType.button}
                            icon='finish'
                            onConfirm={() => doAction(AuctioneerActions.repeatSecondClosePeriod)}
                        />
                    </>
                );

            case StageLot.repeat_second_close_period:
                return <>{cancelButton}</>;

            case StageLot.repeat_second_close_period_ended:
                return (
                    <>
                        <RoundedButton
                            title={t('info.action.avanced.stage.title')}
                            render={
                                <Wrapper fontSize='13px'>
                                    <p>{t('info.action.avanced.stage.message')}</p>
                                    <p>{t('info.action.avanced.stage.confirmation')}</p>
                                </Wrapper>
                            }
                            color='var(--platform-secondary-color)'
                            confirmationType={ConfirmationModalType.button}
                            icon='finish'
                            onConfirm={() =>
                                doAction(AuctioneerActions.finishRepeatSecondClosePeriod)
                            }
                        />
                    </>
                );

            case StageLot.paused:
                return (
                    <>
                        {cancelButton}
                        <RoundedButton
                            title={t('info.stage.paused.title')}
                            render={
                                <Wrapper fontSize='13px'>
                                    <p>{t('info.stage.paused.message')}</p>
                                    <p>{t('info.action.avanced.stage.confirmation')}</p>
                                </Wrapper>
                            }
                            color='#777777'
                            confirmationType={ConfirmationModalType.button}
                            bgColor='#777777'
                            icon='play'
                            onConfirm={() => doAction(AuctioneerActions.unPause)}
                        />
                    </>
                );

            case StageLot.random_close_period:
                return <>{cancelButton}</>;

            case StageLot.negotiation_finished:
                return (
                    <RoundedButton
                        color='var(--platform-warning-color)'
                        margin='0 13px 0 13px'
                        title={t('info.stage.negotiation-finished.title')}
                        icon='startAgain'
                        render={
                            <Wrapper fontSize='13px'>
                                <p>
                                    <p>{t('info.stage.negotiation-finished.message')}</p>
                                </p>
                                <p>{t('info.action.avanced.stage.confirmation')}</p>
                            </Wrapper>
                        }
                        confirmationType={ConfirmationModalType.button}
                        onConfirm={() => doAction(AuctioneerActions.reOpenNegotiation)}
                    />
                );

            case StageLot.canceled:
                return (
                    <>
                        <RoundedButton
                            title={t('info.confirm-cancel-lot')}
                            render={
                                <Wrapper fontSize='13px'>
                                    <p>{`${t('info.confirm-cancel-lot')}?`}</p>
                                </Wrapper>
                            }
                            color='var(--platform-warning-color)'
                            confirmationType={ConfirmationModalType.button}
                            icon='startAgain'
                            onConfirm={() => doAction(AuctioneerActions.revertCancel)}
                        />
                    </>
                );
            default:
                return <></>;
        }
    };

    return actions();
};

export default ClosePeriodActions;
