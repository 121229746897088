import { FC } from 'react';
import { Route } from 'react-router-dom';
import { DefaultRouteProps } from './props';

const DefaultRoute: FC<DefaultRouteProps> = ({ component, location, ...rest }) => {
    const Component: FC = component as React.FC;

    return <Route {...rest} render={() => <Component {...rest} />} />;
};

export default DefaultRoute;
