import { MethodDisputeEnum } from 'enums/method-dispute.enum';
import i18n from '../i18n/config';

const methodDisputeDescription = ({ methodDispute }: { methodDispute: number }) => {
    switch (methodDispute) {
        case MethodDisputeEnum.open:
            return i18n.t('term.method.dispute.open');
        case MethodDisputeEnum.openClosed:
            return i18n.t('term.method.dispute.open-closed');
        case MethodDisputeEnum.closed:
            return i18n.t('term.method.dispute.closed');
        case MethodDisputeEnum.closedOpen:
            return i18n.t('term.method.dispute.closed-open');
        case MethodDisputeEnum.notApplicable:
            return i18n.t('term.method.dispute.dispense-without-dispute');
    }
};

const methodDisputeDoAction = ({
    methodDispute,
    open,
    closed,
}: {
    methodDispute: number;
    open: Function;
    closed: Function;
}) => {
    switch (methodDispute) {
        case MethodDisputeEnum.open:
        case MethodDisputeEnum.closedOpen:
            return open;
        case MethodDisputeEnum.openClosed:
        case MethodDisputeEnum.closed:
        case MethodDisputeEnum.notApplicable:
            return closed;
    }
};

export { methodDisputeDescription, methodDisputeDoAction };
