import { useQuery } from 'Hooks/use-query.hook';
import { useState } from 'react';
import ApiService from 'services/api';

export function useFrustrateForm() {
    const { data, loading } = useQuery(ApiService.listReasonFrustrated);

    const options = data?.map((option) => ({
        label: option.description,
        value: option.id,
    }));

    const [message, setMessage] = useState('');
    const [messageIsDirty, setMessageIsDirty] = useState(false);

    const [reasonFrustratedId, setReasonFrustratedId] = useState();
    const [reasonFrustratedIdIsDirty, setReasonFrustratedIdIsDirty] = useState(false);

    const hasErrors = message.length < 10 || !reasonFrustratedId;

    const messageHasError = message.length < 10 && messageIsDirty;
    const reasonFrustratedIdHasError = !reasonFrustratedId && reasonFrustratedIdIsDirty;

    const form = {
        values: {
            message,
            reasonFrustratedId,
        },
        dirty: {
            message: messageIsDirty,
            reasonFrustratedId: reasonFrustratedIdIsDirty,
        },
        error: {
            message: messageHasError,
            reasonFrustratedId: reasonFrustratedIdHasError,
        },
        hasErrors,
    };

    function handleOnChange(field: 'message' | 'reasonFrustratedId', value: any) {
        if (field === 'message') setMessage(value);
        if (field === 'reasonFrustratedId') setReasonFrustratedId(value);
    }

    function handleOnBlur(field: 'message' | 'reasonFrustratedId') {
        if (field === 'message') setMessageIsDirty(true);
        if (field === 'reasonFrustratedId') setReasonFrustratedIdIsDirty(true);
    }

    return {
        form,
        handleOnChange,
        handleOnBlur,
        options,
        optionsIsLoading: loading,
    };
}
