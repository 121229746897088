import { usePlatformContext } from '../context/platform.context';

export const useGetBackofficeRedirectUrl = (): string => {
    const { platform } = usePlatformContext();
    if (!platform) {
        return '';
    }
    const urlLocation = window.location.toString();
    const host = new URL(urlLocation).hostname;
    const subdomain = host.split('.')[0];

    const fromToUrlPhp = {
        disputa: 'app',
        'disputa-compras': 'app-compras',
        'dispute-room-dev': 'app-dev',
        'dispute-room-qa': 'app-qa',
    };

    if (fromToUrlPhp[subdomain] === undefined) {
        if (host.includes('localhost')) {
            return `http://licitardigital:8080`;
        }

        console.error('Application not found!');
    }

    const developmentTag = fromToUrlPhp[subdomain];

    return `https://${developmentTag}.${platform.domain}`;
};
