export enum StageLot {
    started = 'started',
    paused = 'paused',
    stopped = 'stopped',
    finished = 'finished',
    negotiation = 'negotiation',
    canceled = 'canceled',
    negotiation_finished = 'negotiation_finished',
    unStarted = 'unStarted',
    timeEnded = 'timeEnded',
    convoked = 'convoked',
    random_close_period = 'random_close_period',
    random_close_period_ended = 'random_close_period_ended',
    close_period = 'close_period',
    close_period_ended = 'close_period_ended',
    second_close_period = 'second_close_period',
    second_close_period_ended = 'second_close_period_ended',
    awaiting_rejudge = 'awaiting_rejudge',
    awaiting_call_provider = 'awaiting_call_provider',
    no_winner_finished = 'no_winner_finished',
    awaiting_repeat_close_period = 'awaiting_repeat_close_period',
    repeat_second_close_period = 'repeat_second_close_period',
    repeat_second_close_period_ended = 'repeat_second_close_period_ended',
    random_period = 'random_period',
    random_period_ended = 'random_period_ended',
}
