import { FC, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { clearInterval } from 'worker-timers';
import { addNotification, removeNotification } from '../../helpers/notification';
import { Container } from './styled';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

export interface StatusConnectionProps {
    socketConnection: any;
}

let disconnectedSocketTimer: any = { long: undefined, short: undefined };
let disconnectedInternetTimer: any;
let internetNotificationId: any;
let socketNotificationId: any;

const StatusConnection: FC<StatusConnectionProps> = ({ socketConnection }) => {
    const [socketDisconnected, setSocketDisconnected] = useState({
        // seta como true se internet caiu imediatamente
        short: false,
        // seta como true se internet caiu e persiste por mais de 5 segundos
        long: false,
    });
    const [internetDisconnected, setInternetDisconnected] = useState({
        // seta como true se internet caiu imediatamente
        short: false,
        // seta como true se internet caiu e persiste por mais de 5 segundos
        long: false,
    });

    const { t } = useTranslation();

    const internetDisconnectedIntervalRef = useRef<any>(null);
    const socketDisconnectedRef: any = useRef(null);
    socketDisconnectedRef.current = { socketDisconnected };

    const internetDisconnectedRef: any = useRef(internetDisconnected);
    internetDisconnectedRef.current = { internetDisconnected };

    useEffect(() => {
        handleSocketEvents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socketConnection]);

    const handleSocketEvents = () => {
        if (!socketConnection || socketDisconnectedRef.current.socketDisconnected.long) {
            return;
        }

        socketConnection.on('disconnect', () => handleDisconnected(true));
        socketConnection.on('connect', () => handleDisconnected(false));
        socketConnection.on('connect_failed', () => handleDisconnected(true));
        socketConnection.on('connect_error', () => handleDisconnected(true));
    };

    const handleDisconnected = (disconnected: boolean) => {
        // se caiu no long não altera mais o front, é necessário reload
        if (socketDisconnectedRef.current.socketDisconnected.long) {
            return;
        }

        if (!disconnected) {
            setSocketDisconnected({
                long: disconnected,
                short: disconnected,
            });
            disconnectedSocketTimer.long?.cancel();
            disconnectedSocketTimer.short?.cancel();
            disconnectedSocketTimer = { long: undefined, short: undefined };
        } else if (disconnected && !disconnectedSocketTimer.long) {
            // alguns lugares recriam a conexão socket, então aguarda 2 segundos de desconexão para apresentar a mensagem
            // de que a conexão caiu
            disconnectedSocketTimer.short = debounce(
                () =>
                    setSocketDisconnected((prevState) => ({
                        ...prevState,
                        short: disconnected,
                    })),
                2000
            );

            // se ficar sem por 6 segundos então seta que não tem internet
            disconnectedSocketTimer.long = debounce(
                () =>
                    setSocketDisconnected((prevState) => ({
                        ...prevState,
                        long: disconnected,
                    })),
                6000
            );

            disconnectedSocketTimer.short();
            disconnectedSocketTimer.long();
        }
    };

    const doInternetDisconnected = (isOnline: boolean) => {
        // se caiu no long não altera mais o front, é necessário reload
        if (internetDisconnectedRef.current.internetDisconnected.long) {
            return;
        }

        if (isOnline) {
            setInternetDisconnected({
                long: !isOnline,
                short: !isOnline,
            });
            disconnectedInternetTimer?.cancel();
            disconnectedInternetTimer = undefined;
        } else if (!isOnline && !disconnectedInternetTimer) {
            // se ficar sem por 4 segundos então seta que não tem internet
            disconnectedInternetTimer = debounce(() => {
                setInternetDisconnected((prevState) => ({
                    ...prevState,
                    long: !isOnline,
                }));
            }, 4000);

            disconnectedInternetTimer();
        }

        setInternetDisconnected((prevState) => ({
            ...prevState,
            short: !isOnline,
        }));
    };

    useEffect(() => {
        if (internetDisconnected.short && !internetDisconnected.long && !internetNotificationId) {
            internetNotificationId = addNotification(
                t('info.internet.connection'),
                t('info.internet.connection.error'),
                'danger',
                6000,
                {
                    insert: 'top',
                    container: 'top-right',
                    onRemoval: () => {
                        internetNotificationId = undefined;
                    },
                }
            );
        }

        if (internetDisconnected.long) {
            removeNotification(internetNotificationId);
            socketConnection.disconnect();
            if (internetDisconnectedIntervalRef.current) {
                clearInterval(internetDisconnectedIntervalRef.current);
            }
        }
    }, [internetDisconnected]);

    useEffect(() => {
        if (socketDisconnected.short && !socketDisconnected.long && !socketNotificationId) {
            socketNotificationId = addNotification(
                t('info.internet.connection'),
                t('info.internet.connection.error2'),
                'danger',
                6000,
                {
                    insert: 'top',
                    container: 'top-right',
                    onRemoval: () => {
                        socketNotificationId = undefined;
                    },
                }
            );
        }

        if (socketDisconnected.long) {
            removeNotification(socketNotificationId);
            socketConnection.disconnect();
        }
    }, [socketDisconnected]);

    if (internetDisconnected.long || socketDisconnected.long) {
        return (
            <Container>
                <div className='internet-disconnected-text'>
                    <p>{t('info.internet.connection')}</p>
                    <p>{t('info.internet.connection.refresh')}</p>

                    <Button
                        style={{
                            borderRadius: '4px',
                            margin: '0 5px 0 0',
                            background: 'var(--platform-primary-color)',
                            color: '#fff',
                        }}
                        onClick={() => window.location.reload()}
                    >
                        {t('info.refresh.page')}
                    </Button>
                </div>
            </Container>
        );
    }

    return null;
};

export default StatusConnection;
