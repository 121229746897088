import styled from 'styled-components';
import Wrapper from '../../../../Common/wrapper';

const Lot = styled(Wrapper)`
    :not(:last-child) {
        border-bottom: 1px solid #d6d6d6;
    }
`;

const ProposalCount = styled.div`
    display: flex;
    span {
        font-size: 13px;
    }
`;

const LotItem = styled(Wrapper)`
    max-width: 115px;
    min-width: 115px;

    :nth-child(1) {
        font-size: 15px;
        font-weight: 600;
    }

    :nth-child(2) {
        font-size: 13px;
        font-weight: 600;
        color: #666;
    }
`;

export { Lot, ProposalCount, LotItem };
