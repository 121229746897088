import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import './i18n/config';

if (process.env.REACT_APP_NODE_ENV !== undefined) {
    Sentry.init({
        dsn: 'https://9f1f1e083e32431c9534e47b9d801401@o428699.ingest.sentry.io/5374364',
        environment: process.env.REACT_APP_NODE_ENV,
    });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
