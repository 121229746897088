import { FC, useState, useEffect, useMemo } from 'react';
import { AuctionLotItemsProps } from './props';
import Wrapper from '../Common/wrapper';
import AuctionLotItem from './components/AuctionLotItem';
import Header from '../Common/Header';
import Scroll from '../Common/Scroll';
import ApiService from '../../services/api';
import { AuctionNoticeLotItem } from '../../Interfaces/auctionNoticeLotItem';
import { StageLot } from '../../Interfaces/stageLot';
import { timeout } from 'helpers/timer';
import { Spin, SpinArea } from 'Components/Common/Spin';
import { isAuctioneer } from '../../helpers/permissions';
import { useTranslation } from 'react-i18next';

const stagesToShowReference = [StageLot.negotiation_finished];

const AuctionLotItems: FC<AuctionLotItemsProps> = ({
    auctionNoticeLotSelected,
    authUser,
    auctionNotice,
}) => {
    const [isOpened, setOpened] = useState(true);
    const [loading, setLoading] = useState(true);
    const [auctionLotItems, setAuctionLotItems] = useState<AuctionNoticeLotItem[]>([]);

    const { t } = useTranslation();

    useEffect(() => {
        if (auctionNoticeLotSelected?.id) {
            getAuctionLotItems();
            return;
        }
        setAuctionLotItems([]);
        setLoading(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auctionNoticeLotSelected.id]);

    const reloadAuctionItems = () => {
        setLoading(true);
        getAuctionLotItems();
    };

    useEffect(() => {
        reloadAuctionItems();
    }, [auctionNoticeLotSelected.showReferenceValue]);

    useEffect(() => {
        if (
            stagesToShowReference.includes(auctionNoticeLotSelected.stage) &&
            auctionNoticeLotSelected.showReferenceValue === 1
        ) {
            getAuctionLotItems();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auctionNoticeLotSelected.stage]);

    const getAuctionLotItems = async () => {
        if (!auctionNoticeLotSelected.auctionNoticeId || !auctionNoticeLotSelected.id) return;
        setLoading(true);
        const auctionLotItems = await ApiService.getAuctionNoticeLotItems(
            auctionNoticeLotSelected.auctionNoticeId,
            auctionNoticeLotSelected.id
        );

        if (auctionLotItems?.length >= 0) {
            setAuctionLotItems([...auctionLotItems]);
        }

        timeout(() => setLoading(false), 100);
    };

    const canShowReferenceValue = useMemo(() => {
        return (
            auctionNoticeLotSelected.showReferenceValue === 0 ||
            isAuctioneer(authUser) ||
            (auctionNotice.auctionTypeRules.generalSettings?.cadastro?.manterSigiloExternoAte ===
                'negotiation_finished' &&
                auctionNoticeLotSelected.showReferenceValue === 1 &&
                stagesToShowReference.includes(auctionNoticeLotSelected.stage))
        );
    }, [
        auctionNotice.auctionTypeRules.generalSettings?.cadastro?.manterSigiloExternoAte,
        auctionNoticeLotSelected.showReferenceValue,
        auctionNoticeLotSelected.stage,
        authUser,
    ]);

    return (
        <Wrapper
            margin='10px 0'
            border='1px solid rgba(204, 204, 204, 0.5);'
            height={isOpened ? 'auto' : '46px'}
            width='100%'
            minHeight={isOpened ? '102px' : 'auto'}
            minWidth='290px'
        >
            <Header
                showMinimize={true}
                icon='package'
                title={t('info.lot.items')}
                onExpand={() => setOpened(!isOpened)}
                expandDirection={isOpened ? 'rotate(-90deg)' : 'rotate(0deg)'}
            />

            {isOpened && (
                <Scroll
                    style={{
                        overflowY: 'auto',
                        height: 'calc(100% - 46px)',
                    }}
                >
                    {loading ? (
                        <SpinArea>
                            <Spin />
                        </SpinArea>
                    ) : auctionLotItems?.length > 0 ? (
                        auctionLotItems.map((item: AuctionNoticeLotItem) => (
                            <AuctionLotItem
                                showReferenceValue={canShowReferenceValue}
                                item={item}
                                key={item.id}
                                decimalPlaces={auctionNotice.decimalPlaces}
                            />
                        ))
                    ) : (
                        <Wrapper margin='30px' textAlign='center'>
                            {t('info.empty.lot.items')}
                        </Wrapper>
                    )}
                </Scroll>
            )}
        </Wrapper>
    );
};

export default AuctionLotItems;
