import { TypeAccess, User } from '../Interfaces/user';

export const isCitizen = (user: User): boolean => {
    return user.typeAccess === TypeAccess.citizen;
};

export const isProvider = (user: User): boolean => {
    return user.typeAccess === TypeAccess.provider;
};

// qual as condições para ser um pregoeiro? Apenas typeAccess === organization?
export const isAuctioneer = (user: User): boolean => {
    return user.typeAccess !== TypeAccess.provider && user.typeAccess !== TypeAccess.citizen;
};
