import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Wrapper from '../Common/wrapper';
import BidHistoryModal from './components/BidHistoryModal';
import { BidHistoryProps } from './props';
import { BidHistoryIcon } from './styled';

const BidHistory: FC<BidHistoryProps> = ({ auctionNoticeLotSelected, auctionNotice }) => {
    const [visible, setVisible] = useState(false);
    const { t } = useTranslation();

    return (
        <>
            {visible && (
                <BidHistoryModal
                    onClose={() => setVisible(false)}
                    auctionNoticeLotSelected={auctionNoticeLotSelected}
                    auctionNotice={auctionNotice}
                />
            )}
            <Wrapper display='flex' alignItems='center'>
                <BidHistoryIcon title={t('info.bid.history')} onClick={() => setVisible(true)} />
            </Wrapper>
        </>
    );
};

export default BidHistory;
