import styled from 'styled-components';
import Wrapper from '../../../Common/wrapper';

const Wrapped = styled(Wrapper)`
    :not(:last-child) {
        border-bottom: 1px solid #d7d7d7;
    }
`;

const CancelBid = styled.img`
    width: 11px;
    height: 14px;
    margin: 0 5px 0 0;
    cursor: pointer;
`;

export { CancelBid, Wrapped };
