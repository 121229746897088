import { FC } from 'react';
import { ProviderValueItemCitzenProps } from './props';
import Wrapper from '../../../Common/wrapper';
import { formatValueWithAuctionRules } from '../../../../helpers/currencyTransform';
import { ProviderValueAction } from '../../../../Interfaces/providerValues';
import { Wrapped } from './styled';
import Badge from '../../../Common/Badge';
import ProviderValuesPopoverInfo from 'Components/ProviderValues/components/ProviderValuesPopoverInfo';
import { useTranslation } from 'react-i18next';
import { isDeclinedProviderValue } from '../../../../helpers/declined';
import HelpText from 'Components/HelpText';

const ProviderValueItemCitzen: FC<ProviderValueItemCitzenProps> = ({
    providerValue,
    index,
    auctionNotice,
}) => {
    const { t } = useTranslation();

    const getCompanyType = () => {
        let data: { short: string; title: string; color: string };

        if (providerValue.itsSimpleCompany === 1) {
            data = { short: 'ME', title: 'ME/EPP/COOP', color: 'var(--platform-success-color)' };
        } else {
            data = { short: 'OE', title: t('term.not-simple.company'), color: 'var(--platform-success-color)' };
        }

        if (providerValue.itsRegionalCompany === 1 || providerValue.itsRegionalCompany === 2) {
            data = {
                ...data,
                color: 'var(--platform-info-color)',
                title: `${data.title} - ${t('info.regional')}`,
            };
        }

        return data;
    };

    const getActionByStatus = () => {
        switch (providerValue.currentAction) {
            case ProviderValueAction.show_convoke:
                return {
                    title: t('info.provider-value.status.to-convoke.title'),
                    actionName: t('info.provider-value.status.to-convoke.action'),
                    short: t('info.provider-value.status.to-convoke.short'),
                };
            case ProviderValueAction.show_winner:
                return {
                    title: t('info.provider-value.status.winner.title'),
                    actionName: t('info.provider-value.status.winner.action'),
                    short: t('info.provider-value.status.winner.short'),
                };
            case ProviderValueAction.show_not_covered:
                return {
                    title: t('info.provider-value.status.not-covered.title'),
                    actionName: t('info.provider-value.status.not-covered.action'),
                    bgColor: 'var(--platform-warning-color)',
                    short: t('info.provider-value.status.not-covered.short'),
                };
            case ProviderValueAction.show_convoked:
                return {
                    title: t('info.provider-value.status.convoked.title'),
                    actionName: t('info.provider-value.status.convoked.action'),
                    short: t('info.provider-value.status.convoked.short'),
                };
            case ProviderValueAction.show_negotiating:
                return {
                    title: t('info.provider-value.status.negotiating.title'),
                    actionName: t('info.provider-value.status.negotiating.action'),
                    bgColor: 'var(--platform-tertiary-color)',
                    short: t('info.provider-value.status.negotiating.short'),
                };
            case ProviderValueAction.show_unclassified:
                return {
                    title: t('info.provider-value.status.unclassified.title'),
                    actionName: t('info.provider-value.status.unclassified.action'),
                    bgColor: '#881c2a',
                    short: t('info.provider-value.status.unclassified.short'),
                };
            case ProviderValueAction.show_disabled:
                return {
                    title: t('info.provider-value.status.disabled.title'),
                    actionName: t('info.provider-value.status.disabled.action'),
                    bgColor: '#d22840',
                    short: t('info.provider-value.status.disabled.short'),
                };
        }
    };

    const providerValueStatus = getActionByStatus();
    const companyType = getCompanyType();

    function getFormattedValue(value: string | number | null) {
        return formatValueWithAuctionRules(
            value,
            auctionNotice.judgmentCriterion,
            auctionNotice.rateTypeBid,
            auctionNotice.decimalPlaces
        ).formatted;
    }

    return (
        <Wrapped
            flexBox
            width='100%'
            height='45px'
            padding='6px 5px'
            margin='4px 0'
            overflowY='hidden'
        >
            <Wrapper justifyContent='space-between' width='100%' alignItems='center' flexBox>
                <Wrapper alignItems='center' flexBox>
                    <Wrapper alignItems='center' flexBox>
                        <Wrapper width='20px' margin='0 5px 0 0' fontSize='12px' fontWeight='700'>
                            {`${index < 9 ? `0${index + 1}` : `${index + 1}`}º`}
                        </Wrapper>
                        <Badge
                            color={companyType.color}
                            title={companyType.title}
                            width='24px'
                            margin='0 2px'
                        >
                            {companyType.short}
                        </Badge>
                    </Wrapper>
                    <ProviderValuesPopoverInfo providerValue={providerValue}>
                        <Wrapper cursor='help' truncate fontSize='13px' margin=' 0 0 0 5px'>
                            {getFormattedValue(providerValue?.value)}
                        </Wrapper>
                    </ProviderValuesPopoverInfo>
                    {!!providerValue?.proposal?.additionalExpense && (
                        <HelpText
                            size={12}
                            helpText={t('info.lot-proposal-values', {
                                totalValue: getFormattedValue(providerValue?.value ?? ''),
                                baseValue: getFormattedValue(
                                    Number(providerValue?.value ?? 0) -
                                        Number(providerValue?.proposal?.additionalExpense ?? 0)
                                ),
                                additionalExpense: getFormattedValue(
                                    providerValue?.proposal?.additionalExpense ?? ''
                                ),
                            })}
                        />
                    )}
                </Wrapper>
                <Wrapper alignItems='center' flexBox>
                    {!!providerValueStatus && (
                        <Badge
                            cursor='pointer'
                            padding='0 2px'
                            minWidth='27px'
                            color={providerValueStatus.bgColor || 'var(--platform-secondary-color)'}
                            title={providerValueStatus?.title}
                            fontSize='9px'
                        >
                            {providerValueStatus?.short}
                        </Badge>
                    )}
                    {isDeclinedProviderValue(providerValue) && (
                        <Badge
                            title={t('info.provider-declined')}
                            fontSize='9px'
                            color='#a47110'
                            width='24px'
                            margin='0 0 0 3px'
                        >
                            {`DEC`}
                        </Badge>
                    )}
                </Wrapper>
            </Wrapper>
        </Wrapped>
    );
};

export default ProviderValueItemCitzen;
