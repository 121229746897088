import { FC } from 'react';
import styled from 'styled-components';
import { Imageprops } from './props';

const Img = styled.img<Imageprops>`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    ${(props) =>
        !!props.cursor &&
        `
    cursor: ${props.cursor}
  `};
    ${(props) =>
        !!props.margin &&
        `
    margin: ${props.margin}
  `};
    ${(props) =>
        !!props.transform &&
        `
    transform: ${props.transform}
  `};
`;

const Image: FC<Imageprops> = (props) => {
    return <Img {...props} title={props.title} src={props.src} />;
};

export default Image;
