import styled from 'styled-components';
import { Button } from 'antd';
import Wrapper from 'Components/Common/wrapper';

const InputsContainer = styled(Wrapper)`
    textarea {
        font-size: 13px;
        resize: none;
        margin-top: 10px;
    }
`
const Card = styled(Wrapper)`
    background-color: #FFF;
    width: auto;
    min-width: 330px;
    height: auto;
    padding: 13px;
`

const ContentWrapper = styled(Wrapper)`
    font-size: 14px;
    padding: 3px 0 10px 0;
`

const ErrorWrapper = styled(Wrapper)`
    margin: 5px 0 5px 0;
    font-size: 10px;
    color: ${({theme}) => theme.colors.danger};
`

const ActionButtonsWrapper = styled(Wrapper)`
    display: flex;
    margin: 14px 0 0 0;
    justify-content: space-between;
    align-items: center;

    button {
        border-radius: 3px;
        font-size: 13px;
        text-transform: capitalize;
    }  
`


const SendButton = styled(Button)`
    background: ${({theme}) => theme.colors.primary} !important;

    span {
        color: #fff;
    }
    :hover {
        background: ${({theme}) => theme.colors.primary};
    }
`;

export {
    Card,
    ContentWrapper,
    ErrorWrapper,
    InputsContainer,
    ActionButtonsWrapper,
    SendButton,
}
