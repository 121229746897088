import { FC } from 'react';
import { AuctionLotItemProps } from './props';
import Wrapper from '../../../Common/wrapper';
import { parseCurrency, currencyFormat } from '../../../../helpers/currencyTransform';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Wrapped = styled(Wrapper)`
    :not(:last-child) {
        border-bottom: 1px solid #d7d7d7;
    }
`;

const AuctionLotItem: FC<AuctionLotItemProps> = ({ item, showReferenceValue, decimalPlaces }) => {
    const { t } = useTranslation();

    return (
        <Wrapped padding='6px 5px' height='52px'>
            <Wrapper flexBox justifyContent='space-between'>
                <Wrapper truncate fontSize='13px'>
                    {item.itemDescription}
                </Wrapper>
                <Wrapper fontSize='13px'>{t('term.reference')}</Wrapper>
            </Wrapper>
            <Wrapper flexBox justifyContent='space-between'>
                <Wrapper truncate fontWeight='700' fontSize='13px'>
                    {`${item.amount} ${item.unitMeasurement || ''}`}
                </Wrapper>
                <Wrapper>
                    <span
                        title={showReferenceValue ? '' : t('term.ommited.value')}
                        style={{
                            fontSize: '13px',
                            fontWeight: 700,
                        }}
                    >
                        {showReferenceValue && item.referenceValue
                            ? `${currencyFormat} ${parseCurrency(
                                  parseFloat(item.referenceValue),
                                  decimalPlaces
                              )}`
                            : `R$ (${t('term.secret')})`}
                    </span>
                </Wrapper>
            </Wrapper>
        </Wrapped>
    );
};

export default AuctionLotItem;
