import styled from 'styled-components';
import Wrapper from '../../../Common/wrapper';

const Wrapped = styled(Wrapper)`
    :not(:last-child) {
        border-bottom: 1px solid #d7d7d7;
    }
`;

export { Wrapped };
