import { FC } from 'react';
import { CardLotModeSupplierProps } from './props';
import styled from 'styled-components';
import Wrapper from '../../../Common/wrapper';
import Image from '../../../Common/Image';
import { methodDisputeDescription } from '../../../../helpers/methodDispute';
import { pad } from '../../../../helpers/pad';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../../../helpers/mobile';
import { BsChatText } from 'react-icons/bs';
import { MdOutlineSpaceDashboard } from 'react-icons/md';
import { dispatchWindowEvent } from '../../../../Hooks/window-event.hook';
import ReactSVG from 'Components/ReactSVG';

const Wrapped = styled(Wrapper)`
    height: 70px;
    border: 1px solid rgba(204, 204, 204, 0.5);
`;

const IconChat = styled(BsChatText)`
    width: 20px;
    cursor: pointer;
    height: 20px;
    margin: 0 12px;
    color: #555;
`;

const IconResume = styled(MdOutlineSpaceDashboard)`
    width: 20px;
    cursor: pointer;
    height: 20px;
    color: #555;
`;

const CardLotModeSupplier: FC<CardLotModeSupplierProps> = ({ auctionNotice, authUser }) => {
    const providerCode = authUser.providerAuctionCode ? pad(authUser.providerAuctionCode) : '--';
    const { t } = useTranslation();

    const handleClickChat = () => {
        dispatchWindowEvent('@event.open-chat', {});
    };

    const handleClickResume = () => {
        dispatchWindowEvent('@event.open-resume', {});
    };

    return (
        <Wrapped bgcolor='#FFF' minWidth='260px' padding='10px 15px' borderRadius='5px'>
            <Wrapper textAlign='center' margin='0 0 8px 0'>
                <Wrapper fontSize='13px' title={t('info.provider.code', { value: providerCode })}>
                    <Image
                        src='../../assets/icons/user.svg'
                        width='17px'
                        height='17px'
                        margin='0 8px 0 0 '
                    />
                    {t('info.who.provider')} <b>{providerCode}</b>
                </Wrapper>
            </Wrapper>
            <Wrapper
                justifyContent='center'
                alignItems='center'
                fontWeight='600'
                color='#555'
                flexBox
            >
                <Wrapper fontSize='13px' margin='0 13px 0 0'>
                    <ReactSVG
                        color='var(--platform-secondary-color)'
                        src='../../assets/icons/clock2.svg'
                        width='20px'
                        height='20px'
                    />
                </Wrapper>
                {methodDisputeDescription({ methodDispute: auctionNotice.methodDispute })}
                <Wrapper flexBox>
                    {isMobile() && (
                        <>
                            <IconChat onClick={handleClickChat} />
                            <IconResume onClick={handleClickResume} />
                        </>
                    )}
                </Wrapper>
            </Wrapper>
        </Wrapped>
    );
};

export default CardLotModeSupplier;
