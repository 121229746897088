import { useState, useEffect, FC, useRef } from 'react';
import Counter from '../AuctionLots/components/Counter';
import moment from 'moment';
import { ModeConvokedProviderProps } from './props';
import { StageLot } from '../../Interfaces/stageLot';
import { currMoment } from '../../helpers/moment';
import { CONSTANTS } from '../../constants';
import { isAuctioneer } from '../../helpers/permissions';

const ModeConvokedProvider: FC<ModeConvokedProviderProps> = ({
    auctionLot,
    changeStage,
    onFinishProviderPeriodConvoked,
    authUser,
    serverTimestamp,
    helpText,
    title,
}) => {
    const [seconds, setSeconds] = useState(-1);

    const secondsRef: any = useRef(null);
    secondsRef.current = { seconds, setSeconds };

    useEffect(() => {
        validateIsProviderCalled();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auctionLot]);

    const validateTimer = () => {
        const now = currMoment(serverTimestamp.difference);

        if (!auctionLot.convokedProvider?.statusUpdatedAt) return;
        const finish = moment(auctionLot.convokedProvider.statusUpdatedAt).add(
            CONSTANTS.timersDuration.CONVOKED_PERIOD,
            'seconds'
        );

        const seconds = finish && moment.duration(finish.diff(now)).asSeconds();

        if (seconds > 0) {
            if (auctionLot.stage !== StageLot.convoked) {
                changeStage(StageLot.convoked);
            }
        } else {
            if (auctionLot.stage !== StageLot.awaiting_call_provider) {
                changeStage(StageLot.awaiting_call_provider);
            }
        }
        secondsRef.current.setSeconds(seconds);
    };

    const onFinishTimer = () => {
        if (auctionLot.stage !== StageLot.awaiting_call_provider)
            changeStage(StageLot.awaiting_call_provider);

        return onFinishProviderPeriodConvoked();
    };

    const validateIsProviderCalled = () => {
        if (!auctionLot.convokedProvider) return;

        if (
            auctionLot.convokedProvider.providerAuctionCode === authUser.providerAuctionCode ||
            isAuctioneer(authUser)
        )
            return validateTimer();

        if (auctionLot.stage !== StageLot.awaiting_call_provider)
            changeStage(StageLot.awaiting_call_provider);
    };

    return (
        <Counter
            title={title}
            secondsRemaining={secondsRef.current.seconds}
            onFinishTimer={onFinishTimer}
            visible={true}
            helpText={helpText}
        />
    );
};

export default ModeConvokedProvider;
