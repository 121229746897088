import { FC, useEffect, useRef, useState } from 'react';
import ApiService from '../../services/api';
import { MultipleWinnersListProps } from './props';
import { useTranslation } from 'react-i18next';
import { MultipleWinners } from '../../Interfaces/multipleWinners';
import Wrapper from '../Common/wrapper';
import GroupSeparator from '../Common/GroupSeparator';
import { orderBy } from 'lodash';
import BestProviderValue from './components/BestProviderValue';
import Header from '../Common/Header';
import Scroll from '../Common/Scroll';
import { ProviderValue, ProviderValueStatus } from '../../Interfaces/providerValues';
import { SocketEvent, SocketEvents } from '../../Interfaces/socketEvents';
import TinyQueue from 'tinyqueue';
import { timeout } from '../../helpers/timer';
import MultipleWinnerItem from './components/MultipleWinnerItem';
import ProviderValueItem from './components/ProviderValueItem';
import { Spin, SpinArea } from '../Common/Spin';
import BidHistory from 'Components/BidHistory';

const MultipleWinnersList: FC<MultipleWinnersListProps> = ({
    auction,
    auctionLot,
    socketConnection,
    viewMode,
    getWinners,
}) => {
    const [isOpened, setOpened] = useState(true);
    const [multipleWinners, setMultipleWinners] = useState<MultipleWinners[]>([]);
    const [providerValues, setProviderValues] = useState<ProviderValue[]>([]);
    const [isLoadingMultipleWinners, setIsLoadingMultipleWinners] = useState(true);
    const [isLoadingProviderValues, setIsLoadingProviderValues] = useState(true);

    const providerValuesRef: any = useRef(null);
    providerValuesRef.current = { providerValues, setProviderValues };

    const multipleWinnersRef: any = useRef(null);
    multipleWinnersRef.current = { multipleWinners, setMultipleWinners };

    const auctionLotRef: any = useRef(null);
    auctionLotRef.current = { auctionLot };

    const { t } = useTranslation();

    let queueLock = false;
    const queueEvents: any = new TinyQueue([]);

    useEffect(() => {
        listMultipleWinners();
        listProviderValues();
    }, [auctionLot.id]);

    const listProviderValues = async () => {
        setIsLoadingProviderValues(true);

        const { auctionNoticeId, id } = auctionLot;
        const { judgmentCriterion } = auction;
        const providerValueList = await ApiService.getProviderValues(auctionNoticeId, id);

        setIsLoadingProviderValues(false);
        if (providerValueList?.length) {
            setProviderValues(
                orderBy(
                    providerValueList,
                    'value',
                    judgmentCriterion === 2 || judgmentCriterion === 3 ? 'desc' : 'asc'
                )
            );
        }
    };

    const listMultipleWinners = async () => {
        setIsLoadingMultipleWinners(true);

        let error: any;
        const multipleWinners = await ApiService.listMultipleWinners(
            auction.id,
            auctionLot.id,
            (err) => {
                error = err;
            }
        );

        setMultipleWinners(multipleWinners ?? []);
        setIsLoadingMultipleWinners(false);

        if (error) {
            return;
        }
    };

    useEffect(() => {
        handleSocketEvents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socketConnection]);

    const handleSocketEvents = () => {
        if (!socketConnection) {
            return;
        }

        socketConnection.on(SocketEvents.providerValueUpdated, (event: any) =>
            receiveEvent({
                type: SocketEvents.providerValueUpdated,
                message: event,
            })
        );

        socketConnection.on(SocketEvents.providerValuesUpdated, (event: any) =>
            receiveEvent({
                type: SocketEvents.providerValuesUpdated,
                message: event,
            })
        );

        socketConnection.on(SocketEvents.multipleWinnersUpdated, (event: any) =>
            receiveEvent({
                type: SocketEvents.multipleWinnersUpdated,
                message: event,
            })
        );
    };

    const receiveEvent = (message: SocketEvent) => {
        queueEvents.push(message);

        if (!queueLock) {
            queueLock = true;
            processEvent();
        }
    };

    const processEvent = async () => {
        try {
            while (queueEvents.length) {
                const event: SocketEvent = queueEvents.pop();
                const { type, message } = event;

                switch (type) {
                    case SocketEvents.providerValueUpdated:
                        return handleProviderValueUpdated(message);

                    case SocketEvents.providerValuesUpdated:
                        return handleProviderValuesUpdated(message);

                    case SocketEvents.multipleWinnersUpdated:
                        return handleMultipleWinnersUpdated(message);
                }

                await new Promise((r) => timeout(r, 20));
            }
        } finally {
            queueLock = false;
        }
    };

    const handleProviderValueUpdated = (newProviderValue: ProviderValue) => {
        if (newProviderValue?.lotId !== auctionLotRef.current.auctionLot.id) {
            return;
        }

        const providerValues = providerValuesRef.current.providerValues;
        const providerIndex = providerValues.findIndex(
            (providerValue: ProviderValue) => providerValue.id === newProviderValue.id
        );

        providerValues[providerIndex] = {
            ...providerValues[providerIndex],
            ...newProviderValue,
        };

        setProviderValues(providerValues);
    };

    const handleMultipleWinnersUpdated = (newMultipleWinners: MultipleWinners[]) => {
        if (newMultipleWinners[0]?.lotId !== auctionLotRef.current.auctionLot.id) {
            return;
        }

        setMultipleWinners([...newMultipleWinners]);
    };

    const handleProviderValuesUpdated = (newProviderValues: any) => {
        const lotId = newProviderValues[0]?.lotId;
        if (lotId !== auctionLotRef.current.auctionLot.id) {
            return;
        }

        setProviderValues([...newProviderValues]);
    };

    const sortProviderValues = () => {
        return orderBy(
            providerValuesRef.current.providerValues,
            'value',
            auction.judgmentCriterion === 2 || auction.judgmentCriterion === 3 ? 'desc' : 'asc'
        );
    };

    const getSortedProviderValuesView = () => {
        if (!providerValuesRef.current.providerValues?.length) {
            return [];
        }

        const sortedProviderValues = sortProviderValues();
        const winnerIndex = sortedProviderValues.findIndex(
            (providerValue) => providerValue.status === ProviderValueStatus.winner
        );

        if (winnerIndex === -1) {
            return sortedProviderValues;
        }

        const winner = sortedProviderValues.splice(winnerIndex, 1)[0];
        sortedProviderValues.unshift(winner);
        return sortedProviderValues;
    };

    return (
        <Wrapper
            width='100%'
            minWidth='290px'
            margin='10px 0'
            border='1px solid rgba(204, 204, 204, 0.5);'
            minHeight={isOpened ? '100px' : 'auto'}
            height={isOpened ? 'auto' : '46px'}
        >
            <Header
                showMinimize={true}
                icon='price'
                title={t('Vencedores')}
                onExpand={() => setOpened(!isOpened)}
                expandDirection={isOpened ? 'rotate(-90deg)' : 'rotate(0deg)'}
                customActions={
                    <>
                        <BidHistory
                            auctionNoticeLotSelected={auctionLot}
                            auctionNotice={auction}
                        />
                    </>
                }
            />

            {isOpened && (
                <Scroll
                    style={{
                        overflowY: 'auto',
                        height: 'calc(100% - 46px)',
                    }}
                >
                    <Wrapper>
                        <BestProviderValue
                            auction={auction}
                            providerValues={sortProviderValues()}
                        />
                        <GroupSeparator>Classificação</GroupSeparator>
                        {isLoadingMultipleWinners ? (
                            <SpinArea>
                                <Spin />
                            </SpinArea>
                        ) : (
                            orderBy(multipleWinners, 'position', 'asc').map((winner, index) => (
                                    <MultipleWinnerItem
                                        key={winner.id}
                                        multipleWinner={winner}
                                        auction={auction}
                                        index={index}
                                        viewMode={viewMode}
                                        getWinners={getWinners}
                                    />
                                ))
                        )}
                        <GroupSeparator>Melhores lances</GroupSeparator>
                        {isLoadingProviderValues ? (
                            <SpinArea>
                                <Spin />
                            </SpinArea>
                        ) : (
                            getSortedProviderValuesView().map((providerValue: ProviderValue) => {
                                return (
                                    <ProviderValueItem
                                        multipleWinners={multipleWinnersRef.current.multipleWinners}
                                        key={providerValue.id}
                                        auctionNotice={auction}
                                        providerValue={providerValue}
                                        auctionLot={auctionLotRef.current.auctionLot}
                                        providerValues={sortProviderValues()}
                                        viewMode={viewMode}
                                    />
                                );
                            })
                        )}
                    </Wrapper>
                </Scroll>
            )}
        </Wrapper>
    );
};

export default MultipleWinnersList;
