import { FC } from 'react';
import Wrapper from '../../../../Common/wrapper';
import { BestBidProps } from './props';
import { formatValueWithAuctionRules } from '../../../../../helpers/currencyTransform';
import { useTranslation } from 'react-i18next';
import HelpText from 'Components/HelpText';

const BestBid: FC<BestBidProps> = ({ auctionLot, auctionNotice, decimalPlaces }) => {
    const { judgmentCriterion, rateTypeBid } = auctionNotice;
    const { t } = useTranslation();

    const getPercentInfo = () => {
        if (auctionLot.position?.position === 1) {
            return 'var(--platform-secondary-color)';
        } else if (!auctionLot.position?.position) {
            return '#555';
        }
        return 'var(--platform-danger-color)';
    };

    const getDiscount = () => {
        if (
            auctionLot.valueReference === null ||
            auctionLot.providerBestBid == null ||
            judgmentCriterion !== 2
        ) {
            return 0;
        }

        return !!auctionLot.valueReference
            ? parseFloat(auctionLot?.valueReference) -
                  parseFloat(auctionLot.valueReference) *
                      (parseFloat(auctionLot.providerBestBid) / 100)
            : 0;
    };

    function formatCurrencyWithRules(value: string | number) {
        return formatValueWithAuctionRules(
            value,
            judgmentCriterion,
            rateTypeBid,
            decimalPlaces
        ).formatted
    }

    return (
        <Wrapper minWidth='160px' flexBox margin='0 10px' alignItems='center'>
            <Wrapper flex>
                <Wrapper
                    title={t('info.provider.best.value.info')}
                    flexBox
                    justifyContent='space-between'
                >
                    <Wrapper fontSize='12px' truncate minWidth='80px' margin='0 10px 0 0'>
                        {t('info.provider.best.value')}
                    </Wrapper>
                    <Wrapper
                        flex
                        fontSize='13px'
                        fontWeight='700'
                        truncate
                        textAlign='right'
                        color={getPercentInfo()}
                    >
                        {
                            formatValueWithAuctionRules(
                                auctionLot.bestBid,
                                judgmentCriterion,
                                rateTypeBid,
                                decimalPlaces
                            ).formatted
                        }
                    </Wrapper>
                </Wrapper>
                <Wrapper
                    title={t('info.provider.best.bid.info')}
                    flexBox
                    justifyContent='space-between'
                >
                    <Wrapper truncate minWidth='80px' margin='0 10px 0 0' fontSize='12px'>
                        {judgmentCriterion === 1 || judgmentCriterion === 3
                            ? t('info.provider.bid.value')
                            : t('info.provider.bid.discount')}
                        {!!auctionLot?.additionalExpense && (
                            <HelpText
                                helpText={t('info.lot-proposal-values', {
                                    totalValue: formatCurrencyWithRules(
                                        auctionLot.providerBestBid ?? ''
                                    ),
                                    baseValue: formatCurrencyWithRules(
                                        Number(auctionLot.providerBestBid ?? 0) - Number(auctionLot.additionalExpense ?? 0)
                                    ),
                                    additionalExpense: formatCurrencyWithRules(
                                        auctionLot.additionalExpense ?? ''
                                    ),
                                })}
                            />
                        )}
                    </Wrapper>
                    <Wrapper flex truncate textAlign='right' fontSize='13px'>
                        {formatCurrencyWithRules(auctionLot.providerBestBid ?? '')}
                    </Wrapper>
                </Wrapper>
                {judgmentCriterion === 2 && (
                    <Wrapper
                        title={t('info.provider.best-bid.info')}
                        flexBox
                        justifyContent='space-between'
                    >
                        <Wrapper truncate minWidth='80px' margin='0 10px 0 0' fontSize='12px'>
                            {t('info.provider.bid.proposal')}
                        </Wrapper>
                        <Wrapper flex textAlign='right' truncate fontSize='13px'>
                            {
                                formatValueWithAuctionRules(
                                    getDiscount(),
                                    1,
                                    rateTypeBid,
                                    decimalPlaces
                                ).formatted
                            }
                        </Wrapper>
                    </Wrapper>
                )}
            </Wrapper>
        </Wrapper>
    );
};

export default BestBid;
