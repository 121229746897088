import { FC, useEffect, useState, useRef } from 'react';
import { BidsProps } from './props';
import Wrapper from '../Common/wrapper';
import Header from '../Common/Header';
import Scroll from '../Common/Scroll';
import BidItemAuctionner from './components/BidItemAuctionner';
import BidItemSupplier from './components/BidItemSupplier';
import { Bid } from '../../Interfaces/bid';
import { orderBy } from 'lodash';
import ApiService from '../../services/api';
import { SocketEvents, SocketEvent } from '../../Interfaces/socketEvents';
import TinyQueue from 'tinyqueue';
import { simplePercentDiff } from '../../helpers/difference';
import { timeout } from 'helpers/timer';
import { Spin, SpinArea } from '../Common/Spin';
import { isAuctioneer, isCitizen, isProvider } from '../../helpers/permissions';
import BidItemCitzen from './components/BidItemCitzen';
import { useTranslation } from 'react-i18next';
import BidHistory from '../BidHistory';
import { Decline, DeclineType } from '../../Interfaces/decline';

const Bids: FC<BidsProps> = ({
    auctionNoticeLotSelected,
    auctionNotice,
    authUser,
    socketConnection,
}) => {
    const [loading, setLoading] = useState(true);
    const [isOpened, setOpened] = useState(true);
    const [bids, setBids] = useState<Bid[]>([]);

    const { t } = useTranslation();

    let queueLock = false;
    const queueEvents: any = new TinyQueue([]);

    const bidsRef: any = useRef(null);
    bidsRef.current = { bids, setBids };

    const auctionNoticeLotSelectedRef: any = useRef(null);
    auctionNoticeLotSelectedRef.current = { auctionNoticeLotSelected };

    useEffect(() => {
        if (!auctionNoticeLotSelected?.id) {
            bidsRef.current.setBids([]);
            setLoading(false);
            return;
        }

        setLoading(true);
        getBids();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auctionNoticeLotSelected.id]);

    useEffect(() => {
        handleSocketEvents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socketConnection]);

    const handleSocketEvents = () => {
        if (!socketConnection) {
            return;
        }
        socketConnection.on(SocketEvents.deletedBid, (event: Bid) =>
            receiveEvent({
                type: SocketEvents.deletedBid,
                message: event,
            })
        );
        socketConnection.on(SocketEvents.newBid, (event: Bid) =>
            receiveEvent({
                type: SocketEvents.newBid,
                message: event,
            })
        );
        socketConnection.on(SocketEvents.deferredCreated, (event: Bid) =>
            receiveEvent({
                type: SocketEvents.deferredCreated,
                message: event,
            })
        );
    };

    const handleDeletedBid = (bid: Bid) => {
        const { auctionNoticeLotSelected } = auctionNoticeLotSelectedRef.current;

        if (bid.lotId !== auctionNoticeLotSelected.id) {
            return;
        }

        replaceBids([...bidsRef.current.bids.filter((currBid: Bid) => currBid.id !== bid.id)]);
    };

    const handleNewBid = (bid: Bid) => {
        const { auctionNoticeLotSelected } = auctionNoticeLotSelectedRef.current;

        if (bid.lotId !== auctionNoticeLotSelected.id) {
            return;
        }

        replaceBids([...bidsRef.current.bids, bid]);
    };

    const handleDeferredCreated = (decline: Decline) => {
        const { auctionNoticeLotSelected } = auctionNoticeLotSelectedRef.current;

        if (decline.lotId === auctionNoticeLotSelected.id || !decline.lotId) {
            replaceBids([
                ...bidsRef.current.bids.filter(
                    (bid) => bid.providerAuctionCode !== decline.participate?.providerAuctionCode
                ),
            ]);
        }
    };

    const getBids = async () => {
        const { auctionNoticeLotSelected } = auctionNoticeLotSelectedRef.current;

        const bidList = await ApiService.getBids(
            auctionNoticeLotSelected.auctionNoticeId,
            auctionNoticeLotSelected.id
        );

        replaceBids(bidList ?? []);
        timeout(() => setLoading(false), 100);
    };

    const replaceBids = (bids: Bid[]) => {
        const bidsWithVariation = calculateVariation(bids);
        const transformedBids = transformBids(bidsWithVariation);
        bidsRef.current.setBids(transformedBids);
    };

    const calculateVariation = (bids: Bid[]) => {
        const { judgmentCriterion } = auctionNotice;

        const orderedBids = orderBy(
            bids,
            'value',
            judgmentCriterion === 2 || judgmentCriterion === 3 ? 'desc' : 'asc'
        ).slice(0, 10);

        const newBids = orderedBids.map((bid: Bid, index: number) => ({
            ...bid,
            value: bid.value,
            variation:
                index === 0
                    ? 0
                    : simplePercentDiff(
                          orderedBids[0]?.value,
                          bid.value,
                          auctionNotice.judgmentCriterion
                      ),
        }));

        return newBids;
    };

    const transformBids = (bids: Bid[]) => {
        let firstAuthUserBid: Bid | any = undefined;

        bids.forEach((currBid: Bid, index: number) => {
            currBid.isFirstAuthProviderBid = false;

            if (
                currBid.providerAuctionCode === authUser.providerAuctionCode &&
                !firstAuthUserBid &&
                index === 0
            ) {
                firstAuthUserBid = currBid;
                currBid.isFirstAuthProviderBid = true;
            } else if (currBid.providerAuctionCode === authUser.providerAuctionCode) {
                currBid.isAuthProviderBid = true;
            }
        });
        return bids;
    };

    const receiveEvent = (message: SocketEvent) => {
        queueEvents.push(message);

        if (!queueLock) {
            queueLock = true;
            processEvent();
        }
    };

    const processEvent = async () => {
        try {
            while (queueEvents.length) {
                const event: SocketEvent = queueEvents.pop();
                const { type, message } = event;

                switch (type) {
                    case SocketEvents.deletedBid:
                        return handleDeletedBid(message);

                    case SocketEvents.newBid:
                        return handleNewBid(message);

                    case SocketEvents.deferredCreated:
                        return handleDeferredCreated(message);
                }

                await new Promise((r) => timeout(r, 20));
            }
        } finally {
            queueLock = false;
        }
    };

    const getBidRender = () => {
        if (loading) {
            return (
                <SpinArea>
                    <Spin />
                </SpinArea>
            );
        }

        if (!bidsRef.current.bids?.length) {
            return (
                <Wrapper margin='30px 0' flexBox justifyContent='center'>
                    {t('info.empty.bid.list')}
                </Wrapper>
            );
        }

        return bidsRef.current.bids.map((bid: Bid, i: number) => {
            if (isAuctioneer(authUser)) {
                return (
                    <BidItemAuctionner
                        key={`bid:${bid.id}`}
                        bids={bids}
                        auctionNoticeLotSelected={auctionNoticeLotSelected}
                        index={i}
                        auctionNotice={auctionNotice}
                        bid={bid}
                    />
                );
            }

            if (isProvider(authUser)) {
                return (
                    <BidItemSupplier
                        key={`bid:${bid.id}`}
                        index={i}
                        bids={bids}
                        auctionNotice={auctionNotice}
                        lastBidAuthProvider={bid.isFirstAuthProviderBid || false}
                        bidAuthProvider={bid.isAuthProviderBid || false}
                        bid={bid}
                        lotId={auctionNoticeLotSelected.id}
                        auctionNoticeId={auctionNotice.id}
                    />
                );
            }

            if (isCitizen(authUser)) {
                return (
                    <BidItemCitzen
                        key={`bid:${bid.id}`}
                        bids={bids}
                        index={i}
                        auctionNotice={auctionNotice}
                        bid={bid}
                    />
                );
            }

            return <></>;
        });
    };

    return (
        <Wrapper
            width='100%'
            minWidth='290px'
            margin='10px 0'
            border='1px solid rgba(204, 204, 204, 0.5);'
            minHeight={isOpened ? '100px' : 'auto'}
            height={isOpened ? 'auto' : '46px'}
        >
            <Header
                showMinimize={true}
                icon='price'
                title={t('term.bids')}
                onExpand={() => setOpened(!isOpened)}
                expandDirection={isOpened ? 'rotate(-90deg)' : 'rotate(0deg)'}
                customActions={
                    <>
                        <BidHistory
                            auctionNoticeLotSelected={auctionNoticeLotSelected}
                            auctionNotice={auctionNotice}
                        />
                    </>
                }
            />

            {isOpened && (
                <Scroll
                    style={{
                        overflowY: 'auto',
                        height: 'calc(100% - 46px)',
                    }}
                >
                    {getBidRender()}
                </Scroll>
            )}
        </Wrapper>
    );
};

export default Bids;
