import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Image from '../../Components/Common/Image';

const Content = styled.div`
    display: flex;
    justify-content: center;
    margin: 10% 0;
    flex-direction: column;
    align-items: center;
`;

export interface NotFoundProps {}

const NotFound: FC<NotFoundProps> = () => {
    const { t } = useTranslation();

    return (
        <Content>
            <h2>{t('info.not-found.page')}</h2>
            <Image margin='40px 0 0 0' height='130px' src='assets/img/404.svg' />
        </Content>
    );
};

export default NotFound;
