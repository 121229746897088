import { ReactSVG as RSReactSvg, Props } from 'react-svg'

const ReactSVG = (props: Props) => { 

    return (
        <RSReactSvg  {...props as any} beforeInjection={(svg) => {
            // Add inline style to the SVG element.
            if (props?.color) svg.setAttribute('color', `${props.color}`)
            if (props?.width) svg.setAttribute('width', `${props.width}`)
            if (props?.height) svg.setAttribute('height', `${props.height}`)
            if (props?.width) svg.setAttribute('style', `width: ${props.width}`)
            if (props?.height) svg.setAttribute('style', `height: ${props.height}`)
          }} />
    )

}

export default ReactSVG
