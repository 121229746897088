/**
 *
 * @param title novo título da página
 * @param override para sobrescrever o titulo padrão da página, padrão é `${defaultTitle} - ${title}`
 * @returns
 */
export const setPageTitle = (defaultTitle: string, newTitle?: string, override?: boolean) => {
    if (override && newTitle) {
        document.title = newTitle;
        return;
    }

    if (newTitle) {
        document.title = `${defaultTitle} - ${newTitle}`;
    } else {
        document.title = defaultTitle;
    }
};

export const setDefaultFavicon = (url: string) => {
    try {
        const favicon: any = document.querySelector('link[rel="icon"]');
        favicon.setAttribute('href', url);
        // eslint-disable-next-line no-empty
    } catch (error) { }
};
