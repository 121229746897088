import { Modal, ModalPosition } from '@licitar/ui-kit';
import { FC, useEffect, useState } from 'react';
import { pad } from '../../../../helpers/pad';
import ApiService from '../../../../services/api';
import Header from '../../../Common/Header';
import Bid from '../Bid';
import { BidHistoryModalProps } from './props';
import styled from 'styled-components';
import { t } from 'i18next';
import Wrapper from '../../../Common/wrapper';

const CustomModal = styled(Modal)`
    .modal-content {
        padding: 3px;
        overflow-y: auto;
        height: 100%;

        ::-webkit-scrollbar {
        width: 6px;
        }

        ::-webkit-scrollbar-track {
            background: #e8e8e8;
        }

        ::-webkit-scrollbar-thumb {
            background: ${({theme}) => theme.colors.darkGray};
        }

        ::-webkit-scrollbar-thumb:hover {
            background: ${({theme}) => theme.colors.darkGray};
        }

        scrollbar-width: thin;
        scrollbar-color: ${({theme}) => theme.colors.darkGray};
        }
`;

export interface BidHistory {
    typeBid: number;
    tab: 'negotiation';
    value: number;
    createdAt: string;
    quotaId: any;
    providerAuctionCode: number;
    id: number;
    declinesCount: number;
}

const BidHistoryModal: FC<BidHistoryModalProps> = ({
    onClose,
    auctionNoticeLotSelected,
    auctionNotice,
}) => {
    const [bids, setBids] = useState<BidHistory[]>([]);

    useEffect((): void => {
        getBidHistory();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auctionNoticeLotSelected]);

    const getBidHistory = async () => {
        const bids = await ApiService.getBidHistory({
            auctionLotId: auctionNoticeLotSelected.id,
            auctionNoticeId: auctionNotice.id,
        });

        setBids(bids);
    };

    const groupedBids = bids.reduce((acc: any, curr: BidHistory) => {
        const key = curr.tab;

        if (!acc[key]) {
            acc[key] = [];
        }

        acc[key].push(curr);

        return acc;
    }, {});

    const typeBidToLabel = (tab: string): string => {
        switch (tab) {
            case 'negotiation':
                return t('info.history-bids.negotiation');

            case 'bid':
                return t('info.history-bids.bid');

            case 'close_bid':
                return t('info.history-bids.closed');

            default:
                return '--';
        }
    };

    const getBidsRender = () => {
        if (!bids.length) {
            return (
                <Wrapper margin='30px 0' flexBox justifyContent='center'>
                    {t('info.empty.bid.list')}
                </Wrapper>
            );
        }

        return Object.keys(groupedBids).map((key: string) => (
            <Wrapper key={key} padding={'0 0 20% 0'}>
                <Header
                    showMinimize={false}
                    icon='price'
                    onExpand={() => {}}
                    expandDirection='rotate(-90deg)'
                    title={typeBidToLabel(key)}
                />
                {groupedBids[key].map((bid: BidHistory) => (
                    <Bid
                        key={bid.id}
                        bid={bid}
                        auctionNotice={auctionNotice}
                        deleted={bid.typeBid === 3}
                    />
                ))}
            </Wrapper>
        ));
    };

    return (
        <CustomModal
            position={ModalPosition.fullRight}
            opened
            title={`Lances LOTE ${pad(auctionNoticeLotSelected.item)}`}
            onClickOutSide={onClose}
            width='320px'
            style={{
                margin: 0,
                padding: 0,
            }}
        >
            {getBidsRender()}
        </CustomModal>
    );
};

export default BidHistoryModal;
