import styled from 'styled-components';
import { MdRefresh } from 'react-icons/md';
import Wrapper from '../../../Common/wrapper';

const Wrapped = styled(Wrapper)`
    :not(:last-child) {
        border-bottom: 1px solid #d7d7d7;
    }
`;

const ResetIcon = styled(MdRefresh)`
    width: 18px;
    height: 18px;
    cursor: pointer;
    color: #555;
`;

const LinkButton = styled(Wrapper)<{ loading: boolean }>`
    font-size: 12px;
    margin-right: 8px;
    opacity: ${({ loading }) => (loading ? 0.5 : 1)};
    color: #317ec5;
    cursor: ${({ loading }) => (loading ? 'progress' : 'pointer')};

    &:hover {
        text-decoration: ${({ loading }) => (loading ? '' : 'underline')};
    }
`;

export { Wrapped, ResetIcon, LinkButton };
