import moment from 'moment';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { formatValueWithAuctionRules } from '../../../../helpers/currencyTransform';
import { pad } from '../../../../helpers/pad';
import { AuctionNotice } from '../../../../Interfaces/auctionNotice';
import Badge from '../../../Common/Badge';
import Wrapper from '../../../Common/wrapper';
import { BidHistory } from '../BidHistoryModal';

const Wrapped = styled(Wrapper)`
    border-bottom: 1px solid #d7d7d7;
`;

interface BidProps {
    bid: BidHistory;
    auctionNotice: AuctionNotice;
    deleted: boolean;
}

const Bid: FC<BidProps> = ({ auctionNotice, bid, deleted }) => {
    const { t } = useTranslation();

    const { formatted } = formatValueWithAuctionRules(
        bid.value,
        auctionNotice.judgmentCriterion,
        auctionNotice.rateTypeBid,
        auctionNotice.decimalPlaces
    );

    const getTextStyles = () => {
        if (deleted) {
            return {
                color: '#e01919',
                textDecoration: 'line-through',
            };
        }

        if (bid.declinesCount > 0) {
            return {
                color: '#d48f10',
                textDecoration: 'line-through',
            };
        }
    };

    const getInfo = () => {
        if (deleted) {
            return t('info.deleted.bid');
        }

        if (bid.declinesCount > 0) {
            return t('info.declined.bid');
        }
    };

    const bidDate = moment(bid.createdAt);

    return (
        <Wrapped
            flexBox
            width='100%'
            height='45px'
            padding='6px 15px'
            margin='4px 0'
            overflowY='hidden'
        >
            <Wrapper justifyContent='space-between' width='100%' alignItems='center' flexBox>
                <Wrapper flexBox>
                    <Wrapper alignItems='center' flexBox>
                        {bid.providerAuctionCode && (
                            <Badge
                                title={`${t('info.provider.bid')} ${pad(
                                    String(bid.providerAuctionCode)
                                )}`}
                                color='var(--platform-primary-color)'
                                width='24px'
                                margin='0 3px'
                            >
                                {`F${pad(String(bid.providerAuctionCode))}`}
                            </Badge>
                        )}
                    </Wrapper>
                    <Wrapper margin='0 0 0 15px' style={getTextStyles()} title={getInfo()}>
                        <Wrapper fontSize='11px'>
                            {bidDate.isValid() ? bidDate.format('DD/MM/YYYY') : '--'}
                        </Wrapper>
                        <Wrapper fontSize='13px'>
                            {bidDate.isValid() ? bidDate.format('HH:mm:ss') : '--'}
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
                <Wrapper alignItems='center' flexBox>
                    <Wrapper truncate fontSize='13px' margin=' 0 0 0 5px' style={getTextStyles()}>
                        {formatted}
                    </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapped>
    );
};

export default Bid;
