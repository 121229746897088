interface CustomFields {
    currentAction?: ProviderValueAction;
    isAuthProviderBid: boolean;
}

export interface ProviderValue extends CustomFields {
    id: number;
    lotId: number;
    value: string;
    originalValue: string;
    itsSimpleCompany: number;
    itsRegionalCompany: number;
    status: ProviderValueStatus;
    statusUpdatedAt: string;
    createdAt: string;
    providerAuctionCode: number;
    originValue: ProviderValueOriginValue;
    repeatTimeCloseBid?: number;
    multipleWinnerStatus?: ProviderMultipleWinnerStatus;
    proposal?: {
        id?: number;
        additionalExpense?: number;
        valueBidProposal?: number;
    }
}

export enum ProviderValueAction {
    show_convoke = 'show_convoke',
    show_not_covered = 'show_not_covered',
    show_convoked = 'show_convoked',
    show_winner = 'show_winner',
    show_negotiating = 'show_negotiating',
    show_unclassified = 'show_unclassified',
    show_disabled = 'show_disabled',
}

export enum ProviderValueStatus {
    not_callable = 'not_callable',
    callable = 'callable',
    called = 'called',
    not_covered = 'not_covered',
    covered = 'covered',
    re_covered = 're_covered',
    winner = 'winner',
    winner_unclassified = 'winner_unclassified',
    winner_disabled = 'winner_disabled',
    tied = 'tied',
    provider_declined = 'provider_declined',
}

export enum ProviderValueOriginValue {
    close_bid = 'close_bid',
    bid = 'bid',
    proposal = 'proposal',
}

export enum ProviderMultipleWinnerStatus {
    not_callable = 'not_callable',
    called = 'called',
    ignored = 'ignored',
    covered = 'covered',
    rejected = 'rejected',
}
