import { FC } from 'react';
import Wrapper from '../../../../Common/wrapper';
import { ParticipantsProps } from './props';
import { pad } from '../../../../../helpers/pad';
import ReactSVG from 'Components/ReactSVG';

const Participants: FC<ParticipantsProps> = ({ auctionLot }) => {
    return (
        <Wrapper flexBox>
            <Wrapper margin='0 5px 0 0'>
                <ReactSVG
                    color='var(--platform-primary-color)'
                    src='../../assets/icons/online.svg'
                    width='16px'
                    height='16px'
                />
            </Wrapper>
            <Wrapper alignItems='center' flexBox>
                <Wrapper color='var(--platform-secondary-color)' fontWeight='700' margin='0 3px 0 0' fontSize='14px'>
                    {auctionLot.proposalCount ? pad(auctionLot.proposalCount) : '--'}
                </Wrapper>
                <Wrapper fontWeight='300' fontSize='13px' color='var(--platform-secondary-color)'>
                    Part.
                </Wrapper>
            </Wrapper>
        </Wrapper>
    );
};

export default Participants;
