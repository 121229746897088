interface MultipleWinners {
    id: number;
    position: number;
    auctionId: number;
    providerId: number;
    lotId: number;
    percent: string;
    createdAt: Date;
    updatedAt: Date | null;
    participate?: {
        providerAuctionCode?: number;
    };
    status: MultipleWinnersStatus;
}

enum MultipleWinnersStatus {
    approved = 'approved',
    rejected = 'rejected',
    pending = 'pending',
}

interface MultipleWinnersToCover {
    multipleWinner: MultipleWinners;
    valueToCover: number;
    lotItem: string;
}

export type { MultipleWinners, MultipleWinnersToCover };
export { MultipleWinnersStatus };
