import { useEffect } from 'react';

type WindowEvent = any;

const UseWindowEvent = (
    event: WindowEvent | string,
    callback: (params?: any) => any,
    dependencies: any[]
) => {
    useEffect(() => {
        window.addEventListener(event, callback);
        return () => window.removeEventListener(event, callback);
    }, [event, callback, ...dependencies]);
};

const dispatchWindowEvent = (event: WindowEvent, data: any) => {
    window.dispatchEvent(new CustomEvent(event, { detail: { ...data } }));
};

export { UseWindowEvent, dispatchWindowEvent };
export type { WindowEvent };
