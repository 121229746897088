import { FC, useEffect, useMemo } from 'react';
import { ClockProps } from './props';
import Wrapper from '../wrapper';
import { currMoment } from '../../../helpers/moment';
import Image from '../Image';
import * as workerTimers from 'worker-timers';
import { useTranslation } from 'react-i18next';

const Clock: FC<ClockProps> = ({ serverTimestamp }) => {
    const { t } = useTranslation();

    const initTimer = () => {
        try {
            const timer = document.getElementById('timer');
            workerTimers.setInterval(
                () =>
                    timer &&
                    (timer.innerHTML = currMoment(serverTimestamp.difference).format('LTS')),
                1000
            );
        } catch (error) {}
    };

    const timer = useMemo(() => <Wrapper id='timer' />, []);

    useEffect(() => {
        initTimer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Wrapper flexBox minWidth='160px' width='160px'>
            <Wrapper flexBox alignItems='center' margin='0 20px 0 0'>
                <Image src='../../assets/icons/clock.svg' width='34px' height='34px' />
            </Wrapper>
            <Wrapper>
                <Wrapper fontSize='11px' fontWeight='300'>
                    {currMoment(serverTimestamp.difference).format('L')}
                </Wrapper>
                <Wrapper fontSize='16px' color='#FFF'>
                    {timer}
                </Wrapper>
                <Wrapper fontSize='11px'>({t('info.clock.location')})</Wrapper>
            </Wrapper>
        </Wrapper>
    );
};

export default Clock;
