import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { auctioneerPercentDiff, getPercentOptions } from '../../../../../helpers/difference';
import { AuctionNotice } from '../../../../../Interfaces/auctionNotice';
import { AuctionNoticeLot } from '../../../../../Interfaces/auctionNoticeLot';
import Wrapper from '../../../../Common/wrapper';
import ReactSVG from 'Components/ReactSVG';

interface ValueReferenceDiffProps {
    auctionLot: AuctionNoticeLot;
    auctionNotice: AuctionNotice;
}

const ValueReferenceDiff: FC<ValueReferenceDiffProps> = ({ auctionLot, auctionNotice }) => {
    const { t } = useTranslation();

    const getReferenceValueDiff = useCallback(() => {
        return auctioneerPercentDiff(
            String(auctionLot.valueReference),
            String(auctionLot.bestBid),
            auctionNotice.judgmentCriterion,
            auctionNotice.rateTypeBid === 1
        );
    }, [
        auctionLot.bestBid,
        auctionLot.valueReference,
        auctionNotice.judgmentCriterion,
        auctionNotice.rateTypeBid,
    ]);

    const getReferencePercentOptions = useCallback(
        (percent?: number) => {
            return getPercentOptions(
                percent,
                auctionNotice.judgmentCriterion,
                auctionNotice.rateTypeBid === 1
            );
        },
        [auctionNotice.judgmentCriterion, auctionNotice.rateTypeBid]
    );

    const diff = getReferenceValueDiff();
    const options = getReferencePercentOptions(
        !auctionLot.bestBid || !auctionLot.valueReference ? undefined : diff
    );

    const percent = Math.abs(diff ?? 0)?.toFixed(2);

    return (
        <Wrapper>
            {diff !== undefined &&
            auctionLot.bestBid !== undefined &&
            auctionLot.valueReference !== undefined ? (
                <Wrapper
                    fontSize='13px'
                    color={options.color}
                    title={
                        options.icon &&
                        t(
                            options.icon === 'arrowUp'
                                ? 'info.reference-bigger-diff'
                                : 'info.reference-lower-diff',
                            {
                                value: percent + '%',
                            }
                        )
                    }
                >
                    <Wrapper flexBox alignItems='center'>
                        {options.icon ? (
                            <ReactSVG
                                color='var(--platform-primary-color)'
                                src={`../../assets/icons/${options.icon}.svg`}
                                width='11px'
                                height='13px'
                            />
                        ) : (
                            ''
                        )}
                        <Wrapper flexBox alignItems='center' margin='0 0 0 5px'>
                            <b>{percent}%</b>
                        </Wrapper>
                    </Wrapper>
                </Wrapper>
            ) : null}
        </Wrapper>
    );
};

export default ValueReferenceDiff;
