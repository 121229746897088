import { FC } from 'react';
import { PageHeaderProps } from './props';
import { onFocusChange } from 'helpers/focused';
import moment from 'moment';
import { usePlatformContext } from '../../../context/platform.context';
import DefaultHeader from './components/default-header';
import MobileHeader from './components/mobile-header';
import { isMobile } from '../../../helpers/mobile';

const PageHeader: FC<PageHeaderProps> = ({ auctionNotice, serverTimestamp }) => {
    let unfocusedAt: number | null = null;

    if (process.env.NODE_ENV === 'production') {
        onFocusChange((focused) => {
            if (!focused) {
                unfocusedAt = moment().valueOf();
                return;
            }

            const totalUnfocused = moment.duration(moment().diff(unfocusedAt)).asSeconds();
            // aba é recarregada se ficar com janela desfocada por mais de 2min
            if (totalUnfocused > 120) {
                return window.location.reload();
            }
            unfocusedAt = null;
        });
    }

    const { platform } = usePlatformContext();

    return isMobile() ? (
        <MobileHeader
            auctionNotice={auctionNotice}
            serverTimestamp={serverTimestamp}
            platform={platform}
        />
    ) : (
        <DefaultHeader
            auctionNotice={auctionNotice}
            serverTimestamp={serverTimestamp}
            platform={platform}
        />
    );
};

export default PageHeader;
