import i18n from 'i18n/config';

export const getAuctionTypeDescription = (type: string, defaultStr: string = '--'): string => {
    const obj = {
        E: i18n.t('term.trading'),
        D: i18n.t('term.waiver'),
        C: i18n.t('term.accreditation'),
        I: i18n.t('term.unenforceability'),
        R: i18n.t('term.concurrence'),
        U: i18n.t('term.contest'),
        L: i18n.t('term.auction'),
        T: 'Dialogo competitivo',
        Q: 'Pré qualificação',
        M: 'Procedimento manifestação de interesse',
    };

    return obj?.[type] || defaultStr;
};
