import { AuctionNoticeLot } from '../../Interfaces/auctionNoticeLot';
import { AuctionNotice } from '../../Interfaces/auctionNotice';
import { methodDisputeDoAction } from '../../helpers/methodDispute';
import ApiService from '../../services/api';
import { StageLot } from '../../Interfaces/stageLot';

const defaultBid = ({ auctionLot, bidValue }: BidTypeProps) => {
    return {
        bid: (err: Function) =>
            ApiService.bid(
                auctionLot.auctionNoticeId,
                {
                    lotId: auctionLot.id,
                    value: bidValue,
                },
                err
            ),
    };
};

const coverCloseBid = ({ auctionLot, bidValue }: BidTypeProps) => {
    return {
        bid: (err: Function) =>
            ApiService.closeCover(
                auctionLot.auctionNoticeId,
                {
                    lotId: auctionLot.id,
                    value: bidValue,
                },
                err
            ),
    };
};

const convokedBid = ({ auctionLot, bidValue }: BidTypeProps) => {
    return {
        bid: (err: Function) =>
            ApiService.cover(
                auctionLot.auctionNoticeId,
                {
                    lotId: auctionLot.id,
                    value: bidValue,
                },
                err
            ),
    };
};

const negotiationBid = ({ auctionLot, bidValue }: BidTypeProps) => {
    return {
        bid: (err: Function) =>
            ApiService.updateProviderValue(
                auctionLot.auctionNoticeId,
                auctionLot.id,
                {
                    providerValueId: auctionLot.winnerProvider?.id,
                    value: bidValue,
                },
                err
            ),
    };
};

const openMode = (props: BidTypeProps) => {
    switch (props.auctionLot.stage) {
        case StageLot.convoked:
            return convokedBid(props);

        case StageLot.negotiation:
            return negotiationBid(props);

        default:
            return defaultBid(props);
    }
};

const CloseMode = (props: BidTypeProps) => {
    switch (props.auctionLot.stage) {
        case StageLot.close_period:
        case StageLot.second_close_period:
        case StageLot.repeat_second_close_period:
            return coverCloseBid(props);

        case StageLot.negotiation:
            return negotiationBid(props);

        case StageLot.convoked:
            return convokedBid(props);

        default:
            return defaultBid(props);
    }
};

interface BidTypeProps {
    auctionNotice: AuctionNotice;
    auctionLot: AuctionNoticeLot;
    bidValue: number;
}

const doBidType = (props: BidTypeProps) => {
    const { auctionNotice } = props;

    const action = methodDisputeDoAction({
        methodDispute: auctionNotice.methodDispute,
        open: () => openMode(props),
        closed: () => CloseMode(props),
    });

    if (!action) return;
    return action();
};

export { doBidType };
