import React, { FC, useState, ReactElement } from 'react';
import Popover from 'react-tiny-popover';
import { FrustrateForm } from './components/FrustrateForm';

export interface FrustratePopoverProps {
    onConfirm: (values: { message: string; reasonFrustratedId?: number }) => void;
    render: any;
    opened?: boolean;
    maxWidth: string;
    disabled?: boolean;
}

const FrustratePopover: FC<FrustratePopoverProps> = ({
    children,
    onConfirm,
    render,
    opened,
    maxWidth,
    disabled,
}) => {
    const [modalOpened, setOpenModal] = useState<boolean>(opened || false);

    const cloneChildren = React.cloneElement(children as ReactElement<any>, {
        onClick: (e: any) => !disabled && handleClick(e),
    });

    const handleClick = (e: any) => {
        e.stopPropagation();
        setOpenModal(!modalOpened);
    };

    const handleClose = (e: any) => {
        e.stopPropagation();
        setOpenModal(false);
    };

    return (
        <Popover
            isOpen={modalOpened}
            position={['bottom', 'right', 'left']}
            content={() => (
                <FrustrateForm
                    maxWidth={maxWidth}
                    onSubmit={onConfirm}
                    render={render}
                    onClose={handleClose}
                    onClickOutside={handleClick}
                />
            )}
        >
            {cloneChildren}
        </Popover>
    );
};

export default FrustratePopover;
