export interface CloseBid {
    id: number;
    lotId: number;
    value: number;
    status: ClosedBidStatus;
    providerAuctionCode: number;
}

export enum ClosedBidStatus {
    called = 'called',
    callable = 'callable',
    covered = 'covered',
}
