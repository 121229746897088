import { FC } from 'react';
import Wrapper from '../../../Common/wrapper';
import { BestProviderValueProps } from './props';
import { formatValueWithAuctionRules } from '../../../../helpers/currencyTransform';
import { ProviderValue } from '../../../../Interfaces/providerValues';
import { ProviderValueStatus } from '../../../../Interfaces/providerValues';

const BestProviderValue: FC<BestProviderValueProps> = ({ auction, providerValues }) => {
    const winner = providerValues.find((pValue) => pValue.status === ProviderValueStatus.winner);

    const { formatted } = formatValueWithAuctionRules(
        (winner as ProviderValue)?.value,
        auction.judgmentCriterion,
        auction.rateTypeBid,
        auction.decimalPlaces
    );

    return (
        <Wrapper
            height='30px'
            display='flex'
            justifyContent='space-between'
            padding='0 15px'
            alignItems='center'
        >
            <span style={{ fontWeight: 600 }}>Melhor valor</span>
            {winner ? (
                <span
                    style={{
                        fontWeight: 600,
                        color: 'var(--platform-secondary-color)',
                    }}
                >
                    {formatted}
                </span>
            ) : (
                <span>{'--'}</span>
            )}
        </Wrapper>
    );
};

export default BestProviderValue;
