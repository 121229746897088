import { useTranslation } from 'react-i18next';
import {
    ActionButtonsWrapper,
    Card,
    ContentWrapper,
    ErrorWrapper,
    InputsContainer,
    SendButton,
} from './styles';
import React from 'react';

import { Button, Input, Select } from 'antd';
import { ClickAwayListener } from '@material-ui/core';
import { useFrustrateForm } from './hook';

interface FrustrateFormProps {
    render: React.ReactNode;
    maxWidth: string;
    onClose: (ev: any) => void;
    onClickOutside: (ev: any) => void;
    onSubmit: (values: { message: string; reasonFrustratedId?: number }) => void;
}

export const FrustrateForm: React.FC<FrustrateFormProps> = ({
    render,
    onSubmit,
    maxWidth,
    onClose,
    onClickOutside,
}) => {
    const { t } = useTranslation();

    const { form, handleOnChange, handleOnBlur, options, optionsIsLoading } = useFrustrateForm();

    return (
        <ClickAwayListener onClickAway={onClickOutside}>
            <form>
                <Card maxWidth={maxWidth}>
                    <ContentWrapper>{render}</ContentWrapper>
                    <InputsContainer>
                        <Select
                            dropdownStyle={{ zIndex: 9999 }}
                            placeholder={t('info.modal.reason-frustrated.placeholder.info')}
                            status={form.error.reasonFrustratedId ? 'error' : undefined}
                            loading={optionsIsLoading}
                            options={options}
                            onBlur={() => handleOnBlur('reasonFrustratedId')}
                            onChange={(value) => handleOnChange('reasonFrustratedId', value)}
                        />
                        {form.error.reasonFrustratedId && (
                            <ErrorWrapper>
                                {t('info.modal.reason-frustrated.validation.info')}
                            </ErrorWrapper>
                        )}
                        <Input.TextArea
                            rows={3}
                            autoFocus
                            placeholder={t('info.modal.message.placeholder.info')}
                            status={form.error.message ? 'error' : undefined}
                            onBlur={() => handleOnBlur('message')}
                            onChange={(e) =>
                                handleOnChange('message', e?.currentTarget?.value?.trim() ?? '')
                            }
                        />
                        {form.error.message && (
                            <ErrorWrapper>{t('info.modal.message.validation.info')}</ErrorWrapper>
                        )}
                    </InputsContainer>
                    <ActionButtonsWrapper>
                        <Button onClick={onClose}>{t('term.close')}</Button>
                        <SendButton disabled={form.hasErrors} onClick={() => onSubmit(form.values)}>
                            {t('term.send')}
                        </SendButton>
                    </ActionButtonsWrapper>
                </Card>
            </form>
        </ClickAwayListener>
    );
};
