import styled from 'styled-components';
import Wrapper from '../Common/wrapper';
import { Button } from 'antd';
import { ColorType, getColor } from '../Common/theme';
import { CurrencyInput } from '@licitar/ui-kit';

const Loader = styled(Wrapper)`
    background: url(../../assets/icons/loader2.svg) no-repeat center;
    background-size: contain;
`;

const BidInput = styled(CurrencyInput)`
    padding: 0 5px;
    height: 38px;
    border-radius: 5px 0 0 5px;
    color: #666;
    font-size: 15px;
    border: 1px #d0d0d0 solid;
    max-width: 140px;

    &:disabled {
        background: #fafafa;
    }
`;

const SendButton = styled(Button)`
    background: ${({ theme }) => theme.colors.primary} !important;
    color: #fff !important;
    height: 38px !important;
    border: 0 !important;
    padding: 0 6px !important;
    border-radius: 0px 4px 4px 0px !important;

    span {
        color: #fff;
        font-size: 14px;
    }
`;

export { Loader, BidInput, SendButton };
