import { FC, useState, useEffect } from 'react';
import { BidProps } from './props';
import Wrapper from '../Common/wrapper';
import ConfirmationPopover from '../Common/ConfirmationPopover';
import { currencyFormat, formatValueWithAuctionRules } from '../../helpers/currencyTransform';
import { doBidType } from './bidService';
import { addNotification } from '../../helpers/notification';
import { Loader, SendButton, BidInput } from './styled';
import { useTranslation } from 'react-i18next';

const CreateBid: FC<BidProps> = ({ auctionLot, activeBids, auctionNotice, selected }) => {
    const [bidValue, setBid] = useState<number | undefined>(undefined);
    const [loadingBid, setLoadingBid] = useState<boolean>(false);
    const { t } = useTranslation();

    const bid = async () => {
        if (bidValue === undefined) {
            return;
        }

        const bidType = doBidType({
            auctionNotice,
            auctionLot,
            bidValue,
        });

        setLoadingBid(true);

        let error: any = undefined;

        await bidType.bid((err: any) => {
            error = err;
        });

        setLoadingBid(false);
        setBid(undefined);

        if (!error) {
            return addNotification(t('term.success'), t('info.registered.bid'), 'success', 3000);
        }
    };

    useEffect(() => {
        if (!selected) return;
        const inputBid = document.getElementById(`createBid:${auctionLot.id}`);
        inputBid?.focus();
    }, [auctionLot, selected]);

    return (
        <Wrapper flexBox position='relative'>
            {loadingBid && (
                <Loader
                    title={t('info.sending.bid')}
                    position='absolute'
                    top='0'
                    right='0'
                    left='0'
                    bottom='0'
                    flexBox
                    justifyContent='center'
                />
            )}
            <Wrapper flexBox opacity={loadingBid ? '0.7' : '1'}>
                <Wrapper>
                    {(auctionNotice.judgmentCriterion === 1 && auctionNotice.rateTypeBid === 1) ||
                    auctionNotice.judgmentCriterion === 2 ? (
                        <BidInput
                            allowNegative={auctionNotice.rateTypeBid === 1}
                            value={bidValue ?? 0}
                            prefix='% '
                            id={`createBid:${auctionLot.id}`}
                            disabled={!activeBids || loadingBid}
                            className='inputBid'
                            decimalSeparator=','
                            precision={auctionNotice.decimalPlaces}
                            thousandSeparator='.'
                            onChangeEvent={(__: any, _: any, floatValue: any) => setBid(floatValue)}
                        />
                    ) : (
                        <BidInput
                            allowNegative={auctionNotice.rateTypeBid === 1}
                            value={bidValue ?? 0}
                            prefix={`${currencyFormat} `}
                            decimalSeparator=','
                            thousandSeparator='.'
                            id={`createBid:${auctionLot.id}`}
                            disabled={!activeBids || loadingBid}
                            className='inputBid'
                            precision={auctionNotice.decimalPlaces}
                            onChangeEvent={(__: any, _: any, floatValue: any) => setBid(floatValue)}
                        />
                    )}
                </Wrapper>
                <Wrapper>
                    <ConfirmationPopover
                        key={`confirmationBid:${auctionLot.id}`}
                        render={
                            <>
                                <Wrapper fontSize='13px'>
                                    <p>
                                        {t('info.confirm.send.bid.message')}{' '}
                                        <b>{`${
                                            formatValueWithAuctionRules(
                                                bidValue,
                                                auctionNotice.judgmentCriterion,
                                                auctionNotice.rateTypeBid,
                                                auctionNotice.decimalPlaces
                                            ).formatted
                                        }`}</b>
                                        .
                                    </p>
                                    <p>{t('info.confirm.send.bid')}</p>
                                </Wrapper>
                            </>
                        }
                        maxWidth='220px'
                        disabled={bidValue === undefined || loadingBid}
                        onConfirm={bid}
                    >
                        <SendButton disabled={!activeBids || loadingBid} size='large'>
                            <span>{t('info.send')}</span>
                        </SendButton>
                    </ConfirmationPopover>
                </Wrapper>
            </Wrapper>
        </Wrapper>
    );
};

export default CreateBid;
