import { CONSTANTS } from '../constants';

export const getDataFromAuthHash = (
    hashId?: string
): {
    token: string;
    auctionId: string;
    service: string;
} | null => {
    const { AUTH } = CONSTANTS.storageMap;

    if (!hashId) {
        getDataFromPath();
    }

    const data = localStorage.getItem(`${AUTH}:${hashId}`);

    if (!data) {
        return null;
    }

    const obj = JSON.parse(data);

    return {
        token: obj.token,
        auctionId: obj.auction,
        service: obj.service,
    };
};

export const getDataFromPath = () => {
    const parts = window.location?.pathname.split('/');
    return (parts?.[2] as string) || null;
};
