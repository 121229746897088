import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'antd';
import Badge from 'Components/Common/Badge';
import { pad } from 'helpers/pad';
import { Item, Title } from '../../styled';
import ApiService from 'services/api';
import { ToggleRequestAdditionalDocumentsProps } from './props';

const ToggleRequestAdditionalDocuments: FC<ToggleRequestAdditionalDocumentsProps> = ({ proposalId, providerAuctionCode, requestedAdditionalDocumentsProp }) => {
  const { t } = useTranslation();
  const [loadingSwitch, setLoadingSwitch] = useState(false);

  const [requestedAdditionalDocuments, setRequestedAdditionalDocuments] = useState(
    requestedAdditionalDocumentsProp ?? false
  );
  
  useEffect(() => {
    setRequestedAdditionalDocuments(requestedAdditionalDocumentsProp)
  }, [requestedAdditionalDocumentsProp])

  const handleToggleSwitch = async (proposalId: number, requested: boolean) => {
    try {
      if (proposalId) {
        setLoadingSwitch(true);
        const data = await ApiService.toggleRequestAdditionalDocuments(Number(proposalId), { requested }, () => {});
        if (data?.status === 'success') setRequestedAdditionalDocuments((state) => !state);
      }
    } finally {
      setLoadingSwitch(false);
    }
  };

  return (
    <Item style={{ marginBottom: '12px '}}>
      <Title>
        <Badge
          title={`${t('term.provider')} ${pad(providerAuctionCode)}`}
          color='var(--platform-primary-color)'
          width='24px'
          margin='0 3px'
        >
          {`F${pad(providerAuctionCode)}`}
        </Badge>
        <span>{t('info.additional-documents')}</span>
      </Title>
      <Switch
        loading={loadingSwitch}
        disabled={loadingSwitch}
        checked={requestedAdditionalDocuments}
        onChange={() => handleToggleSwitch(proposalId, !requestedAdditionalDocuments)}
      />
    </Item>
  );
};

export default ToggleRequestAdditionalDocuments;
