import { FC } from 'react';
import styled from 'styled-components';
import Image from '../../Components/Common/Image';
import { useTranslation } from 'react-i18next';

const Content = styled.div`
    display: flex;
    justify-content: center;
    margin: 10% 0;
    flex-direction: column;
    align-items: center;
`;
export interface PermissionRequiredProps {}

const PermissionRequired: FC<PermissionRequiredProps> = () => {
    const { t } = useTranslation();

    return (
        <Content>
            <h2>{t('invalid.session')}</h2>
            <h4>{t('invalid.session.message')}</h4>
            <Image margin='40px 0 0 0' height='130px' src='../../assets/img/fingerprint.svg' />
        </Content>
    );
};

export default PermissionRequired;
