import { FC, useEffect, useState } from 'react';
import { SendMessageProps } from './props';
import Wrapper from '../Common/wrapper';
import ApiService from '../../services/api';
import { TextAreaScroll, TotalChars } from './styled';
import { useTranslation } from 'react-i18next';
import ChatFavoriteMessages from 'Components/Chat/components/chatFavoriteMessage';

export const MESSAGE_LENGTH_LIMIT = 1000;

const SendMessage: FC<SendMessageProps> = ({ auctionNoticeId, favoriteMessage, selectFavoriteMessage, isAuctioneer }) => {
    const [text, setText] = useState<string | undefined>(undefined);
    const { t } = useTranslation();

    useEffect(() => {
        setText(favoriteMessage)
    }, [favoriteMessage])

    const handleSend = async () => {
        if (!text || text.trim() === '') return;

        const created = await ApiService.createMessage(auctionNoticeId, { message: text });
        if (created) {
            setText(undefined);
        }
    };

    const handleSetText = (text: string) => {
        if (text?.length > MESSAGE_LENGTH_LIMIT) {
            return setText(text.slice(0, MESSAGE_LENGTH_LIMIT));
        }
        return setText(text);
    };

    return (
        <Wrapper
            borderTop='1px solid #D7D7D7'
            height='110px'
            flexDirection='column'
            flexBox
            bgcolor='#efefef'
        >
            {
                isAuctioneer && <ChatFavoriteMessages selectFavoriteMessage={selectFavoriteMessage}/>
            }
            <Wrapper flex position='relative' padding='5px 10px 0 10px'>
                <TextAreaScroll
                    rows={3}
                    value={text}
                    onChange={(ev) => handleSetText(ev.target.value)}
                    style={{
                        width: '100%',
                        borderRadius: '5px',
                        padding: '8px 30px 8px 8px ',
                        fontSize: '13px',
                        resize: 'none',
                        maxHeight: '78px',
                    }}
                    onKeyPress={(ev) => {
                        if (ev.key === 'Enter' && !ev.shiftKey) {
                            ev.preventDefault();
                            handleSend();
                        }
                    }}
                    maxLength={MESSAGE_LENGTH_LIMIT}
                    placeholder={`${t('info.chat.send.message')}..`}
                />
                {text?.length ? (
                    <img
                        src={`../../assets/icons/send1.svg`}
                        style={{
                            position: 'absolute',
                            width: '20px',
                            height: '20px',
                            top: '38px',
                            right: '17px',
                            cursor: 'pointer',
                        }}
                        title={t('info.send')}
                        alt='Chat'
                        onClick={handleSend}
                    />
                ) : null}
            </Wrapper>
            <TotalChars>
                <span>{`${text?.length ?? 0}/${MESSAGE_LENGTH_LIMIT} ${t(
                    'info.characters'
                )}`}</span>
            </TotalChars>
        </Wrapper>
    );
};

export default SendMessage;
