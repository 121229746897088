import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Wrapper from '../Common/wrapper';
import { ErrorMessageProps } from './props';

const ErrorMessage: FC<ErrorMessageProps> = ({ message, description }) => {
    const { t } = useTranslation();

    return (
        <Wrapper height='100vh'>
            <Wrapper padding='80px 0' flexBox fontSize='22px' color='#555' justifyContent='center'>
                {message}
            </Wrapper>
            <Wrapper fontSize='19px' textAlign='center' margin='10px 0'>
                {description || t('error.contact.support')}
            </Wrapper>
        </Wrapper>
    );
};

export default ErrorMessage;
