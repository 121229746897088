import { BiBlock } from 'react-icons/bi';
import styled from 'styled-components';

const DisableIcon = styled(BiBlock)`
    cursor: pointer;
    font-size: 18px;
    color: #666;
`;

export { DisableIcon }
