import { FC, useState } from 'react';
import Wrapper from '../../../Common/wrapper';
import ApiService from '../../../../services/api';
import { formatValueWithAuctionRules } from '../../../../helpers/currencyTransform';
import { pad } from '../../../../helpers/pad';
import { Wrapped } from './styled';
import Badge from '../../../Common/Badge';
import { useTranslation } from 'react-i18next';
import { ProviderValueItemProps } from './props';
import {
    ProviderMultipleWinnerStatus,
    ProviderValueStatus,
} from '../../../../Interfaces/providerValues';
import { addNotification } from '../../../../helpers/notification';
import { MultipleWinnersStatus } from '../../../../Interfaces/multipleWinners';
import ConfirmationPopover from '../../../Common/ConfirmationPopover';
import { HiOutlineBan } from 'react-icons/hi';

const canNotCoverStatuses: ProviderValueStatus[] = [
    ProviderValueStatus.winner,
    ProviderValueStatus.provider_declined,
    ProviderValueStatus.winner_disabled,
    ProviderValueStatus.winner_unclassified,
];

const ProviderValueItem: FC<ProviderValueItemProps> = ({
    providerValue,
    auctionNotice,
    auctionLot,
    multipleWinners,
    providerValues,
    viewMode,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    const { formatted } = formatValueWithAuctionRules(
        providerValue.value,
        auctionNotice.judgmentCriterion,
        auctionNotice.rateTypeBid,
        auctionNotice.decimalPlaces
    );

    const validProviderValues = providerValues.filter(
        (pv) => !canNotCoverStatuses.includes(pv.status)
    );

    const multipleWinner = multipleWinners.find(
        (mw) => mw.participate?.providerAuctionCode === providerValue.providerAuctionCode
    );

    const callToMultipleWinnerCover = async () => {
        if (viewMode) {
            return;
        }

        let error: any;
        setIsLoading(true);

        await ApiService.callToMultipleWinnerCover(
            auctionNotice.id,
            auctionLot.id,
            providerValue.id,
            (err) => (error = err)
        );

        setIsLoading(false);

        if (!!error) {
            return addNotification(
                t('term.error'),
                'Ocorreu um erro ao chamar o fornecedor.',
                'warning',
                3000
            );
        }
    };

    const visibleConvokeButton = () => {
        if (canNotCoverStatuses.includes(providerValue.status) || viewMode) {
            return false;
        }

        // algum lance pendente de aprovação
        if (!!multipleWinners.find((mw) => mw.status === MultipleWinnersStatus.pending)) {
            return false;
        }

        // algum fornecedor convocado
        if (
            validProviderValues.find(
                (pv) => pv.multipleWinnerStatus === ProviderMultipleWinnerStatus.called
            )
        ) {
            return false;
        }

        // todos as posições ocupadas
        if (
            multipleWinners.every(
                (mw) =>
                    mw.participate?.providerAuctionCode &&
                    mw.status === MultipleWinnersStatus.approved
            )
        ) {
            return false;
        }

        const nextValidProviderValue = validProviderValues.find((pv) => !pv.multipleWinnerStatus);

        if (nextValidProviderValue?.id !== providerValue.id) {
            return false;
        }

        return true;
    };

    const getActionByStatus = () => {
        if (
            providerValue.status === ProviderValueStatus.winner &&
            multipleWinners.find((mw) => mw.position === 1)
        ) {
            return {
                title: '1° vencedor',
                bgColor: 'var(--platform-secondary-color)',
                visible: true,
            };
        } else if (
            providerValue.status === ProviderValueStatus.winner_disabled ||
            providerValue.status === ProviderValueStatus.winner_unclassified ||
            providerValue.status === ProviderValueStatus.provider_declined
        ) {
            return {
                title: 'Desabilitado',
                bgColor: '#e54040',
                visible: true,
            };
        }

        switch (providerValue.multipleWinnerStatus) {
            case ProviderMultipleWinnerStatus.rejected:
                return {
                    title: 'Recusado',
                    bgColor: '#ff511d',
                    visible: true,
                };

            case ProviderMultipleWinnerStatus.called:
                return {
                    title: 'Convocado',
                    bgColor: 'var(--platform-secondary-color)',
                    visible: true,
                };

            case ProviderMultipleWinnerStatus.covered: {
                const multipleWinner = multipleWinners.find(
                    (mw) =>
                        mw.participate?.providerAuctionCode === providerValue.providerAuctionCode
                );

                if (multipleWinner) {
                    return {
                        title: `${multipleWinner.position}° vencedor`,
                        bgColor: 'var(--platform-secondary-color)',
                        visible: true,
                    };
                }

                return {
                    title: 'Aceito',
                    bgColor: 'var(--platform-secondary-color)',
                    visible: true,
                };
            }

            case ProviderMultipleWinnerStatus.not_callable:
                return {
                    title: 'Não chamado',
                    bgColor: '#ff511d',
                    visible: true,
                };

            case ProviderMultipleWinnerStatus.ignored:
                return {
                    title: 'Ignorado',
                    bgColor: '#e4474a',
                    visible: true,
                };

            default:
                return {
                    action: () => callToMultipleWinnerCover(),
                    title: 'Convocar',
                    bgColor: 'var(--platform-secondary-color)',
                    visible: visibleConvokeButton(),
                };
        }
    };

    const updateMultipleWinnerCover = async (status: MultipleWinnersStatus) => {
        if (viewMode) {
            return;
        }

        let error: any;

        setIsLoading(true);
        await ApiService.updateMultipleWinnerCover(
            auctionNotice.id,
            auctionLot.id,
            providerValue.id,
            status,
            (err) => (error = err)
        );

        setIsLoading(false);

        if (!!error) {
            return addNotification(
                t('term.error'),
                'Ocorreu um ao atualizar os dados',
                'warning',
                3000
            );
        }
    };

    const handleClickIgnore = async () => {
        if (viewMode) {
            return;
        }

        let error: any = undefined;

        setIsLoading(true);
        const response = await ApiService.ignoreMultipleWinners(
            auctionNotice.id,
            auctionLot.id,
            providerValue.id,
            (err) => {
                error = err;
            }
        );

        setIsLoading(false);

        if (error || !response?.ok) {
            return addNotification(
                t('term.error'),
                t('Ocorreu um erro ao ignorar'),
                'warning',
                3000
            );
        }

        return addNotification(t('term.success'), t('term.success'), 'success', 3000);
    };

    const providerValueStatus = getActionByStatus();

    return (
        <Wrapped
            flexBox
            width='100%'
            height='40px'
            padding='6px 5px'
            margin='4px 0'
            overflowY='hidden'
        >
            <Wrapper justifyContent='space-between' width='100%' alignItems='center' flexBox>
                <Wrapper alignItems='center' flexBox>
                    <Wrapper alignItems='center' flexBox>
                        {providerValue.providerAuctionCode && (
                            <Badge
                                title={`${t('info.provider.bid')} ${pad(
                                    providerValue.providerAuctionCode
                                )}`}
                                color='var(--platform-primary-color)'
                                width='24px'
                                margin='0 3px'
                            >
                                {`F${pad(providerValue.providerAuctionCode)}`}
                            </Badge>
                        )}
                    </Wrapper>
                    <Wrapper truncate fontSize='13px' margin=' 0 0 0 5px'>
                        {formatted}
                    </Wrapper>
                </Wrapper>
                <Wrapper display='flex' alignItems='center'>
                    {!viewMode && multipleWinner?.status === MultipleWinnersStatus.pending && (
                        <ConfirmationPopover
                            render={
                                <>
                                    <Wrapper fontSize='13px'>
                                        <p>
                                            Aceitar a contraproposta do fornecedor{' '}
                                            <b>{pad(providerValue.providerAuctionCode)}</b> no valor
                                            de <b>{formatted}</b>?
                                        </p>
                                    </Wrapper>
                                </>
                            }
                            maxWidth='220px'
                            onConfirm={() =>
                                updateMultipleWinnerCover(MultipleWinnersStatus.approved)
                            }
                            onReject={() =>
                                updateMultipleWinnerCover(MultipleWinnersStatus.rejected)
                            }
                        >
                            <Badge
                                isLoading={isLoading}
                                pulse={!isLoading}
                                cursor='pointer'
                                padding='0 2px'
                                minWidth='50px'
                                color={'#026bae'}
                                title={providerValueStatus?.title}
                                fontSize='9px'
                            >
                                Pendente aprovação
                            </Badge>
                        </ConfirmationPopover>
                    )}
                    {!!providerValueStatus &&
                        providerValueStatus.visible &&
                        multipleWinner?.status !== MultipleWinnersStatus.pending && (
                            <>
                                {!viewMode &&
                                    providerValue.multipleWinnerStatus ===
                                        ProviderMultipleWinnerStatus.called && (
                                        <ConfirmationPopover
                                            disabled={isLoading}
                                            render={
                                                <>
                                                    <Wrapper fontSize='13px'>
                                                        <p>
                                                            Ao pular o fornecedor ele não terá mais
                                                            a oportunidade de melhorar seu valor.
                                                        </p>
                                                    </Wrapper>
                                                </>
                                            }
                                            maxWidth='220px'
                                            onConfirm={handleClickIgnore}
                                        >
                                            <HiOutlineBan
                                                style={{
                                                    fontSize: 18,
                                                    color: 'var(--platform-danger-color)',
                                                    cursor: 'pointer',
                                                    margin: '0 3px 0 0',
                                                }}
                                            />
                                        </ConfirmationPopover>
                                    )}
                                <Badge
                                    isLoading={isLoading}
                                    cursor={
                                        !!providerValueStatus?.action && !viewMode
                                            ? 'pointer'
                                            : undefined
                                    }
                                    padding='0 2px'
                                    minWidth='48px'
                                    color={providerValueStatus.bgColor || 'var(--platform-secondary-color)'}
                                    title={providerValueStatus?.title}
                                    fontSize='9px'
                                    onClick={() => {
                                        if (!viewMode) {
                                            providerValueStatus?.action?.();
                                        }
                                    }}
                                >
                                    {providerValueStatus?.title}
                                </Badge>
                            </>
                        )}
                </Wrapper>
            </Wrapper>
        </Wrapped>
    );
};

export default ProviderValueItem;
