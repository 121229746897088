import { BrowserRouter, RouteComponentProps, Switch } from 'react-router-dom';
import DefaultRoute from '../Auth/DefaultRoute';
import PrivateRoute from '../Auth/PrivateRoute';
import RedirectValidator from '../Auth/RedirectValidator';
import { PlatformContextProvider } from '../context/platform.context';
import { Initial } from '../pages/Initial';
import { LotsContextProvider } from '../pages/Initial/select-lots.context';
import NotFound from '../pages/NotFound';
import PermissionRequired from '../pages/PermissionRequired';

const Routes = () => {
    const routeList: JSX.Element[] = [
        <PrivateRoute
            path='/a/:hashId'
            key='/a/:hashId'
            exact
            component={(props: RouteComponentProps) => <Initial to='/a/:hashId' {...props} />}
        />,
        <DefaultRoute path='/redirect' key='/redirect' component={() => <RedirectValidator />} />,
        <DefaultRoute path='/404' key='/404' exact component={() => <NotFound />} />,
        <DefaultRoute
            path='/unauthenticated'
            key='/unauthenticated'
            exact
            component={() => <PermissionRequired />}
        />,
    ];

    return (
        <BrowserRouter>
            <PlatformContextProvider>
                <LotsContextProvider>
                    <Switch>
                        {routeList}
                        <DefaultRoute path='*' key='*' component={() => <NotFound />} />
                    </Switch>
                </LotsContextProvider>
            </PlatformContextProvider>
        </BrowserRouter>
    );
};

export default Routes;
