import { FC, useEffect, useState } from 'react';
import Wrapper from '../Common/wrapper';
import { Button, Modal } from 'antd';
import Badge from '../Common/Badge';
import ApiService from '../../services/api';
import { ModalMultipleWinnerCoverProps } from './props';
import { MultipleWinnersToCover } from '../../Interfaces/multipleWinners';
import { pad } from '../../helpers/pad';
import { SocketEvent, SocketEvents } from '../../Interfaces/socketEvents';
import TinyQueue from 'tinyqueue';
import { timeout } from '../../helpers/timer';
import styled from 'styled-components';
import { HiStatusOnline } from 'react-icons/hi';
import { Badge as DefaultBadge } from 'antd';
import ModalFormView from './modal-form.view';

const Icon = styled(HiStatusOnline)`
    width: 26px;
    height: 26px;
    color: var(--platform-secondary-color);
`;

const Wrapped = styled(Wrapper)`
    height: 44px;
    border: 1px solid rgba(204, 204, 204, 0.5);
`;

const ModalMultipleWinnerCover: FC<ModalMultipleWinnerCoverProps> = ({
    auction,
    socketConnection,
}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalFormVisible, setIsModalFormVisible] = useState(false);
    const [multipleWinnersToCover, setMultipleWinnersToCover] = useState<MultipleWinnersToCover[]>(
        []
    );
    const [providerValueToCover, setProviderValueToCover] = useState<
        MultipleWinnersToCover | undefined
    >(undefined);
    const [isLoading, setIsLoading] = useState(true);

    let queueLock = false;
    const queueEvents: any = new TinyQueue([]);

    useEffect(() => {
        setIsLoading(true);
        listMultipleWinnerToCover();
    }, []);

    useEffect(() => {
        if (isModalVisible) {
            setIsLoading(true);
            listMultipleWinnerToCover();
        }
    }, [isModalVisible]);

    const listMultipleWinnerToCover = async () => {
        let error: any;
        const multipleWinnersToCover = await ApiService.listMultipleWinnerToCover(
            auction.id,
            (err) => {
                error = err;
            }
        );

        if (error) {
            return;
        }

        setIsLoading(false);
        setMultipleWinnersToCover(multipleWinnersToCover);
    };

    useEffect(() => {
        handleSocketEvents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socketConnection]);

    const handleSocketEvents = () => {
        if (!socketConnection) {
            return;
        }

        socketConnection.on(SocketEvents.multipleWinnersToCover, (event: any) =>
            receiveEvent({
                type: SocketEvents.multipleWinnersToCover,
                message: event,
            })
        );
    };

    const receiveEvent = (message: SocketEvent) => {
        queueEvents.push(message);

        if (!queueLock) {
            queueLock = true;
            processEvent();
        }
    };

    const processEvent = async () => {
        try {
            while (queueEvents.length) {
                const event: SocketEvent = queueEvents.pop();
                const { type, message } = event;

                switch (type) {
                    case SocketEvents.multipleWinnersToCover:
                        return handleMultipleWinnersToCover(message);
                }

                await new Promise((r) => timeout(r, 20));
            }
        } finally {
            queueLock = false;
        }
    };

    const handleMultipleWinnersToCover = (data: MultipleWinnersToCover[]) => {
        setMultipleWinnersToCover(data);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const getDefaultRender = () =>
        multipleWinnersToCover.map((multipleWinner) => (
            <Wrapper
                padding='7px 0'
                flexBox
                justifyContent='space-between'
                borderBottom='1px #ebebeb solid'
            >
                <Wrapper flexBox>
                    <Badge
                        cursor='pointer'
                        padding='0 2px'
                        minWidth='27px'
                        width='37px'
                        color={'var(--platform-info-color)'}
                        title={`F`}
                        fontSize='9px'
                        style={{
                            marginRight: '8px',
                        }}
                    >
                        Lote {pad(multipleWinner.lotItem)}
                    </Badge>
                    <span style={{ fontSize: '14px' }}>
                        Convocado para assumir a {multipleWinner.multipleWinner.position} posição.
                    </span>
                </Wrapper>
                <Wrapper>
                    <Button
                        size='small'
                        style={{
                            borderRadius: '4px',
                            fontSize: '12px',
                        }}
                        onClick={() => {
                            setIsModalVisible(false);
                            setIsModalFormVisible(true);
                            setProviderValueToCover(multipleWinner);
                        }}
                    >
                        Abrir
                    </Button>
                </Wrapper>
            </Wrapper>
        ));

    return (
        <>
            {isModalFormVisible && providerValueToCover && (
                <ModalFormView
                    {...{
                        providerValueToCover,
                        auction,
                        onClose: () => {
                            setIsModalFormVisible(false);
                            setIsModalVisible(true);
                        },
                    }}
                />
            )}
            <Modal
                title={'Solicitações'}
                visible={isModalVisible}
                footer={null}
                onCancel={() => {
                    setIsModalVisible(false);
                }}
            >
                {isLoading ? (
                    // getFetchingRender()
                    <></>
                ) : multipleWinnersToCover.length ? (
                    getDefaultRender()
                ) : (
                    <Wrapper flexBox justifyContent='center'>
                        Nenhuma solicitação até o momento
                    </Wrapper>
                )}
            </Modal>
            <Wrapped
                minWidth='205px'
                maxWidth='150px'
                bgcolor={multipleWinnersToCover.length ? '#ffffb58f' : '#fff'}
                textAlign='center'
                padding='12px 24px'
                justifyContent='space-between'
                alignItems='center'
                borderRadius='5px'
                flexBox
            >
                <Wrapper flexBox alignItems='center' onClick={showModal} cursor='pointer'>
                    <Icon />
                    <span style={{ margin: '0 0 0 8px', fontSize: 15 }}>
                        <b>Classificação</b>
                    </span>
                </Wrapper>
                <DefaultBadge showZero count={multipleWinnersToCover.length} />
            </Wrapped>
        </>
    );
};

export default ModalMultipleWinnerCover;
