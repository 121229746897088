import { FC } from 'react';
import { RoundedButtonProps } from './props';
import ConfirmationPopover from '../../../../Common/ConfirmationPopover';
import MessagePopover from '../../../../Common/MessagePopover';
import { ConfirmationModalType } from '../../../../../Interfaces/confirmationModal';
import { BlockIcon, Button } from './styles';
import { useLongPress } from 'use-long-press';
import ReactSVG from 'Components/ReactSVG';
import FrustratePopover from 'Components/Common/FrustratePopover';

const RoundedButton: FC<RoundedButtonProps> = ({
    color,
    icon,
    margin,
    bgColor,
    text,
    onConfirm,
    confirmationType,
    title,
    disabled,
    render,
    omitConfirmButton,
    onLongPressDone,
}) => {
    const actionLongPress = useLongPress(() => {
        onLongPressDone?.();
    });

    const getConfirmation = () => {
        if (!!confirmationType) {
            switch (confirmationType) {
                case ConfirmationModalType.button:
                    return (
                        <ConfirmationPopover
                            omitConfirmButton={omitConfirmButton}
                            disabled={disabled}
                            render={!!text ? <>{text}</> : render}
                            maxWidth='240px'
                            onConfirm={() => !disabled && onConfirm?.()}
                        >
                            <Button
                                {...actionLongPress()}
                                title={title}
                                color={color}
                                bgColor={bgColor}
                                margin={margin}
                            >
                                {icon === 'block' ? (
                                    <BlockIcon color={color} />
                                ) : (
                                    <ReactSVG
                                        color={color}
                                        src={`../../assets/icons/${icon}.svg`}
                                        width='12px'
                                        height='12px'
                                    />
                                )}
                            </Button>
                        </ConfirmationPopover>
                    );

                case ConfirmationModalType.message:
                    return (
                        <MessagePopover
                            maxWidth='330px'
                            render={!!text ? <>{text}</> : render}
                            onConfirm={(message: string) => !disabled && onConfirm?.(message)}
                        >
                            <Button
                                {...actionLongPress()}
                                title={title}
                                color={color}
                                bgColor={bgColor}
                                margin={margin}
                            >
                                <ReactSVG
                                    color={color}
                                    src={`../../assets/icons/${icon}.svg`}
                                    width='12px'
                                    height='12px'
                                />
                            </Button>
                        </MessagePopover>
                    );

                case ConfirmationModalType.frustate:
                    return (
                        <FrustratePopover
                            maxWidth='330px'
                            render={!!text ? <>{text}</> : render}
                            onConfirm={(values) => !disabled && onConfirm?.(values)}
                        >
                            <Button
                                {...actionLongPress()}
                                title={title}
                                color={color}
                                bgColor={bgColor}
                                margin={margin}
                            >
                                <ReactSVG
                                    color={color}
                                    src={`../../assets/icons/${icon}.svg`}
                                    width='12px'
                                    height='12px'
                                />
                            </Button>
                        </FrustratePopover>
                    );
            }
        } else {
            return (
                <Button
                    {...(!disabled ? actionLongPress() : {})}
                    title={title}
                    color={color}
                    bgColor={bgColor}
                    margin={margin}
                    onClick={() => !disabled && onConfirm?.()}
                >
                    
                    <ReactSVG
                        color={color}
                        src={`../../assets/icons/${icon}.svg`}
                        width='12px'
                        height='12px'
                    />
                </Button>
            );
        }
    };

    return getConfirmation();
};

export default RoundedButton;
