import { FC, useState } from 'react';
import Wrapper from '../Common/wrapper';
import Header from '../Common/Header';
import { useTranslation } from 'react-i18next';
import { UnclassifiedProviderActionsProps } from './props';
import ModalIntentionToAppeal from '../ModalIntentionToAppeal';
import styled from 'styled-components';
import { canIntentionToAppeal } from '../../helpers/intention-to-appeal';

const Item = styled.div`
    padding: 4px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const UnclassifiedProviderActions: FC<UnclassifiedProviderActionsProps> = ({
    auctionNotice,
    auctionNoticeLotSelected,
}) => {
    const [isOpened, setOpened] = useState(true);
    const { t } = useTranslation();


    const hasOptions = canIntentionToAppeal(auctionNotice, auctionNoticeLotSelected)

    return (
        <Wrapper
            border='1px solid rgba(204, 204, 204, 0.5);'
            margin='10px 0'
            width='100%'
            minWidth='290px'
            minHeight='auto'
            height='auto'
        >
            <Header
                icon='user'
                showMinimize
                title={t('Ações')}
                onExpand={() => setOpened(!isOpened)}
                expandDirection={isOpened ? 'rotate(-90deg)' : 'rotate(0deg)'}
            />
            {isOpened && (
                <Wrapper style={{ padding: '5px 0' }}>
                    {!hasOptions && (
                        <Item>
                            <span>{t('info.no-actions-avaliable')}</span>
                        </Item>
                    )}
                    {canIntentionToAppeal(auctionNotice, auctionNoticeLotSelected) ? (
                        <Item>
                            <span>{t('info.appeal-intent')}</span>
                            <ModalIntentionToAppeal
                                auctionLot={auctionNoticeLotSelected}
                                auction={auctionNotice}
                            />
                        </Item>
                    ) : null}
                </Wrapper>
            )}
        </Wrapper>
    );
};

export default UnclassifiedProviderActions;
