const currencyFormat = 'R$';

const parseCurrency = (value: number, decimalPlaces?: number) => {
    const decimalValue = value
        .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: decimalPlaces ?? 2,
        })
        .split(currencyFormat)?.[1]
        ?.trim();

    return value < 0 ? `-${decimalValue}` : decimalValue;
}

const replaceToJudgmentCriterionFormat = (
    value: string | number | null | undefined,
    judgmentCriterion: number,
    decimalPlaces?: number,
) => {
    const formatted = (value: any) => {
        switch (judgmentCriterion) {
            case 2:
                return `${value || '--'}%`;
            default:
                return `${currencyFormat} ${value || '--'}`;
        }
    };

    let parsedValue: string = '';

    if (typeof value === 'string') {
        parsedValue = parseCurrency(parseFloat(value), decimalPlaces);

    } else if (typeof value === 'number') {
        parsedValue = parseCurrency(value, decimalPlaces);
    }

    return { formatted: formatted(parsedValue), raw: parsedValue };
};

const formatValueWithAuctionRules = (
    value: string | number | null | undefined,
    judgmentCriterion: number,
    rateTypeBid: number,
    decimalPlaces?: number,
    isReferenceValue?: boolean,
) => {

    if (!rateTypeBid || isReferenceValue || rateTypeBid === 0) {
        const { formatted, raw } = replaceToJudgmentCriterionFormat(value, judgmentCriterion, decimalPlaces);
        return { formatted, raw };
    }

    const { formatted, raw } = replaceToJudgmentCriterionFormat(value, 2, decimalPlaces);
    return { raw, formatted };
};

export { currencyFormat, parseCurrency, replaceToJudgmentCriterionFormat, formatValueWithAuctionRules };
