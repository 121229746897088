import { FC, useState } from 'react';
import Wrapper from '../../../Common/wrapper';
import Popover from 'react-tiny-popover';
import { SendMessageSupplierProps } from './props';
import Image from '../../../Common/Image';
import { Button, Input } from 'antd';
import ApiService from '../../../../services/api';
import { SendButton, PulseButton } from './styled';
import { useTranslation } from 'react-i18next';
import { getCompetentAuthorityName } from '../../../../helpers/auction-rules';

const { TextArea } = Input;

const SendMessageSupplier: FC<SendMessageSupplierProps> = ({ auctionLot, auctionNotice }) => {
    const [modalOpened, setmodalOpened] = useState(false);
    const [message, setMessage] = useState<string | undefined>(undefined);

    const { t } = useTranslation();

    const onConfirm = async () => {
        if (!message) return;

        await ApiService.createMessage(auctionLot.auctionNoticeId, { message });
        setmodalOpened(false);
        setMessage(undefined);
    };

    return (
        <PulseButton>
            <Popover
                isOpen={modalOpened}
                position={['bottom', 'right', 'left']}
                onClickOutside={() => setmodalOpened(false)}
                content={() => (
                    <Wrapper
                        maxWidth='210px'
                        style={{
                            display: modalOpened ? 'block' : 'none',
                        }}
                        bgcolor='#FFF'
                        minWidth='350px'
                        padding='15px'
                    >
                        <Wrapper margin='0 0 10px 0'>
                            <Wrapper fontSize='13px'>
                                <p>
                                    {t('info.send.message.info', {
                                        name: getCompetentAuthorityName(auctionNotice),
                                    })}
                                </p>
                                <p>
                                    {t('info.send.message.to.auctioneer.confirm', {
                                        name: getCompetentAuthorityName(auctionNotice),
                                    })}
                                </p>
                            </Wrapper>
                        </Wrapper>
                        <TextArea
                            style={{
                                fontSize: '13px',
                                resize: 'none',
                            }}
                            value={message}
                            rows={3}
                            autoFocus
                            onChange={(ev) => setMessage(ev.target.value)}
                        >
                            {message}
                        </TextArea>
                        <Wrapper
                            margin='14px 0 0 0'
                            flexBox
                            justifyContent='space-between'
                            alignItems='center'
                        >
                            <Button onClick={() => setmodalOpened(false)}>{t('term.close')}</Button>
                            <SendButton onClick={onConfirm}>{t('term.send')}</SendButton>
                        </Wrapper>
                    </Wrapper>
                )}
            >
                <Wrapper onClick={() => setmodalOpened(true)}>
                    <Image
                        src='../../assets/icons/chatNegotiation.svg'
                        width='19px'
                        height='19px'
                        title={t('info.send.message.to.auctioneer', {
                            name: getCompetentAuthorityName(auctionNotice),
                        })}
                    />
                </Wrapper>
            </Popover>
        </PulseButton>
    );
};

export default SendMessageSupplier;
