import { FC, useEffect } from 'react';
import { SearchBoxProps } from './props';
import styled from 'styled-components';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import Search from 'antd/lib/input/Search';

const Content = styled.div`
    &.hidden {
        height: 0;
    }

    &.opened {
        box-shadow: 0 2px 3px #ddd;
        padding: 9px 7px;
    }

    overflow: hidden;
    height: 51px;
    background: #fff;
    right: 9px;
    left: 9px;
    position: absolute;
    transition: all ease 300ms;

    border-left: 1px #ddd solid;
    border-bottom: 1px #ddd solid;
    border-right: 1px #ddd solid;
`;

const SearchBox: FC<SearchBoxProps> = ({ searchBoxOpened, onSearch, fetching }) => {
    const debouncedSearch = debounce((value) => {
        return onSearch(value);
    }, 500);

    const { t } = useTranslation();

    useEffect(() => {
        const elem = document.getElementById('message-search');

        if (elem && searchBoxOpened) {
            elem.focus();
        }

        if (elem && !searchBoxOpened) {
            elem.innerText = '';
        }
    }, [searchBoxOpened]);

    return (
        <Content className={!searchBoxOpened ? 'hidden' : 'opened'}>
            {searchBoxOpened && (
                <Search
                    id='message-search'
                    placeholder={`${t('info.search')}..`}
                    style={{
                        fontSize: '13px',
                        borderRadius: '3px',
                        height: '33px',
                    }}
                    autoFocus
                    loading={fetching}
                    type='search'
                    onChange={(ev: any) => debouncedSearch(ev.target.value)}
                />
            )}
        </Content>
    );
};

export default SearchBox;
