import { FC } from 'react';
import { ActionsProps } from './props';
import Wrapper from '../../../../Common/wrapper';
import ApiService from '../../../../../services/api';
import { methodDisputeDoAction } from '../../../../../helpers/methodDispute';
import ModeOpenActions from '../ModeOpenActions';
import { AuctioneerActions } from '../interface/AuctioneerActions';
import ClosePeriodActions from '../ClosePeriodActions';

const actions = {
    start: ApiService.startAuctionNoticeLot,
    finish: ApiService.finishAuctionNoticeLot,
    renew: ApiService.renewAuctionNoticeLot,
    pause: ApiService.pauseAuctionNoticeLot,
    unPause: ApiService.unPauseAuctionNoticeLot,
    cancel: ApiService.cancelLot,
    finishNegotiation: ApiService.finishNegotiationAuctionNoticeLot,
    startClosePeriod: ApiService.startCloseAuctionNoticeLot,
    startSecondClosePeriod: ApiService.startSecondCloseAuctionNoticeLot,
    reOpenNegotiation: ApiService.reopenNegotiation,
    repeatSecondClosePeriod: ApiService.repeatSecondClosePeriod,
    finishRepeatSecondClosePeriod: ApiService.finishRepeatSecondClosePeriod,
    startRandomPeriod: ApiService.startRandomPeriod,
    revertCancel: ApiService.revertCancelLot,
    desclassify: ApiService.desclassifyProviderValue
};

const Actions: FC<ActionsProps> = ({
    auctionLot,
    auctionNotice,
    closeBids,
    serverTimestamp,
    providerValues,
    changeStage,
}) => {
    const auctioneerActionToRender = () => {
        const action = methodDisputeDoAction({
            methodDispute: auctionNotice.methodDispute,
            open: () => (
                <ModeOpenActions
                    providerValues={providerValues}
                    doAction={doAction}
                    auctionLot={auctionLot}
                    auctionNotice={auctionNotice}
                />
            ),
            closed: () => (
                <ClosePeriodActions
                    auctionLot={auctionLot}
                    closeBids={closeBids}
                    providerValues={providerValues}
                    doAction={doAction}
                    serverTimestamp={serverTimestamp}
                    changeStage={changeStage}
                    auctionNotice={auctionNotice}
                />
            ),
        });

        return action?.();
    };

    const doAction = async (action: AuctioneerActions, props?: any) => {
        return await actions?.[action]({
            ...props,
            auctionNoticeId: auctionLot.auctionNoticeId,
            auctionLotId: auctionLot.id,
        });
    };

    return (
        <Wrapper margin='0 5px' minWidth='105px' alignItems='center' flexBox>
            {auctioneerActionToRender()}
        </Wrapper>
    );
};

export default Actions;
