import { Button } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlinePaperClip } from 'react-icons/ai';
import { getMd5Hash } from '../../helpers/get-md5-hash';
import { useGetBackofficeRedirectUrl } from '../../helpers/get-url-redirect-php';
import { AuctionNotice } from '../../Interfaces/auctionNotice';
import { getDataFromAuthHash } from '../../helpers/token';

interface ProviderAdditionalDocumentsRedirectProps {
    auction: AuctionNotice;
}

const ProviderAdditionalDocumentsRedirect: FC<ProviderAdditionalDocumentsRedirectProps> = ({
    auction,
}) => {
    const { t } = useTranslation();
    const backofficeUrl = useGetBackofficeRedirectUrl();

    const redirect = () => {
        const hash = window.location.pathname.split('/')[2];
        const data = getDataFromAuthHash(hash as string);

        if (!data) {
            return;
        }

        const { token } = data;

        const auctionHash = getMd5Hash(String(auction.id));
        const redirectUrl = encodeURIComponent(`/proposta/?idE=${auctionHash}&aba=documentos`);

        if (!token || !auctionHash || !backofficeUrl) {
            return;
        }

        window.location.replace(`${backofficeUrl}/auth/?token=${token}&redirectUrl=${redirectUrl}`);
    };

    return (
        <Button
            title={t('info.additional-documents')}
            type='primary'
            shape='round'
            style={{
                background: '#3da60d',
                border: 'none',
                display: 'flex',
                alignItems: 'center',
            }}
            onClick={redirect}
            icon={
                <AiOutlinePaperClip
                    style={{
                        fontSize: '18px',
                    }}
                />
            }
            size='small'
        />
    );
};

export default ProviderAdditionalDocumentsRedirect;
