import { FC } from 'react';
import Wrapper from '../../../Common/wrapper';
import { formatValueWithAuctionRules } from '../../../../helpers/currencyTransform';
import { ProposalItemValueLabelProps } from './props';

const ProposalItemValueLabel: FC<ProposalItemValueLabelProps> = ({
    participant,
    auctionNotice,
}) => {
    function handleFormatValueWithAuctionRules(value: string | number) {
        return formatValueWithAuctionRules(
            value,
            auctionNotice.judgmentCriterion,
            auctionNotice.rateTypeBid,
            auctionNotice.decimalPlaces
        ).formatted;
    }

    if (!participant?.proposal?.additionalExpense || !participant?.proposal?.valueBidProposal)
        return <></>;

    const valueBidProposal = Number(participant.proposal.valueBidProposal);
    const additionalExpense = Number(participant.proposal.additionalExpense);

    return (
        <Wrapper fontSize='10px' display='flex'>
            {handleFormatValueWithAuctionRules(valueBidProposal - additionalExpense)}
            &nbsp;&#43;&nbsp;
            <Wrapper color='var(--platform-danger-color)'>
                {handleFormatValueWithAuctionRules(additionalExpense)}
            </Wrapper>
        </Wrapper>
    );
};

export default ProposalItemValueLabel;
