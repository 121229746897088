import { FC, useEffect } from 'react';
import { AuctionLotItemProps } from './props';
import Wrapper from '../../../../Common/wrapper';
import ApiService from '../../../../../services/api';
import { pad } from '../../../../../helpers/pad';
import ConditionalTimer from '../../../../ConditionalTimer';
import CitzenBestBid from '../CitzenBestBid';
import { Lot, ProposalCount, LotItem } from './styled';
import { useTranslation } from 'react-i18next';

const AuctionLotItemCitzen: FC<AuctionLotItemProps> = ({
    auctionLot,
    selected,
    onSelectAuctionLot,
    changeStage,
    onBestBidLoaded,
    onFinishProviderPeriodConvoked,
    authUser,
    auctionNotice,
    serverTimestamp,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        getBestBid();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auctionLot.id]);

    const getBestBid = async () => {
        const bestBidResponse = await ApiService.getBestBid(
            auctionLot.auctionNoticeId,
            auctionLot.id
        );

        if (!bestBidResponse) {
            onBestBidLoaded({
                lotId: auctionLot.id,
                value: null,
            });
        }

        onBestBidLoaded(bestBidResponse);
    };

    const replacedLotName = `${t('term.lot.title')} ${pad(auctionLot.item)}`;
    const isCota = `${auctionLot.quotaId !== null ? ` (${t('term.quota')})` : ''}`;

    return (
        <Lot
            position='relative'
            padding='5px 10px'
            borderRight={selected ? '1px solid #fff' : '1px solid #D6D6D6'}
            flexBox
            justifyContent='center'
            cursor='pointer'
            bgcolor={selected ? '#FFF' : '#f5f5f5'}
            onClick={() => onSelectAuctionLot(auctionLot)}
            height='86px'
        >
            <Wrapper flexBox alignItems='center'>
                <Wrapper margin='0 10px 0 0'>
                    <LotItem title={`${replacedLotName}${isCota}`}>
                        <span>{replacedLotName}</span>
                        <span>{isCota}</span>
                    </LotItem>
                    <ProposalCount>
                        <span>
                            <b>
                                {!!auctionLot.proposalCount ? pad(auctionLot.proposalCount) : '--'}
                            </b>{' '}
                            {auctionLot?.proposalCount === 1
                                ? t('term.proposal')
                                : t('term.proposals')}
                        </span>
                    </ProposalCount>
                </Wrapper>
            </Wrapper>
            <CitzenBestBid auctionLot={auctionLot} auctionNotice={auctionNotice} />
            <Wrapper
                width='100px'
                flexBox
                justifyContent='center'
                alignItems='center'
                margin='0 8px'
            >
                <ConditionalTimer
                    key={`timer:${auctionLot.id}`}
                    auctionLot={auctionLot}
                    auctionNotice={auctionNotice}
                    changeStage={changeStage}
                    onFinishProviderPeriodConvoked={onFinishProviderPeriodConvoked}
                    onFinishTimerLot={() => {}}
                    authUser={authUser}
                    serverTimestamp={serverTimestamp}
                    bidPeriodCloseMode={true}
                />
            </Wrapper>
        </Lot>
    );
};

export default AuctionLotItemCitzen;
