import { FC } from 'react';
import Wrapper from '../wrapper';

interface GroupSeparatorProps {}

const GroupSeparator: FC<GroupSeparatorProps> = ({ children }) => {
    return (
        <Wrapper
            bgcolor='#EAEAEA'
            height='24px'
            fontSize='13px'
            fontWeight='600'
            display='flex'
            alignItems='center'
            padding='0 10px'
        >
            {children}
        </Wrapper>
    );
};

export default GroupSeparator;
