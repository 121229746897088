import styled from 'styled-components';

export const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    right: 0;
    bottom: 0;
    z-index: 99999;
    background: #ffffffcf;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
`;
