import { FC, useEffect, useState } from 'react';
import Wrapper from '../../../Common/wrapper';
import Image from '../../../Common/Image';
import { useTranslation } from 'react-i18next';
import ApiService from 'services/api';
import { ChatFavoriteMessage } from 'Components/Chat/interface';
import Search from 'antd/lib/transfer/search';
import { debounce } from 'lodash';
import Popover from 'react-tiny-popover';
import { FaStar } from 'react-icons/fa';
import {
    FavoriteButton,
    FavoriteMessagesBody,
    FavoriteMessagesItem,
    FavoriteMessagesTitle,
} from './styled';

const ChatFavoriteMessages: FC<{ selectFavoriteMessage: (data: string) => void }> = ({
    selectFavoriteMessage,
}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [messages, setMessages] = useState<ChatFavoriteMessage[]>([]);
    const [messagesFiltered, setMessagesFiltered] = useState<ChatFavoriteMessage[]>(messages);
    const [isLoading, setIsLoading] = useState(true);
    const { t } = useTranslation();

    const getMessages = async () => {
        const messages = await ApiService.listFavoriteMessages();
        setMessages(messages);
        setIsLoading(false);
    };

    useEffect(() => {
        setIsLoading(true);
        getMessages();
    }, [isModalVisible]);

    const selectMessage = ({ message }: ChatFavoriteMessage) => {
        selectFavoriteMessage(message);
        setIsModalVisible(false);
    };

    useEffect(() => {
        setMessagesFiltered(messages);
    }, [messages]);

    const onSearchText = (text: string) => {
        if (text === '' || !text) {
            setMessagesFiltered(messages);
            return;
        }

        const filtered = messages.filter((message) =>
            message.title.toLocaleLowerCase().includes(text.toLocaleLowerCase().trim())
        );
        setMessagesFiltered(filtered);
    };
    const debouncedSearch = debounce((value) => {
        return onSearchText(value);
    }, 500);
    return (
        <>
            <Popover
                isOpen={isModalVisible}
                position={['top', 'right', 'left']}
                onClickOutside={(e: any) => {
                    if (e?.path?.find((e: any) => e?.id === 'requestRefreshPage')) {
                        return;
                    }
                    setIsModalVisible(false);
                }}
                containerStyle={{ maxWidth: '300px', maxHeight: '400px', overflowY: 'auto' }}
                content={() => {
                    if (isLoading) return <></>;
                    if (messages.length) {
                        const messageItems = messagesFiltered.map((message, index) => (
                            <FavoriteMessagesItem
                                onClick={() => selectMessage(message)}
                                disableDivider={index === messagesFiltered.length - 1}
                            >
                                <FavoriteMessagesTitle>{message.title}</FavoriteMessagesTitle>
                                <FavoriteMessagesBody>{message.message}</FavoriteMessagesBody>
                            </FavoriteMessagesItem>
                        ));
                        return (
                            <Wrapper
                                padding='10px 12px'
                                justifyContent='space-between'
                                bgcolor='#FFF'
                                borderBottom='1px #ebebeb solid'
                            >
                                <Search onChange={(ev: any) => debouncedSearch(ev.target.value)} />
                                {messageItems}
                            </Wrapper>
                        );
                    }
                    return (
                        <Wrapper flexBox justifyContent='center'>
                            {t('info.favorite-messages-empty')}
                        </Wrapper>
                    );
                }}
            >
                <Wrapper
                    onClick={() => setIsModalVisible(true)}
                    alignItems='center'
                    flexBox
                    margin='0 0 0 0'
                    padding='0 0 0 12px'
                >
                    <FavoriteButton>
                        <FaStar fontSize='14px' />
                        {t('info.favorite-messages')}
                    </FavoriteButton>
                </Wrapper>
            </Popover>
        </>
    );
};

export default ChatFavoriteMessages;
