import { FC } from 'react';
import Wrapper from '../../../Common/wrapper';
import { formatValueWithAuctionRules } from '../../../../helpers/currencyTransform';
import { pad } from '../../../../helpers/pad';
import { Item, Online } from '../styles';
import { AuctioneerProposalItemProps } from './props';
import { useTranslation } from 'react-i18next';
import ProposalItemValueLabel from '../ProposalItemValueLabel';

const AuctioneerProposalItem: FC<AuctioneerProposalItemProps> = ({
    participant,
    auctionNotice,
}) => {
    const code = pad(participant.providerAuctionCode);

    function handleFormatValueWithAuctionRules(value: string) {
        return formatValueWithAuctionRules(
            value,
            auctionNotice.judgmentCriterion,
            auctionNotice.rateTypeBid,
            auctionNotice.decimalPlaces
        )
    }

    const { formatted } = handleFormatValueWithAuctionRules(participant?.proposal?.valueBidProposal);
    const { t } = useTranslation();

    const isOnlineText = participant.isOnline ? 'Online' : 'Offline';

    return (
        <Item flexBox padding='8px 5px' alignItems='center' margin='3px 0'>
            <Online isOnline={participant.isOnline} title={isOnlineText as string} />
            <Wrapper justifyContent='space-between' alignItems='center' flex flexBox>
                <Wrapper fontSize='12px'>{`${t('term.provider.token')} ${code}`}</Wrapper>
                <Wrapper
                    truncate
                    title={t('info.proposal.initial.value', { value: formatted })}
                    fontSize='13px'
                    color='#444'
                    fontWeight='700'
                    textAlign='end'
                >
                    {formatted}
                    <ProposalItemValueLabel {...{ participant, auctionNotice }} />
                </Wrapper>
            </Wrapper>
        </Item>
    );
};

export default AuctioneerProposalItem;
