import { FC, useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { TypeAccess, User } from '../../Interfaces/user';
import Authenticating from '../Authenticating';
import { PrivateRouteProps } from './props';
import jwt from 'jwt-decode';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import ApiService from '../../services/api';
import { getDataFromAuthHash } from '../../helpers/token';

const PrivateRoute: FC<PrivateRouteProps> = ({ component, location, ...rest }) => {
    const [authenticating, setAuthenticating] = useState(true);
    const [authUser, setAuthUser] = useState<User | undefined>(undefined);
    const [auctionNoticeId, setAuctionNoticeId] = useState<string | undefined>(undefined);

    const { i18n } = useTranslation();

    useEffect(() => {
        validateData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validateData = async () => {
        const hash = (rest as any).computedMatch.params.hashId as string;
        const data = getDataFromAuthHash(hash);

        if (!data) {
            return setAuthenticating(false);
        }

        const { token, auctionId } = data;

        if (token) {
            await validateTokenApi(token, auctionId);
        }

        if (auctionId) {
            validateAuctionNotice(auctionId);
        }

        setAuthenticating(false);
    };

    const validateAuctionNotice = (auctionNoticeId: string) => {
        setAuctionNoticeId(auctionNoticeId);
        return !!auctionNoticeId;
    };

    const validateTokenApi = async (token: string, auctionNoticeId: string | null) => {
        try {
            const who: any = jwt(token);
            const user = await ApiService.getMe();

            const defaultTypeAccess = TypeAccess.citizen;
            let typeAccess = defaultTypeAccess;

            const validTypeAccessToAccessDispute = [
                TypeAccess.provider,
                TypeAccess.citizen,
                TypeAccess.organization,
            ];

            if (validTypeAccessToAccessDispute.includes(who.typeAccess)) {
                typeAccess = who.typeAccess;
            }

            setAuthUser({
                ...who,
                providerId: who.providerId && (parseInt(who.providerId) || undefined),
                userId: who.userId && (parseInt(who.userId) || undefined),
                typeAccess: typeAccess,
                featureFlags: user?.featureFlags,
            });

            // setar idioma do usuario
            i18n.changeLanguage(user.language);
            Sentry.setUser({ userId: who.userId, auction: auctionNoticeId });
        } catch (error) {}

        return !!authUser;
    };

    const Component = component as FC<any>;

    return (
        <Route
            {...rest}
            render={({ location }) =>
                authenticating ? (
                    <Authenticating />
                ) : !!authUser && !!auctionNoticeId ? (
                    <Component {...rest} auctionNoticeId={auctionNoticeId} authUser={authUser} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/unauthenticated',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};

export default PrivateRoute;
